import { useConfirmBank } from "hooks/query/customers/bank/use-confirm-bank-account";
import { useRejectBank } from "hooks/query/customers/bank/use-reject-bank-account";
import useDownloadFile from "hooks/query/file/use-download-file";
import React, { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { useParams } from "react-router-dom";
import {
  Badge,
  Button,
  color,
  DashboardContainer,
  DownloadInput,
  FormLayout,
  Heading,
  media,
  Spacer,
  spacing,
  TextInput,
} from "stablr";
import { ReactComponent as CheckedCirleIcon } from "stablr/assets/icons/checked-circle.svg";
import { ReactComponent as RemoveCirleIcon } from "stablr/assets/icons/remove-circle.svg";
import styled from "styled-components";
import { APIBankAccount, APIBankAccountType } from "types/API/BankAccount";

import { BANK_ACCOUNT_SCHEMA } from "../common/bankAccountSchema";
import { getBankAccountBadgeVariant } from "../functions/get-bank-account-badge-varient";
import { parseBankAccountBadgeText } from "../functions/parse-bank-account-badge-text";

type BankAccountInfoProps = {
  data: APIBankAccount;
};

interface BankAccountInfoFormValues extends Omit<APIBankAccount, "Types"> {
  Type: APIBankAccountType;
}

const DashboardContainerStyled = styled(DashboardContainer)`
  padding: 0 ${spacing.xl};
`;
const ButtonsContainer = styled.div`
  display: flex;

  > *:not(:last-child) {
    margin-right: ${spacing.l};
  }

  @media (${media.mobile}) {
    display: block;

    > * {
      margin-bottom: ${spacing.xs};
    }
  }
`;

export function BankAccountInfo({ data }: BankAccountInfoProps) {
  const [isVisibleButton, setIsVisibleButton] = useState(true);

  const { id } = useParams();

  const organizationId = id ? id : "";
  const bankId = data.Id;

  const {
    mutateAsync: mutateAsyncRejectCustomer,
    isSuccess: isSuccessReject,
    isLoading: isRejecting,
  } = useRejectBank();

  const {
    mutateAsync: mutateAsyncConfirmCustomer,
    isSuccess: isSuccessConfirm,
    isLoading: isConfirming,
  } = useConfirmBank();

  const { refetch } = useDownloadFile(organizationId, data?.BankStatementFileId, "bank-statements");

  useEffect(() => {
    if (isSuccessReject) setIsVisibleButton(false);
    if (isSuccessConfirm) setIsVisibleButton(false);
  }, [isSuccessConfirm, isSuccessReject]);

  const handleApproveBank = async () => mutateAsyncConfirmCustomer({ organizationId, bankId });

  const handleRejectBank = async () => mutateAsyncRejectCustomer({ organizationId, bankId });

  // const formattedDate = format(new Date(data.DateFileUploaded), "dd/MM/yyyy HH:mm");

  return (
    <DashboardContainerStyled>
      <Heading as="h3">
        {data.BankName}{" "}
        <Badge text={parseBankAccountBadgeText(data?.Status)} varient={getBankAccountBadgeVariant(data?.Status)} />
      </Heading>
      <hr color={color.greyscale.grey2} />
      <Heading as="h4">Bank Account Information </Heading>

      <Form<BankAccountInfoFormValues>
        onSubmit={() => {
          //do nothing
        }}
        initialValues={{ ...data, Type: data.Types?.[0] }}
      >
        {({ values }) => (
          <>
            <FormLayout columns={2}>
              <>
                <Field label="Type" name={BANK_ACCOUNT_SCHEMA.TYPE} readOnly compact component={TextInput} />
                <Field
                  readOnly
                  label="Bank Account Holder"
                  name={BANK_ACCOUNT_SCHEMA.ACCOUNT_NAME}
                  compact
                  component={TextInput}
                />
                {["SWIFT", "SEPA"].includes(values.Type) && (
                  <Field
                    label="IBAN / Account Number"
                    readOnly
                    name={BANK_ACCOUNT_SCHEMA.IBAN}
                    placeholder={data?.Iban}
                    compact
                    component={TextInput}
                  />
                )}
                {["SWIFT", "SEPA"].includes(values.Type) && (
                  <Field readOnly label="BIC/SWIFT" name={BANK_ACCOUNT_SCHEMA.BIC} compact component={TextInput} />
                )}
                {["BLINC"].includes(values.Type) && (
                  <Field readOnly label="Blinc Id" name={BANK_ACCOUNT_SCHEMA.BLINC_ID} compact component={TextInput} />
                )}
              </>
            </FormLayout>
            <Spacer height={spacing.l} />
            <hr color={color.greyscale.grey2} />
            <Heading as="h4">Bank</Heading>
            <FormLayout columns={2}>
              <>
                <Field label="Name" name={BANK_ACCOUNT_SCHEMA.BANK_NAME} readOnly compact component={TextInput} />
                <Field readOnly label="Address" name={BANK_ACCOUNT_SCHEMA.BANK_ADDRESS} compact component={TextInput} />
                <Field
                  label="Postal Code"
                  readOnly
                  name={BANK_ACCOUNT_SCHEMA.BANK_POSTAL_CODE}
                  compact
                  component={TextInput}
                />
                <Field readOnly label="City" name={BANK_ACCOUNT_SCHEMA.CITY} compact component={TextInput} />
                <Field label="Country" readOnly name={BANK_ACCOUNT_SCHEMA.COUNTRY} compact component={TextInput} />
              </>
            </FormLayout>
            <Spacer height={spacing.l} />
            <hr color={color.greyscale.grey2} />
            <Heading as="h4">Bank Account Statements</Heading>
            <FormLayout columns={2}>
              <>
                <Field
                  name={BANK_ACCOUNT_SCHEMA.BANK_STATEMENT_FILE_NAME}
                  readOnly
                  compact
                  label="Bank Account Statements"
                  inputType="Download"
                  component={DownloadInput}
                  onDownload={refetch}
                />
                {/* <SpanStyled color={color.greyscale.grey5}>Uploaded at {formattedDate}</SpanStyled> */}
              </>
            </FormLayout>

            <Spacer height={spacing.xl} />
            {isVisibleButton && data?.Status === "PENDING" && (
              <ButtonsContainer>
                <Button
                  onClick={handleApproveBank}
                  icon={<CheckedCirleIcon />}
                  width="160px"
                  backgroundColor={color.palette.green}
                  loading={isConfirming}
                >
                  Approve
                </Button>
                <Button
                  onClick={handleRejectBank}
                  icon={<RemoveCirleIcon />}
                  loading={isRejecting}
                  width="160px"
                  backgroundColor={color.palette.red}
                >
                  Reject
                </Button>
              </ButtonsContainer>
            )}
          </>
        )}
      </Form>
    </DashboardContainerStyled>
  );
}
