import { APIBankAccountStatusType } from "types/API/BankAccount";

export function parseBankAccountBadgeText(status?: APIBankAccountStatusType): string {
  if (status === undefined) return "";
  switch (status) {
    case "APPROVED": {
      return "Approved";
    }
    case "REJECTED": {
      return "Rejected";
    }
    case "DELETED": {
      return "Deleted";
    }
    case "PENDING": {
      return "Pending";
    }
    default: {
      return status;
    }
  }
}
