import { ReactComponent as StablRLogoSVG } from "assets/svg/logo-stablr-ops.svg";
import { signOut } from "aws-amplify/auth";
import useUserData from "hooks/context/use-user-data";
import * as React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { ReactComponent as CustomersSVG } from "stablr/assets/icons/customers.svg";
import { ReactComponent as DashboardIconSVG } from "stablr/assets/icons/dashboard.svg";
import { ReactComponent as PieChartIconSVG } from "stablr/assets/icons/pie-chart.svg";
import { ReactComponent as StickyNoteSVG } from "stablr/assets/icons/sticky-note.svg";
import { ReactComponent as TransactionsIconSVG } from "stablr/assets/icons/transactions.svg";
import {
  DrawerLink,
  HeaderNavigation,
  MenuDropDown,
  MenuDropDownLink,
  MenuDropDownList,
  NavigationDrawer,
} from "stablr/components/molecules";
import {
  UserDropDown,
  UserDropDownActions,
  UserDropDownDivider,
  UserDropDownFootLinks,
  UserDropDownInfo,
} from "stablr/components/organisms";
import { DashboardTemplate } from "stablr/components/templates";
import { useIsMobile } from "stablr/hooks";

import PackageJson from "../../package.json";
import { HeaderNavigationCustomer } from "./Customers/Tabs/HeaderNavigationCustomer";
import { HeaderNavigationTransactions } from "./Transactions/common/HeaderNavigationTransactions";

interface DashboardMenuProps {
  children: React.ReactElement;
}

export default function DashboardMenuComponent({ children }: DashboardMenuProps) {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const userData = useUserData();

  const handleOnLogOut = async () => {
    await signOut();
    navigate("../sign-in");
  };

  return (
    <DashboardTemplate
      navigationBarElement={
        <NavigationDrawer logoText="OPS">
          <DrawerLink icon={<DashboardIconSVG />} to="/dashboard">
            Dashboard
          </DrawerLink>
          <DrawerLink icon={<PieChartIconSVG />} to="/finance">
            Finance
          </DrawerLink>
          <DrawerLink icon={<TransactionsIconSVG />} to="/transactions/buy">
            Transactions
          </DrawerLink>
          <DrawerLink icon={<CustomersSVG />} to="/customers">
            Customers
          </DrawerLink>
          <DrawerLink icon={<StickyNoteSVG />} to="/contracts">
            S. Contracts
          </DrawerLink>
        </NavigationDrawer>
      }
      headerNavigationElement={
        <HeaderNavigation
          title={
            isMobile ? (
              <StablRLogoSVG />
            ) : (
              <Routes>
                <Route path="/dashboard/*" element={<>Dashboard</>} />
                <Route path="/finance/*" element={<>Finance</>} />
                <Route path="/transactions/*" element={<>Transactions</>} />
                <Route path="/transactions/buy/:id" element={<HeaderNavigationTransactions />} />
                <Route path="/transactions/sell/:id" element={<HeaderNavigationTransactions />} />
                <Route path="/customers/*" element={<>Customer Management</>} />
                <Route path="/customers/:id/*" element={<HeaderNavigationCustomer />} />
                <Route path="/contracts/*" element={<>Smart Contract Administration</>} />
              </Routes>
            )
          }
        >
          <>
            <UserDropDown name={userData.name ?? "-"}>
              <UserDropDownInfo name={userData.name ?? "-"} email={userData.email ?? ""}></UserDropDownInfo>
              <UserDropDownDivider />
              <UserDropDownActions onLogout={handleOnLogOut} version={PackageJson.version} />
              <UserDropDownDivider />
              <UserDropDownFootLinks links={[{ to: "https://stablr.com", label: "StablR.com", external: true }]} />
            </UserDropDown>

            {isMobile ? (
              <MenuDropDown>
                <>
                  <MenuDropDownList>
                    <MenuDropDownLink icon={<DashboardIconSVG />} to="/dashboard">
                      Dashboard
                    </MenuDropDownLink>
                    <MenuDropDownLink icon={<PieChartIconSVG />} to="/finance">
                      Finance
                    </MenuDropDownLink>
                    <MenuDropDownLink icon={<TransactionsIconSVG />} to="/transactions/buy">
                      Transactions
                    </MenuDropDownLink>
                    <MenuDropDownLink icon={<CustomersSVG />} to="/customers">
                      Customers
                    </MenuDropDownLink>
                    <MenuDropDownLink icon={<StickyNoteSVG />} to="/contracts">
                      S. Contracts
                    </MenuDropDownLink>
                  </MenuDropDownList>
                </>
              </MenuDropDown>
            ) : (
              <></>
            )}
          </>
        </HeaderNavigation>
      }
    >
      {children}
    </DashboardTemplate>
  );
}
