import { useQuery } from "@tanstack/react-query";
import api from "api";
import { APITransactionListItem } from "types/API/Transaction";

useTransactions.queryKey = "transactions";

export function useTransactions(type: "BUY" | "SELL") {
  return useQuery(
    [useTransactions.queryKey, type],
    async () => api.Transaction.getTransactions(type) as Promise<APITransactionListItem[]>,
    {
      notifyOnChangeProps: "all",
    }
  );
}
