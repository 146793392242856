import {
  addDays,
  addMonths,
  endOfMonth,
  endOfWeek,
  format,
  isSameDay,
  isSameMonth,
  startOfMonth,
  startOfWeek,
  subMonths,
} from "date-fns";
import React, { useState } from "react";
import { Button, ButtonContainer } from "stablr";
import { ReactComponent as ChevronLeftSVG } from "stablr/assets/icons/chevron-left.svg";
import { ReactComponent as ChevronRightSVG } from "stablr/assets/icons/chevron-right.svg";
import color from "stablr/styles/color";
import fontSize from "stablr/styles/fontSize";
import fontWeight from "stablr/styles/fontWeight";
import spacing from "stablr/styles/spacing";
import styled from "styled-components";

interface CalendarProps {
  selectedDate: Date | null;
  onClose: () => void;
  onSelect: (date: Date) => void;
}

interface DayStyledProps {
  isInactive?: boolean;
  isSelected?: boolean;
  isToday?: boolean;
}

const ICON_ARROW_SIZE = 22;

export const CalendarWrapper = styled.div`
  box-shadow: 2px 3px 4px 4px #0000001a;
  position: absolute;
  top: 100px;
  left: ${spacing.m};
  z-index: 999;
  background-color: ${color.greyscale.white};
  padding: ${spacing.xxs} ${spacing.m};
`;

const WeekContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
`;

const DayStyled = styled.div<DayStyledProps>`
  padding: ${spacing.xs};
  font-size: ${fontSize.p};
  height: 30px;
  width: 30px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-weight: ${props => (props.isToday || props.isSelected ? fontWeight.bold : fontWeight.light)};
  background-color: ${props =>
    props.isSelected ? color.theme.primary : props.isToday ? color.greyscale.white : color.other.transparent};
  color: ${props =>
    props.isInactive && !props.isSelected
      ? color.greyscale.grey3
      : props.isSelected
      ? color.greyscale.white
      : color.greyscale.black};

  &:hover {
    background-color: ${props => (props.isInactive ? color.greyscale.grey4 : color.greyscale.grey3)};
    color: ${color.greyscale.white};
    font-weight: ${props => (props.isToday ? fontWeight.bold : fontWeight.light)};
  }
`;

const WeekNamesStyled = styled.div`
  cursor: default;
  color: ${color.greyscale.black};
  font-size: ${fontSize.data};
  font-weight: ${fontWeight.bold};
  padding-bottom: ${spacing.s};
`;

const CurrentMonthStyled = styled.div`
  padding: ${spacing.xs} ${spacing.s};
  min-width: 120px;
  text-align: center;
  font-weight: ${fontWeight.bold};
  color: ${color.greyscale.black};
  white-space: nowrap;
`;

const HeaderStyled = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: ${spacing.s};
  width: 100%;

  & button {
    padding: 0 ${spacing.xs};
  }
`;

const TodayButtonStyled = styled.div`
  border-radius: 5px;
  padding: ${spacing.s} ${spacing.m};
  cursor: pointer;
`;

const getWeekDaysNames = (activeDate: Date) => {
  const weekStartDate = startOfWeek(activeDate, { weekStartsOn: 1 });
  const weekDays = [];
  for (let day = 0; day < 7; day++) {
    const dayName = format(addDays(weekStartDate, day), "E");
    const shortDayName = dayName.slice(0, 2);
    weekDays.push(<WeekNamesStyled>{shortDayName}</WeekNamesStyled>);
  }
  return <WeekContainer>{weekDays}</WeekContainer>;
};

export const Calendar: React.FC<CalendarProps> = ({ onSelect, onClose }) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [activeDate, setActiveDate] = useState(new Date());

  const getHeader = () => {
    return (
      <HeaderStyled>
        <Button
          size="small"
          borderColor="transparent"
          iconColor={color.theme.primary}
          iconSize={ICON_ARROW_SIZE}
          icon={<ChevronLeftSVG />}
          onClick={() => setActiveDate(subMonths(activeDate, 1))}
          varient="line"
          layout="icon-only"
        />
        <CurrentMonthStyled>{format(activeDate, "MMM. yyyy")}</CurrentMonthStyled>
        <Button
          size="small"
          borderColor="transparent"
          iconColor={color.theme.primary}
          iconSize={ICON_ARROW_SIZE}
          icon={<ChevronRightSVG />}
          onClick={() => setActiveDate(addMonths(activeDate, 1))}
          varient="line"
          layout="icon-only"
        />
        <TodayButtonStyled
          onClick={() => {
            setSelectedDate(new Date());
            setActiveDate(new Date());
          }}
        >
          Today
        </TodayButtonStyled>
      </HeaderStyled>
    );
  };

  const generateDatesForCurrentWeek = (date: Date, selectedDate: Date, activeDate: Date) => {
    let currentDate = date;
    const week = [];
    for (let day = 0; day < 7; day++) {
      const cloneDate = currentDate;

      week.push(
        <DayStyled
          isInactive={!isSameMonth(currentDate, activeDate)}
          isSelected={isSameDay(currentDate, selectedDate)}
          isToday={isSameDay(currentDate, new Date())}
          onClick={() => {
            setSelectedDate(cloneDate);
          }}
        >
          {format(currentDate, "d")}
        </DayStyled>
      );
      currentDate = addDays(currentDate, 1);
    }
    return <>{week}</>;
  };

  const getDates = () => {
    const startOfTheSelectedMonth = startOfMonth(activeDate);
    const endOfTheSelectedMonth = endOfMonth(activeDate);
    const startDate = startOfWeek(startOfTheSelectedMonth, { weekStartsOn: 1 });
    const endDate = endOfWeek(endOfTheSelectedMonth, { weekStartsOn: 1 });

    let currentDate = startDate;

    const allWeeks = [];

    while (currentDate <= endDate) {
      allWeeks.push(generateDatesForCurrentWeek(currentDate, selectedDate, activeDate));
      currentDate = addDays(currentDate, 7);
    }

    return <WeekContainer>{allWeeks}</WeekContainer>;
  };

  const handleAddClick = () => {
    if (selectedDate) {
      onSelect(selectedDate);
    }
  };

  const handleCancelClick = () => {
    onClose();
  };

  return (
    <CalendarWrapper>
      {getHeader()}
      {getWeekDaysNames(activeDate)}
      {getDates()}
      <ButtonContainer onClick={handleCancelClick} justifyContent="flex-start">
        <Button varient="line" size="medium" borderColor={color.greyscale.white}>
          Cancel
        </Button>
        <Button
          onClick={handleAddClick}
          varient="primary"
          size="medium"
          rounded
          backgroundColor={color.theme.primary}
          borderColor={color.greyscale.white}
          width="130px"
        >
          Add
        </Button>
      </ButtonContainer>
    </CalendarWrapper>
  );
};
