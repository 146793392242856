import useDownloadFile from "hooks/query/file/use-download-file";
import React from "react";
import { useParams } from "react-router-dom";
import { color } from "stablr";
import { ReactComponent as DeleteIcon } from "stablr/assets/icons/delete-outline.svg";
import { ReactComponent as DownloadIcon } from "stablr/assets/icons/download.svg";
import { Table } from "stablr/components/atoms";
import { HeadlessMenuList } from "stablr/components/molecules";

import DeleteDocumentModal from "./DeleteDocumentModal";

const { TableData } = Table;

interface DocumentActionsProps {
  fileId: string;
  title: string;
}

const DocumentActions = ({ fileId, title }: DocumentActionsProps) => {
  const { id } = useParams();

  const [openConfirm, setOpenConfirm] = React.useState(false);

  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  const { refetch } = useDownloadFile(id ?? "", fileId, "internal");

  const options = [
    {
      label: "Download",
      icon: <DownloadIcon />,
      iconBgColor: color.greyscale.white,
      iconColor: color.palette.purple,
      onClick: () => refetch(),
    },
    {
      label: "Delete",
      icon: <DeleteIcon />,
      iconBgColor: color.greyscale.white,
      iconColor: color.palette.red,
      onClick: () => setOpenConfirm(true),
    },
  ];

  return (
    <TableData onClick={handleClick}>
      {options?.length ? <HeadlessMenuList options={options} /> : <></>}
      <DeleteDocumentModal
        organizationId={id ?? ""}
        fileId={fileId}
        fileName={title}
        isOpen={openConfirm}
        onClose={() => setOpenConfirm(false)}
      />
    </TableData>
  );
};

export default DocumentActions;
