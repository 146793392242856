// import api from "api";

import Connection from "./Connection";
// import { MOCK_FINANCE_DAILY, MOCK_FINANCE_MONTHLY, MOCK_FINANCE_YEARLY } from "./mocks/mock-finance";

export type GetFinanceOverviewType = "day" | "month" | "year";

// const getFinanceOverview = (type: GetFinanceOverviewType) =>
//   api.Mock.mockRequest(
//     `/ops/financial/overview?type=${type}`,
//     type === "daily" ? MOCK_FINANCE_DAILY : type === "monthly" ? MOCK_FINANCE_MONTHLY : MOCK_FINANCE_YEARLY
//   );

export const getFinanceOverview = (type: GetFinanceOverviewType) =>
  Connection.get(`/ops/financial/overview?type=${type}`).then(response => response.Items);
