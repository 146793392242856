import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { format } from "date-fns";
import useDownloadFile from "hooks/query/file/use-download-file";
import * as React from "react";
import { useParams } from "react-router-dom";
import { ReactComponent as DownloadIcon } from "stablr/assets/icons/download.svg";
import { Button, Table } from "stablr/components/atoms";
import { useIsMobile } from "stablr/hooks";
import { color, spacing } from "stablr/styles";
import styled from "styled-components";
import { APIDocument } from "types/API/Document";

const { TableBody, Table: TableStyled, TableData, TableHead, TableHeader, TableRow } = Table;

interface DocumentTableProps {
  data: APIDocument;
  organizationId?: string;
}

const DocumentTableStyled = styled(TableStyled)`
  & tr > td:nth-child(1) {
    /* Stop date from wrapping */
    white-space: nowrap;
  }

  margin-bottom: ${spacing.xxl};
`;

const columnHelper = createColumnHelper<APIDocument>();

export function LatestDocument({ data }: DocumentTableProps) {
  const { id, fileId } = useParams();

  const isMobile = useIsMobile();
  const { refetch } = useDownloadFile(id ?? "", fileId ?? "", "internal");

  const recentDocument = data;

  const columns = React.useMemo(() => {
    const tableColumns = [
      columnHelper.accessor("UploadedAt", {
        header: "Timestamp",
        cell: info => <TableData>{format(new Date(info.getValue()), "dd/MM/y HH:mm") ?? "-"}</TableData>,
        footer: info => info.column.id,
      }),
      columnHelper.accessor("FileName", {
        header: "Document",
        cell: info => <TableData>{info.getValue() ?? "-"}</TableData>,
        footer: info => info.column.id,
      }),
      columnHelper.accessor("Version", {
        header: "Version",
        cell: info => <TableData>{info.getValue() ?? "-"}</TableData>,
        footer: info => info.column.id,
      }),
      columnHelper.accessor("Version", {
        id: "downloadLatestDocument",
        header: "",
        cell: () => (
          <TableData>
            <Button
              onClick={() => refetch()}
              size="small"
              iconSize={20}
              varient="line"
              borderColor={color.greyscale.white}
              color={color.theme.primary}
              icon={<DownloadIcon />}
            >
              Download
            </Button>
          </TableData>
        ),
        footer: info => info.column.id,
        enableSorting: false,
      }),
    ];

    const mobileColumns = [tableColumns[0], tableColumns[1], tableColumns[3]];
    const col = [...(isMobile ? mobileColumns : tableColumns)];
    return col;
  }, [isMobile, refetch]);

  const table = useReactTable({
    data: [recentDocument],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <DocumentTableStyled>
        <TableHead>
          {table.getHeaderGroups().map(headerGroup => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <TableHeader key={`Header-${header.id}`}>
                  {flexRender(header.column.columnDef.header, header.getContext())}
                </TableHeader>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {table.getRowModel().rows.map(row => (
            <React.Fragment key={`ROW_${row.id}`}>
              <TableRow key={row.id}>
                {row.getVisibleCells().map(cell => {
                  return (
                    <React.Fragment key={`Row_${row.id}_Column_${cell.id}`}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </React.Fragment>
                  );
                })}
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </DocumentTableStyled>
    </>
  );
}
