import { MutableState } from "final-form";
import React from "react";
import { Field, Form } from "react-final-form";
import { Button, Spacer } from "stablr/components/atoms";
import { ButtonContainer, ErrorMessage, ListBoxInput } from "stablr/components/molecules";
import { validateFormValues } from "stablr/functions";
import { color, spacing } from "stablr/styles";
import { CustodianBank } from "types/API/CustodianBank";
import { AccountOperator } from "types/API/Governance";

import { CustodianBankValidation, PAYMENT_CONFIRMATION_SCHEMA } from "./schema/custodianBankSchemaValidation";

interface CustodianBankFormProps {
  custodianBank?: CustodianBank;
  custodianBankList: CustodianBank[];
  accountOperators: AccountOperator[];
  onUpdate: (values: CustodianBankFormValues) => void;
}

export type CustodianBankFormValues = CustodianBank;

export function CustodianBankForm({
  custodianBank,
  onUpdate,
  custodianBankList,
  accountOperators,
}: CustodianBankFormProps) {
  const bankNameOptions = React.useMemo(
    () => accountOperators.map(item => ({ label: item.Name, value: item.Id })),
    [accountOperators]
  );

  const clearBankAccount = (
    _: undefined,
    state: MutableState<CustodianBank, CustodianBank>,
    utils: any // eslint-disable-line
  ) => {
    utils.changeValue(state, "Id", () => undefined);
  };

  return (
    <Form
      onSubmit={onUpdate}
      validate={validateFormValues(CustodianBankValidation)}
      keepDirtyOnReinitialize
      initialValues={custodianBank}
      mutators={{
        clearBankAccount,
      }}
    >
      {({ handleSubmit, submitError, submitting, values, form }) => {
        const bankAccountOptions = custodianBankList
          .filter(item => item.BankingPartnerOperator === values.BankingPartnerOperator)
          .map(item => ({
            label: item.Types.includes("BLINC")
              ? `${item.BlincId} - ${item.Types.join(", ")}`
              : `${item.BankAccountNumber} - ${item.Types.join(", ")} - ${item.Name}`,
            value: item.Id,
          }));

        return (
          <form onSubmit={handleSubmit}>
            <Field
              name={PAYMENT_CONFIRMATION_SCHEMA.BANKING_PARTNER_OPERATOR}
              component={ListBoxInput}
              options={bankNameOptions}
              label="Custodian Bank name"
              onChange={(BankingPartnerOperator: string) => {
                if (BankingPartnerOperator !== values.BankingPartnerOperator) {
                  form.mutators.clearBankAccount();
                }
                form.change("BankingPartnerOperator", BankingPartnerOperator);
              }}
            ></Field>
            <Field
              label="Custodian Bank account"
              name={PAYMENT_CONFIRMATION_SCHEMA.ID}
              component={ListBoxInput}
              options={bankAccountOptions}
            ></Field>
            {submitError && <ErrorMessage>{submitError}</ErrorMessage>}

            <ButtonContainer justifyContent="flex-start">
              <Button type="submit" varient="primary" color={color.greyscale.white} loading={submitting}>
                Save
              </Button>
              <Spacer width={spacing.m} />
            </ButtonContainer>
          </form>
        );
      }}
    </Form>
  );
}
