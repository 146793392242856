import { createColumnHelper } from "@tanstack/react-table";
import Constants from "constants/index";
import { format, utcToZonedTime } from "date-fns-tz";
import { getBlockchainKYTStatusVarient } from "pages/Transactions/functions/get-blockchain-kyt-status-varient";
import { getBlockchainStatusVarient } from "pages/Transactions/functions/get-blockchain-status-varient";
import { getFiatKYTStatusVarient } from "pages/Transactions/functions/get-fiat-kyt-status-varient";
import { getFiatStatusVarient } from "pages/Transactions/functions/get-fiat-status-varient";
import { parseBlockchainKYTStatusText } from "pages/Transactions/functions/parse-blockchain-kyt-status-text";
import { parseBlockchainStatusText } from "pages/Transactions/functions/parse-blockchain-status-text";
import { parseFiatKYTStatusText } from "pages/Transactions/functions/parse-fiat-kyt-status-text";
import { parseFiatStatusText } from "pages/Transactions/functions/parse-fiat-status-text";
import * as React from "react";
import { transformWalletAddress } from "refactor/transform-wallet-address";
import { Badge, formatCurrency, Table } from "stablr";
import { APITransactionListItem } from "types/API/Transaction";

import { TableCurrencyData } from "../../../../refactor/TableCurrencyData";

const { TableData } = Table;

const columnHelper = createColumnHelper<APITransactionListItem>();

export const BUY_ORDER_TABLE_COLUMNS = [
  columnHelper.accessor("CreatedDateTime", {
    header: "Date",
    cell: info => (
      <TableData>
        {info.getValue() ? format(utcToZonedTime(new Date(info.getValue()), "UTC"), "dd/MM/yyyy HH:mm") : "-"}
      </TableData>
    ),
    footer: info => info.column.id,
  }),
  columnHelper.accessor("TransactionId", {
    header: "Transaction ID",
    cell: info => <TableData>{transformWalletAddress(info.getValue())}</TableData>,
    footer: info => info.column.id,
  }),
  columnHelper.accessor("Fiat.FiatValue.Amount", {
    header: "Fiat Amount",
    cell: info => (
      <TableCurrencyData currency="EURO">
        {formatCurrency(
          {
            Amount: info.getValue(),
            Denominator: Constants.fallbackDenominator,
          },
          2
        )}
      </TableCurrencyData>
    ),
    footer: info => info.column.id,
  }),
  columnHelper.accessor("Fiat.Status", {
    header: "Payment",
    cell: info => (
      <TableData>
        {info.getValue() === "CONFIRMED" ? (
          parseFiatStatusText(info.getValue())
        ) : info.getValue() ? (
          <Badge noMargin text={parseFiatStatusText(info.getValue())} varient={getFiatStatusVarient(info.getValue())} />
        ) : (
          "-"
        )}
      </TableData>
    ),
    footer: info => info.column.id,
  }),
  columnHelper.accessor("Fiat.KYTStatus", {
    header: "Comply Advantage",
    cell: info => (
      <TableData>
        {info.getValue() === "COMPLETED" ? (
          parseFiatKYTStatusText(info.getValue())
        ) : info.getValue() ? (
          <Badge
            noMargin
            text={parseFiatKYTStatusText(info.getValue())}
            varient={getFiatKYTStatusVarient(info.getValue())}
          />
        ) : (
          "-"
        )}
      </TableData>
    ),
    footer: info => info.column.id,
  }),
  columnHelper.accessor("Blockchain.BlockchainValue.Amount", {
    header: "Stablecoin Amount",
    cell: info => (
      <TableCurrencyData currency="EURR">
        {formatCurrency(
          {
            Amount: info.getValue(),
            Denominator: Constants.fallbackDenominator,
          },
          2
        )}
      </TableCurrencyData>
    ),
    footer: info => info.column.id,
  }),
  columnHelper.accessor("Blockchain.KYTStatus", {
    header: "Chainalysis",
    cell: info => (
      <TableData>
        {info.getValue() === "COMPLETED" ? (
          parseBlockchainKYTStatusText(info.getValue())
        ) : info.getValue() ? (
          <Badge
            noMargin
            text={parseBlockchainKYTStatusText(info.getValue())}
            varient={getBlockchainKYTStatusVarient(info.getValue())}
          />
        ) : (
          "-"
        )}
      </TableData>
    ),
    footer: info => info.column.id,
  }),
  columnHelper.accessor("Blockchain.Status", {
    header: "Transaction",
    cell: info => (
      <TableData>
        {info.getValue() === "MINTED" ? (
          parseBlockchainStatusText(info.getValue())
        ) : info.getValue() ? (
          <Badge
            noMargin
            text={parseBlockchainStatusText(info.getValue())}
            varient={getBlockchainStatusVarient(info.getValue())}
          />
        ) : (
          "-"
        )}
      </TableData>
    ),
    footer: info => info.column.id,
  }),
];
