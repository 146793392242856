import { AccountOperator, BankingPartner } from "types/API/Governance";

import Connection from "./Connection";

type APIGetBankingPartners = BankingPartner[];
type APIGetAccountOperators = AccountOperator[];

export const getBankingPartners = (): Promise<APIGetBankingPartners> =>
  Connection.get(`/ops/governance/bankingpartners`);

export const getAccountOperators = (): Promise<APIGetAccountOperators> =>
  Connection.get(`/ops/governance/bankingpartners/accountoperators`);
