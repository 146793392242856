import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "api";
import Constants from "constants/index";
import { getErrorMessage, useToast } from "stablr";

import { useCustomer } from "./use-customer";

export function useRejectCustomer() {
  const queryClient = useQueryClient();
  const { errorToast, successToast } = useToast();

  return useMutation({
    mutationFn: async ({ organizationId }: { organizationId: string }) => api.Customer.rejectCustomer(organizationId),
    onSuccess: async (_: unknown, { organizationId }) => {
      // Added because backend is not completed by the time it returns 200 .... *sigh*
      setTimeout(async () => {
        await queryClient?.invalidateQueries({ queryKey: [useCustomer.queryKey, organizationId] });
      }, Constants.cache.defaultSocketDelay);

      successToast({ message: "Customer has been rejected successfully" });
    },
    onError: error => {
      errorToast({ message: getErrorMessage(error) });
    },
  });
}
