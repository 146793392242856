import useDownloadPayment from "hooks/query/payment/use-download-payment";
import * as React from "react";
import { Button, color } from "stablr";
import { ReactComponent as DownloadSVG } from "stablr/assets/icons/download.svg";

interface DownloadPaymentInformationProps {
  id: string;
  organizationId: string;
}

export function DownloadPaymentInstruction({ organizationId, id }: DownloadPaymentInformationProps) {
  const { refetch: refetchDownloadFile } = useDownloadPayment(organizationId, id);

  return (
    <Button
      size="small"
      icon={<DownloadSVG />}
      onClick={() => refetchDownloadFile()}
      varient="line"
      backgroundColor={color.greyscale.white}
    >
      Download Payment Instruction
    </Button>
  );
}
