import { APIDashboard } from "types/API/Dashboard";

import { MOCK_TRANSACTIONS } from "./mock-transaction";

export const MOCK_DASHBOARD: APIDashboard = {
  Transactions: [...MOCK_TRANSACTIONS].splice(0, 5),
  Wallets: [
    {
      Id: "1",
      Status: "PENDING",
      Name: "Test",
      Address: "123456",
      OrganizationId: "2345",
      Network: "0",
      Type: "1",
      FireblocksWalletId: "2345",
      Created: "",
    },
    {
      Id: "1",
      Status: "PENDING",
      Name: "Test",
      Address: "123456",
      OrganizationId: "2345",
      Network: "0",
      Type: "1",
      FireblocksWalletId: "2345",
      Created: "",
    },
    {
      Id: "1",
      Status: "PENDING",
      Name: "Test",
      Address: "123456",
      OrganizationId: "2345",
      Network: "0",
      Type: "1",
      FireblocksWalletId: "2345",
      Created: "",
    },
  ],
};
