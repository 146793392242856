import { useCustomers } from "hooks/query/customers/customer/use-customers";
import React from "react";
import { Heading } from "stablr";
import { ReactComponent as CustomersIcon } from "stablr/assets/icons/customers.svg";
import { CardEmpty, CardError, CardLoader, DashboardContainer } from "stablr/components/molecules";
import { getErrorMessage } from "stablr/functions";
import styled from "styled-components";

import { CustomerTable } from "./CustomerTable";

const HeadingStyled = styled(Heading)`
  padding-left: 50px;
`;
export function Customers() {
  const { data, isLoading, error, refetch } = useCustomers();
  return (
    <DashboardContainer>
      <HeadingStyled as="h2">Customer Overview</HeadingStyled>
      {error ? (
        <CardError body={getErrorMessage(error)} title="Error fetching customer(s)" onRetry={refetch} />
      ) : isLoading ? (
        <CardLoader />
      ) : !data?.length ? (
        <CardEmpty
          iconElement={<CustomersIcon />}
          title="No customers found"
          body="There is no customer at the moment"
        />
      ) : (
        <CustomerTable data={data} />
      )}
    </DashboardContainer>
  );
}
