import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "api";
import { useToast } from "stablr/hooks";
import { APIUploadDocument } from "types/API/Document";

import { useDocument } from "./use-document";
import { useDocuments } from "./use-documents";

export function useUpdateDocument() {
  const queryClient = useQueryClient();
  const { successToast } = useToast();

  return useMutation({
    mutationFn: async ({
      organizationId,
      fileId,
      document,
    }: {
      organizationId: string;
      fileId: string;
      document: APIUploadDocument;
    }) => {
      const data: APIUploadDocument = {
        Title: document.Title,
        FileName: document.FileName,
        Data: document.Data,
      };
      return api.Document.updateDocument(organizationId, fileId, data);
    },
    onSuccess: async (_: unknown, { organizationId }) => {
      await queryClient?.invalidateQueries({ queryKey: [useDocuments.queryKey] });
      await queryClient?.invalidateQueries({ queryKey: [useDocument.queryKey, organizationId] });
      successToast({ message: "Document has been updated successfully" });
    },
  });
}
