import Constants from "constants/index";
import { EURR_PROXY_ABI } from "contract/EURR_PROXY_ABI";
import { EURR_ABI } from "contract/EURR-ABI";
import * as React from "react";
import { CardTitle } from "stablr";
import styled from "styled-components";

import { ListContainer } from "../../components/ListContainer";
import { MetaMaskButton } from "./components/MetaMaskButton";
import { EthereumContractListItem } from "./EthereumContractListItem";

const PROXY_FUNCTIONS = ["changeAdmin", "upgradeTo", "upgradeToAndCall"];
const MINTER_FUNCTIONS = [
  "configureMinter",
  "removeMinter",
  "minterAllowance",
  "increaseAllowance",
  "decreaseAllowance",
  "increaseMinterAllowance",
  "decreaseMinterAllowance",
  "transfer", // Use for testing during development
];

const CONTRACT_MANAGEMENT_FUNCTIONS = [
  "pause",
  "unpause",
  "transferOwnership",
  "updateMasterMinter",
  "updateBlacklister",
  "updatePauser",
];

const CONTRACTS = {
  test: {
    symbol: "EURR-TEST",
    proxy: "0xc9D0d69F076935F8A65aBAEfA75bC7C48F3226A7",
    implementation: "0xF9CEd84494195755673033D5e33EEfEaF4287641",
  },
  development: {
    symbol: "EURR-DEV",
    proxy: "0x951baC8f3b8BB9eF85D2435BC203E1eE038A9Dc9",
    implementation: "0xd00465B43d3C13aFc2d96619A82b90120ace61bF",
  },
  acceptance: {
    symbol: "EURR-ACC",
    proxy: "0xdF2D914BA84B5128e2f653B649A5767215C2B728",
    implementation: "0x267364EeFd8DaD5d45F1B3ab9254CA9C62C7a197",
  },
  production: {
    symbol: "EURR",
    proxy: "0xdaC306D72f48dbaD805a11CBf7A512A277C084C9",
    implementation: "0x2F712f52389bFb82963D47a2dE3EFAd6f2963284",
  },
};

const EthereumNetworkStyled = styled.div``;

const EURR_CONTRACT = CONTRACTS[Constants.environment ?? "test"];

export function EthereumNetwork() {
  return (
    <EthereumNetworkStyled>
      <CardTitle
        title={`Ethereum${Constants.environment === "production" ? "" : " (Sepolia)"}`}
        buttonElement={<MetaMaskButton />}
      ></CardTitle>
      <ListContainer>
        <EthereumContractListItem
          symbol={EURR_CONTRACT.symbol}
          proxyAbi={EURR_PROXY_ABI}
          implementationAbi={EURR_ABI}
          proxyAddress={EURR_CONTRACT.proxy}
          implementationAddress={EURR_CONTRACT.implementation}
          proxyFunctionOptions={[
            {
              label: "Contract Management",
              items: PROXY_FUNCTIONS,
            },
          ]}
          implementationFunctionOptions={[
            {
              label: "Minter Management",
              items: MINTER_FUNCTIONS,
            },
            {
              label: "Contract Management",
              items: CONTRACT_MANAGEMENT_FUNCTIONS,
            },
          ]}
        />
      </ListContainer>
    </EthereumNetworkStyled>
  );
}
