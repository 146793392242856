import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Badge, Table } from "stablr/components/atoms";
import { TableHeaderIcon } from "stablr/components/atoms/Table";
import { useIsMobile } from "stablr/hooks";
import { fontWeight } from "stablr/styles";
import spacing from "stablr/styles/spacing";
import styled from "styled-components";
import { APICustomer, APICustomerStatusType } from "types/API/Customer";

import { getCustomerBadgeVarient } from "./functions/get-customer-badge-varient";
import { parseCustomerBadgetext } from "./functions/parse-customer-badge-text";

const { TableBody, TableData, TableHead, TableHeader, TableRow, TableRowBadge } = Table;

interface CustomerTableProps {
  data: APICustomer[];
}

const CustomerTableStyled = styled.table`
  padding: ${spacing.s} ${spacing.l};
  width: 100%;

  & th {
    font-weight: ${fontWeight.bold};
  }
`;

const CustomerTableWrapperStyled = styled.div`
  overflow-x: auto;

  & tr {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    & span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 200px;
    }

    & span[data-testid="Badge"] {
      max-width: unset;
      margin: 0;
    }

    & b {
      font-weight: ${fontWeight.semiBold};
    }
  }
`;

const columnHelper = createColumnHelper<APICustomer>();

export function CustomerTable({ data }: CustomerTableProps) {
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const [sorting, setSorting] = React.useState<SortingState>([]);

  const handleOnActionsClick = (id: string) => {
    navigate(`/customers/${id}/customer-info`);
  };

  const columns = React.useMemo(() => {
    const tableColumns = [
      columnHelper.accessor("Name", {
        header: "Name",
        cell: info => info.getValue(),
        footer: info => info.column.id,
      }),
      columnHelper.accessor("Id", {
        header: "Customer ID",
        cell: info => info.getValue(),
        footer: info => info.column.id,
      }),
      columnHelper.accessor("Country", {
        header: "Country",
        cell: info => info.getValue(),
        footer: info => info.column.id,
      }),
      columnHelper.accessor("Status", {
        header: "Status",
        cell: info => displayStatus(info.getValue()),
        footer: info => info.column.id,
      }),
    ];

    const displayStatus = (status: APICustomerStatusType) => {
      if (status === "APPROVED") {
        return "Approved";
      } else {
        return <Badge text={parseCustomerBadgetext(status)} varient={getCustomerBadgeVarient(status)} />;
      }
    };

    const mobileColumns = [tableColumns[0], tableColumns[1], tableColumns[3]];
    const col = [...(isMobile ? mobileColumns : tableColumns)];
    return col;
  }, [isMobile]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <CustomerTableWrapperStyled>
      <CustomerTableStyled>
        <TableHead>
          <>
            {table.getHeaderGroups().map(headerGroup => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <TableHeader
                    icon={<TableHeaderIcon header={header} />}
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHeader>
                ))}
              </TableRow>
            ))}
          </>
        </TableHead>
        <TableBody>
          <>
            {table.getRowModel().rows.map(row => (
              <React.Fragment key={row.getValue("Id")}>
                {isMobile && (
                  <TableRowBadge
                    colSpan={3}
                    badgeElement={
                      <Badge
                        varient={getCustomerBadgeVarient(row.original?.Status)}
                        text={parseCustomerBadgetext(row.original?.Status)}
                      />
                    }
                  ></TableRowBadge>
                )}
                <TableRow key={row.id} onRowClick={() => handleOnActionsClick(row.getValue("Id"))}>
                  {row.getVisibleCells().map(cell => {
                    return (
                      <TableData key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableData>
                    );
                  })}
                </TableRow>
              </React.Fragment>
            ))}
          </>
        </TableBody>
      </CustomerTableStyled>
    </CustomerTableWrapperStyled>
  );
}
