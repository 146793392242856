import { useQuery } from "@tanstack/react-query";
import api from "api";

useWallets.queryKey = "organisation-wallets";

export function useWallets(organizationId: string) {
  return useQuery(
    [useWallets.queryKey, organizationId],
    async () => api.Customer.getCustomerWalletList(organizationId),
    {
      notifyOnChangeProps: "all",
    }
  );
}
