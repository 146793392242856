import React from "react";
import { Field } from "react-final-form";
import { FileUploadInput, FormInformationList, TextInput } from "stablr/components/molecules";
import { FormLayout } from "stablr/components/organisms";

import { DOCUMENT_SCHEMA } from "./schemaValidation";

interface DocumentFormProps {
  disabled?: boolean;
  isEditForm?: boolean;
  documentTitle?: string;
}

export default function DocumentForm({ disabled, isEditForm }: DocumentFormProps) {
  return (
    <>
      <FormLayout columns={2}>
        <Field
          name={DOCUMENT_SCHEMA.TITLE}
          label="Name"
          placeholder="Name"
          component={TextInput}
          disabled={isEditForm ? true : disabled}
          compact
        />
      </FormLayout>
      <FormLayout columns={2}>
        <Field
          name={DOCUMENT_SCHEMA.FILE}
          component={FileUploadInput}
          label="Document"
          placeholder={"Upload Document"}
          description="(.PDF Format)"
        />
        <FormInformationList
          title="Document requirements:"
          bulletPoints={["PDF Format.", "File Size Limit: max size allowed is 10MB per file."]}
        />
      </FormLayout>
    </>
  );
}
