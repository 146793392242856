import { useRejectCustomer } from "hooks/query/customers/customer/use-reject-customer";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as RemoveCirleIcon } from "stablr/assets/icons/remove-circle.svg";
import { Button } from "stablr/components/atoms";
import { ActionModal } from "stablr/components/organisms";
import { color } from "stablr/styles";

export default function RejectCustomer({ title, organizationId }: { organizationId: string; title: string }) {
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const { mutateAsync: mutateAsyncRejectCustomer, isLoading } = useRejectCustomer();

  const openModal = (event: React.MouseEvent<HTMLElement>) => {
    event?.preventDefault();
    setOpen(true);
  };

  const onCloseModal = () => !isLoading && setOpen(false);

  const rejectCustomer = async () => {
    await mutateAsyncRejectCustomer({ organizationId }, { onSuccess: () => navigate(-1) });
    onCloseModal();
  };

  return (
    <>
      <Button
        onClick={openModal}
        icon={<RemoveCirleIcon />}
        width="160px"
        backgroundColor={color.palette.red}
        loading={isLoading}
      >
        Reject
      </Button>
      <ActionModal
        open={open}
        onClose={onCloseModal}
        title={`Reject Customer ${title}`}
        description={`Are you sure you want to reject ${title}?`}
        btnText="Reject"
        btnBackgroundColor={color.palette.red}
        btnColor={color.greyscale.white}
        onConfirm={rejectCustomer}
        loading={isLoading}
      />
    </>
  );
}
