import format from "date-fns/format";
import { useFinanceOverview } from "hooks/query/finance/use-finance-overview";
import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getErrorMessage } from "stablr";
// Uncomment when we enable downoad and date filter
// import { ReactComponent as DownloadSVG } from "stablr/assets/icons/download.svg";
import { Spacer, TabSwitch } from "stablr/components/atoms";
import {
  CardError,
  CardLoader,
  Container,
  DashboardContainer,
  ExpandableListItem,
  ListWrapper,
} from "stablr/components/molecules";
import { spacing } from "stablr/styles";
import styled from "styled-components";

import FinanceTable from "./components/FinanceTable";

const NavigationContainerStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

// Uncomment when we enable downoad and date filter
// const RightNavigationStyled = styled.div`
//   flex: 1;
//   display: flex;
//   justify-content: flex-end;
//   align-items: center;
// `;

export function Finance() {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const normalisedPath = pathname.toLowerCase();
  const location = normalisedPath.startsWith("/finance/year")
    ? "year"
    : normalisedPath.startsWith("/finance/month")
    ? "month"
    : "day";

  const handleOnLocationChange = (newValue: string) => {
    navigate(`/finance/${newValue}`);
  };

  const { data, isLoading, error, refetch } = useFinanceOverview(location);

  return (
    <DashboardContainer>
      <Container marginLeft={spacing.l} marginRight={spacing.l} marginTop={spacing.m} marginBottom={spacing.m}>
        <NavigationContainerStyled>
          <TabSwitch
            options={[
              { id: "day", label: "Daily" },
              { id: "month", label: "Monthly" },
              { id: "year", label: "Yearly" },
            ]}
            value={location}
            onChange={handleOnLocationChange}
          ></TabSwitch>
          <Spacer width={spacing.xl} />
          {/* Uncomment when we enable this feature */}
          {/* <RightNavigationStyled>
            <Button
              varient="line"
              size="medium"
              icon={<DownloadSVG />}
              iconSize={24}
              marginHorizontalSize="small"
              color={color.theme.primary}
              borderColor={color.theme.primary}
            >
              Download All Data
            </Button>
            <Spacer width={spacing.m} />
            <Button varient="secondary" size="medium" backgroundColor={color.greyscale.grey1}>
              Select Date
            </Button>
          </RightNavigationStyled> */}
        </NavigationContainerStyled>
        {isLoading ? (
          <CardLoader />
        ) : data !== undefined ? (
          <ListWrapper>
            {data.map(item => {
              return (
                <ExpandableListItem
                  key={item.Date}
                  title={format(
                    new Date(item.Date),
                    location === "day" ? "yyyy-MM-dd" : location === "month" ? "yyyy-MM" : "yyyy"
                  )}
                >
                  <FinanceTable data={item} />
                </ExpandableListItem>
              );
            })}
          </ListWrapper>
        ) : (
          <CardError title="Error" body={getErrorMessage(error)} onRetry={refetch} />
        )}
      </Container>
    </DashboardContainer>
  );
}
