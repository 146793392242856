import Constants from "constants/index";
import React, { useMemo } from "react";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import { useCurrentLocationFromPaths } from "refactor/use-current-location-from-paths";
import { DropDown } from "stablr/components/atoms";
import { DashboardContainer, Tabs } from "stablr/components/molecules";
import { useIsMobile } from "stablr/hooks";
import { media, spacing } from "stablr/styles";
import styled from "styled-components";

import BankAccounts from "./BankAccount";
import CustomerInfoPage from "./CustomerInformation";
import Documents from "./Documents";
import AddDocument from "./Documents/AddDocument";
import DocumentVersions from "./Documents/DocumentVersions";
import EditDocument from "./Documents/EditDocument";
import CustodianBank from "./SettingsCustodian";
import { TransactionProfilePage } from "./SettingsTransactionProfile";
import Wallets from "./Wallet";

const TabPanelsStyled = styled.div`
  @media (${media.small}) {
    padding: ${spacing.s} ${spacing.l};
  }
`;

const DisableCustomerDocuments = Constants.features.disableCustomerDocuments;
const DisableCustodianBank = Constants.features.disableCustomerCustodianBank;

function Customer() {
  const { id } = useParams();
  const navigate = useNavigate();

  const TAB_LIST = [
    {
      label: "Customer Information",
      value: `/customers/${id}/customer-info`,
    },
    {
      label: "Settings",
      value: `/customers/${id}/custodian-bank`,
      options: [
        {
          label: "Transaction Profile",
          value: `/customers/${id}/transaction-profile`,
        },
        ...(DisableCustodianBank
          ? []
          : [
              {
                label: "Custodian Bank",
                value: `/customers/${id}/custodian-bank`,
              },
            ]),
      ],
    },
    {
      label: "Bank Account",
      value: `/customers/${id}/bank-accounts`,
    },
    {
      label: "Wallets",
      value: `/customers/${id}/wallets`,
    },
    ...(DisableCustomerDocuments
      ? []
      : [
          {
            label: "Documents",
            value: `/customers/${id}/documents`,
          },
        ]),
  ];

  const isMobile = useIsMobile();

  const handleOnTabChange = React.useCallback(
    (value: string) => {
      navigate(value);
    },
    [navigate]
  );

  const { permittedTabList, routes } = useMemo(() => {
    return {
      permittedTabList: TAB_LIST,
      routes: (
        <TabPanelsStyled>
          <Routes>
            <Route path="/*" element={<CustomerInfoPage />} />
            <Route path="/customer-info" element={<CustomerInfoPage />} />
            <Route path="/custodian-bank" element={<CustodianBank />} />
            <Route path="/transaction-profile" element={<TransactionProfilePage />} />
            <Route path="/bank-accounts" element={<BankAccounts />} />
            <Route path="/wallets" element={<Wallets />} />
            <Route path="/documents/*" element={<Documents />} />
            <Route path="/documents/add" element={<AddDocument />} />
            <Route path="/documents/:fileId/edit" element={<EditDocument />} />
            <Route path="/documents/:fileId/versions" element={<DocumentVersions />} />
          </Routes>
        </TabPanelsStyled>
      ),
    };
  }, []);

  const flatRoutes = permittedTabList
    .map(tabItem => (tabItem.options ? tabItem.options.map(option => option.value) : tabItem.value))
    .flat();
  const flatPermittedTabs = permittedTabList.map(tabItem => tabItem.options ?? tabItem).flat();
  const currentLocation = useCurrentLocationFromPaths(flatRoutes);

  return (
    <DashboardContainer>
      {isMobile ? (
        <>
          <DropDown
            defaultValue={permittedTabList[0].value}
            options={permittedTabList}
            value={permittedTabList[currentLocation]?.value as string}
            onChange={handleOnTabChange}
          />
          {routes}
        </>
      ) : (
        <Tabs
          value={flatPermittedTabs[currentLocation]?.value as string}
          tabList={permittedTabList}
          onChange={handleOnTabChange}
        >
          {routes}
        </Tabs>
      )}
    </DashboardContainer>
  );
}

export default Customer;
