import { FORM_ERROR } from "final-form";
import { useCustodianBanks } from "hooks/query/custodian-banks/use-custodian-banks";
import { useCustodianBank } from "hooks/query/customers/custodianBank/use-custodian-bank";
import { useUpdateCustodianBank } from "hooks/query/customers/custodianBank/use-update-custodian-bank";
import { useAccountOperators } from "hooks/query/governance/use-account-operators";
import React from "react";
import { useParams } from "react-router-dom";
import { CardEmpty, CardError, CardLoader, DashboardContainer, getErrorMessage, Heading } from "stablr";
import { ReactComponent as BusinessIconSVG } from "stablr/assets/icons/business.svg";

import { CustodianBankForm, CustodianBankFormValues } from "./CustodianBankForm";

function CustomerInfoPage() {
  const { id } = useParams();

  const {
    data: custodianBank,
    isLoading: isLoadingCustodianBank,
    error: errorCustodianBank,
    refetch: refetchCustodianBank,
  } = useCustodianBank(`${id}`);
  const {
    data: custodianBanks,
    isLoading: isLoadingCustodianBanks,
    error: errorCustodianBanks,
    refetch: refetchCustodianBanks,
  } = useCustodianBanks();
  const {
    data: accountOperators,
    isLoading: isLoadingAccountOperators,
    error: errorAccountOperators,
    refetch: refetchAccountOperators,
  } = useAccountOperators();
  const { mutateAsync } = useUpdateCustodianBank();

  const isLoading = isLoadingCustodianBank || isLoadingCustodianBanks || isLoadingAccountOperators;
  const error = errorCustodianBanks
    ? errorCustodianBanks
    : errorAccountOperators
    ? errorAccountOperators
    : errorCustodianBank;

  const currentBankAccount = custodianBanks?.find(bank => bank.Id === custodianBank?.BankAccountId);
  const hasNoCustodianBankAssigned = errorCustodianBank && (errorCustodianBank as any)?.response.statusCode === 404; // eslint-disable-line

  const handleOnSubmit = async (values: CustodianBankFormValues) => {
    const BankAccount = custodianBanks?.find(bank => bank.Id === values.Id);
    try {
      if (BankAccount) {
        await mutateAsync({
          organizationId: `${id}`,
          bankAccountId: BankAccount.Id,
        });
      } else {
        return {
          [FORM_ERROR]: "Could not find matching Bank Account",
        };
      }
    } catch (er) {
      return {
        [FORM_ERROR]: getErrorMessage(er),
      };
    }
  };

  return (
    <DashboardContainer>
      <Heading as="h3">Custodian Bank{hasNoCustodianBankAssigned ? " - (No default)" : ""}</Heading>
      {error && !hasNoCustodianBankAssigned ? (
        <CardError
          body={getErrorMessage(error)}
          title="Error fetching custodian bank"
          onRetry={() => {
            if (errorCustodianBanks) refetchCustodianBanks();
            if (errorAccountOperators) refetchAccountOperators();
            else refetchCustodianBank();
          }}
        />
      ) : isLoading ? (
        <CardLoader />
      ) : !custodianBanks ? (
        <CardEmpty
          iconElement={<BusinessIconSVG />}
          title="No custodian banks found"
          body="There are currently no custodial banks"
        />
      ) : !accountOperators ? (
        <CardEmpty
          iconElement={<BusinessIconSVG />}
          title="No account operators found"
          body="There are currently no account operators"
        />
      ) : (
        <CustodianBankForm
          accountOperators={accountOperators}
          custodianBank={currentBankAccount}
          onUpdate={handleOnSubmit}
          custodianBankList={custodianBanks}
        />
      )}
    </DashboardContainer>
  );
}

export default CustomerInfoPage;
