import * as React from "react";
import { Route, Routes } from "react-router-dom";

import SignIn from "./screens/SignIn";

export default function SignUpComponent() {
  return (
    <Routes>
      <Route path="" element={<SignIn />} />
    </Routes>
  );
}
