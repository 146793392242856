import * as React from "react";
import { ReactComponent as DashboardSVG } from "stablr/assets/icons/dashboard.svg";
import { CardEmpty, CardWrapper } from "stablr/components/molecules";
import { color, media, spacing } from "stablr/styles";
import styled from "styled-components";
// import { APITransactionListItem } from "types/API/Transaction";

const LARGE_DEFAULT_HEIGHT = "550px";
const SMALL_DEFAULT_HEIGHT = "550px";
// interface RecentActivityCardProps {
//   // title: string;
//   // buttonElement?: React.ReactElement;
//   // data?: APITransactionListItem[];
// }

const RecentActivityCardStyled = styled(CardWrapper)`
  background: ${color.greyscale.white};
  margin-top: ${spacing.l};
  padding-bottom: ${spacing.l};
  position: relative;
  display: flex;
  flex-direction: column;
  @media ${media.mobile} {
    height: ${SMALL_DEFAULT_HEIGHT};
  }
  @media ${media.desktop} {
    height: ${LARGE_DEFAULT_HEIGHT};
  }
`;

export default function RecentActivityCardComponent() {
  return (
    <RecentActivityCardStyled>
      <CardEmpty
        title="Dashboard"
        body="More dashboard items will appear here in the future."
        iconElement={<DashboardSVG />}
      />
    </RecentActivityCardStyled>
  );
}
