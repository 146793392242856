import { useQuery } from "@tanstack/react-query";
import api from "api";

useCustodianBanks.queryKey = "custodianBanks";

export function useCustodianBanks() {
  return useQuery([useCustodianBanks.queryKey], async () => api.CustodianBanks.getCustodianBanks(), {
    notifyOnChangeProps: "all",
  });
}
