import { APICustomerStatusType } from "types/API/Customer";

export function getCustomerBadgeVarient(status: APICustomerStatusType) {
  switch (`${status}`) {
    case "PENDING":
      return "info";
    case "APPROVED":
      return "success";
    case "REJECTED":
      return "error";
    default:
      return "default";
  }
}
