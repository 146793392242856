import { APIBlockchainTransactionStatus } from "types/API/Transaction";

export function parseBlockchainStatusText(status: APIBlockchainTransactionStatus): string {
  switch (`${status}`) {
    case "PENDING":
      return "Pending";
    case "CONFIRMED":
      return "Confirmed";
    case "FAILED":
      return "Failed";
    case "BURNED":
      return "Burned";
    case "MINTED":
      return "Minted";
    case "TRANSFERRED":
      return "Transferred";
    case "REJECTED":
      return "Rejected";
    default:
      return status ?? "undefined";
  }
}

// "PENDING" | "CONFIRMED" | "FAILED" | "BURNED" | "MINTED" | "TRANSFERRED";
