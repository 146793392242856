import { del, get, patch, post, put } from "aws-amplify/api";
import { fetchAuthSession } from "aws-amplify/auth";
import Constants from "constants/index";

interface SocketResponse<T = void> {
  Result: "Fail" | "Success";
  Message?: string;
  Data?: T;
}

export type SocketErrorResponse = SocketResponse<{ Cause: string }>;

const logError = (error: any) => console.log("Error", error);

const getErrorMessage = (error: SocketErrorResponse): string => {
  const errorObject = error?.Data?.Cause && JSON.parse(error?.Data?.Cause);
  return errorObject ? errorObject?.errorMessage : error.Message;
};

const connectSocket = async <T>(eventId: string): Promise<unknown> => {
  const baseUrl = process.env.REACT_APP_BASE_WEBSOCKET;
  const socketURL = `${baseUrl}?eventId=${eventId}`;
  const socket = new WebSocket(socketURL);
  const message: unknown = await new Promise((resolve, reject) => {
    const timeout = setTimeout(() => {
      reject("Socket connection timeout");
      socket.close();
    }, 30 * 1000);

    socket.onerror = () => {
      reject("Socket connection failure");
      clearTimeout(timeout);
    };
    socket.onmessage = ({ data }: MessageEvent<string>) => {
      let result;
      try {
        result = JSON.parse(data) as SocketResponse<T>;
        if (result.Result === "Fail") {
          reject(getErrorMessage(result as SocketErrorResponse));
        } else if (result.Result === "Success") {
          resolve(result.Data);
        } else {
          reject("Unhandled reponse rejected!");
        }
      } catch (er) {
        reject("Invalid JSON response from Socket Message");
      }
      clearTimeout(timeout);
      socket.close();
    };
  });

  return message;
};

const Connection = {
  get: async (path: string, options?: object, errorEventName?: string) => {
    try {
      const restOperation = await get({
        apiName: Constants.apiName,
        path: `api/v1${path}`,
        options: {
          ...(options ? options : {}),
          headers: await getHeaders(),
        },
      }).response;

      const response = await restOperation.body.json();
      return response as any;
    } catch (er) {
      errorEventName && logError(errorEventName);
      throw er;
    }
  },
  post: async (path: string, body?: object, options?: object, errorEventName?: string) => {
    try {
      const restOperation = await post({
        apiName: Constants.apiName,
        path: `api/v1${path}`,
        options: {
          ...(options ? options : {}),
          headers: await getHeaders(),
          body: body as any, // eslint-disable-line
        },
      }).response;
      const response = await restOperation.body.json();
      return response as any;
    } catch (er) {
      errorEventName && logError(errorEventName);
      throw er;
    }
  },
  postFile: async (path: string, body?: any, options?: object, errorEventName?: string) => {
    const formData = new FormData();

    for (const name in body) {
      formData.append(name, body[name]);
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/v1${path}`, {
        method: "POST",
        body: formData,
        ...(options ? options : {}),
        headers: { ...(await getHeaders()) },
      });
      if (!response.ok) {
        const message = (await response.json())?.message ?? "Failed to submit file";
        throw new Error(`${message}`);
      }
      return response;
    } catch (er) {
      errorEventName && logError(errorEventName);
      throw er;
    }
  },
  putFile: async (path: string, body?: any, options?: object, errorEventName?: string) => {
    const formData = new FormData();

    for (const name in body) {
      formData.append(name, body[name]);
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/v1${path}`, {
        method: "PUT",
        body: formData,
        ...(options ? options : {}),
        headers: { ...(await getHeaders()) },
      });
      if (!response.ok) {
        const message = (await response.json())?.message ?? "Failed to submit file";
        throw new Error(`${message}`);
      }
      return response;
    } catch (er) {
      errorEventName && logError(errorEventName);
      throw er;
    }
  },
  postSocket: async (path: string, body?: object, options?: object, errorEventName?: string) => {
    try {
      const restOperation = (await post({
        apiName: Constants.apiName,
        path: `api/v1${path}`,
        options: {
          ...(options ? options : {}),
          headers: await getHeaders(),
          body: body as any, // eslint-disable-line
        },
      }).response) as any; // eslint-disable-line
      const response = await restOperation.body.json();
      const eventId = response["Entries"][0]["EventId"];
      return connectSocket(eventId);
    } catch (er) {
      errorEventName && logError(errorEventName);
      throw er;
    }
  },
  // Temporary adjustment to resolve an event id issue for BE, this returns event ID and/or takes event ID to subscribe with instead of the returned event ID
  postSocketWithEvent: async (
    path: string,
    body?: object,
    options?: object,
    errorEventName?: string,
    alternativeEventId?: string
  ) => {
    try {
      const restOperation = (await post({
        apiName: Constants.apiName,
        path: `api/v1${path}`,
        options: {
          ...(options ? options : {}),
          headers: await getHeaders(),
          body: body as any, // eslint-disable-line
        },
      }).response) as any; // eslint-disable-line
      const response = await restOperation.body.json(); //
      const eventId = alternativeEventId ?? response["Entries"][0]["EventId"];
      const socketResponse = await connectSocket(eventId);
      return {
        data: socketResponse,
        eventId,
      } as any;
    } catch (er) {
      errorEventName && logError(errorEventName);
      throw er;
    }
  },
  delete: async (path: string, body?: object, options?: object) => {
    const restOperation = await del({
      apiName: Constants.apiName,
      path: `api/v1${path}`,
      options: {
        ...(options ? options : {}),
        headers: await getHeaders(),
      },
    }).response;
    const response = restOperation;
    return response;
  },
  deleteSocket: async (path: string, body?: object, options?: object, errorEventName?: string) => {
    try {
      const response = (await del({
        apiName: Constants.apiName,
        path: `api/v1${path}`,
        options: {
          ...(options ? options : {}),
          headers: await getHeaders(),
        },
      }).response) as any; // eslint-disable-line
      const eventId = response["Entries"][0]["EventId"];
      return connectSocket(eventId);
    } catch (er) {
      errorEventName && logError(errorEventName);
      throw er;
    }
  },
  // Temporary adjustment to resolve an event id issue for BE, this returns event ID and/or takes event ID to subscribe with instead of the returned event ID
  deleteSocketWithEvent: async <T>(
    path: string,
    body?: object,
    options?: object,
    errorEventName?: string,
    alternativeEventId?: string
  ) => {
    try {
      const response = (await del({
        apiName: Constants.apiName,
        path: `api/v1${path}`,
        options: {
          ...(options ? options : {}),
          headers: await getHeaders(),
        },
      }).response) as any; // eslint-disable-line
      const eventId = alternativeEventId ?? response["Entries"][0]["EventId"];
      const socketResponse = await connectSocket(eventId);
      return {
        data: socketResponse as T,
        eventId,
      };
    } catch (er) {
      errorEventName && logError(errorEventName);
      throw er;
    }
  },
  patch: async (path: string, body?: object, options?: object) => {
    const restOperation = await patch({
      apiName: Constants.apiName,
      path: `api/v1${path}`,
      options: {
        ...(options ? options : {}),
        headers: await getHeaders(),
        body: body as any, // eslint-disable-line
      },
    }).response;
    const response = await restOperation.body.json();
    return response;
  },
  patchSocket: async (path: string, body?: object, options?: object, errorEventName?: string) => {
    try {
      const response = (await patch({
        apiName: Constants.apiName,
        path: `api/v1${path}`,
        options: {
          ...(options ? options : {}),
          headers: await getHeaders(),
          body: body as any, // eslint-disable-line
        },
      }).response) as any; // eslint-disable-line
      const eventId = response["Entries"][0]["EventId"];
      return connectSocket(eventId);
    } catch (er) {
      errorEventName && logError(errorEventName);
      throw er;
    }
  },
  put: async (path: string, body?: object, options?: object, errorEventName?: string) => {
    try {
      const response = await put({
        apiName: Constants.apiName,
        path: `api/v1${path}`,
        options: {
          ...(options ? options : {}),
          headers: await getHeaders(),
          body: body as any, // eslint-disable-line
        },
      }).response;
      return response;
    } catch (er) {
      errorEventName && logError(errorEventName);
      throw er;
    }
  },
  putSocket: async (path: string, body?: object, options?: object, errorEventName?: string) => {
    try {
      const restOperation = (await put({
        apiName: Constants.apiName,
        path: `api/v1${path}`,
        options: {
          ...(options ? options : {}),
          headers: await getHeaders(),
          body: body as any, // eslint-disable-line
        },
      }).response) as any; // eslint-disable-line
      const response = await restOperation.body.json();
      const eventId = response["Entries"][0]["EventId"];
      return connectSocket(eventId);
    } catch (er) {
      errorEventName && logError(errorEventName);
      throw er;
    }
  },
  postNoAuth: async (path: string, body?: object, options?: object) => {
    const restOperation = await post({
      apiName: Constants.apiName,
      path: `api/v1${path}`,
      options: {
        ...(options ? options : {}),
        body: body as any, // eslint-disable-line
      },
    }).response;
    const response = await restOperation.body.json();
    return typeof response === "string" ? JSON.parse(response) : response;
  },
};

export default Connection;

const getHeaders = async () => ({
  Authorization: `Bearer ${(await fetchAuthSession()).tokens?.idToken}`,
});
