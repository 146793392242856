import * as React from "react";
import { color, fontFamily, fontSize, fontWeight, Icon, spacing } from "stablr";
import { ReactComponent as EUROIcon } from "stablr/assets/icons/currency-euro.svg";
import { ReactComponent as EURRIcon } from "stablr/assets/icons/currency-eurr.svg";
import styled from "styled-components";

const LARGE_PADDING = spacing.m;
const SMALL_PADDING = spacing.s;
const XSMALL_PADDING = spacing.xs;

export const TABLECurrencyDATA_VARIENT_MAP = {
  default: {},
  bold: {
    fontWeight: fontWeight.semiBold,
    color: color.greyscale.black,
  },
  red: {
    color: color.palette.red,
  },
};

const CURRENCY_MAP = {
  EURR: <EURRIcon />,
  EURO: <EUROIcon />,
};

const TableCurrencyDataStyled = styled.td(
  ({ $paddingSize }: { $paddingSize: "xsmall" | "small" | "large" }) => `
  text-align: left;
  padding: ${$paddingSize === "small" ? SMALL_PADDING : $paddingSize === "xsmall" ? XSMALL_PADDING : LARGE_PADDING} ${
    $paddingSize === "small" ? SMALL_PADDING : $paddingSize === "xsmall" ? XSMALL_PADDING : LARGE_PADDING
  };
  font-family: ${fontFamily.primary};
  font-weight: ${fontWeight.regular};
  font-size: ${fontSize.p};
  color: ${color.greyscale.black};
`
);

/**
 * See reason for float https://stackoverflow.com/a/56685632
 */
const TableHeaderFloat = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  min-width: 150px;
`;

const IconStyled = styled(Icon)`
  display: inline-block;
`;

export interface TableCurrencyDataProps extends React.TdHTMLAttributes<HTMLTableCellElement> {
  paddingSize?: "xsmall" | "small" | "large";
  varient?: "default" | "bold" | "red";
  currency: keyof typeof CURRENCY_MAP;
}

TableCurrencyData.testid = "TableCurrencyData";

export function TableCurrencyData({
  children,
  paddingSize = "large",
  varient = "default",
  currency,
  ...props
}: TableCurrencyDataProps) {
  return (
    <TableCurrencyDataStyled $paddingSize={paddingSize} style={TABLECurrencyDATA_VARIENT_MAP[varient]} {...props}>
      <TableHeaderFloat>
        <IconStyled>{CURRENCY_MAP[currency]}</IconStyled>
        {children}
      </TableHeaderFloat>
    </TableCurrencyDataStyled>
  );
}
