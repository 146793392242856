import { useDocumentVersions } from "hooks/query/customers/document/use-versions-document";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as PieChartIconSVG } from "stablr/assets/icons/pie-chart.svg";
import { Heading } from "stablr/components/atoms";
import { ActionHeader, CardEmpty, CardError, CardLoader, DashboardContainer } from "stablr/components/molecules";
import { getErrorMessage } from "stablr/functions";

import { DocumentVersionsTable } from "./DocumentVersionsTable";
import { LatestDocument } from "./LatestDocument";

function DocumentVersions() {
  const { id, fileId } = useParams();
  const { data, isLoading, error, refetch } = useDocumentVersions(`${id}`, `${fileId}`);
  const navigate = useNavigate();

  return (
    <DashboardContainer>
      {error ? (
        <CardError body={getErrorMessage(error)} title="Error fetching document(s)" onRetry={refetch} />
      ) : isLoading ? (
        <CardLoader />
      ) : !data || data.Versions?.length === 0 ? (
        <CardEmpty
          iconElement={<PieChartIconSVG />}
          title="No document found"
          body="There is no document at the moment"
        />
      ) : (
        <>
          <ActionHeader
            title={`${data.Title} - Version ${data.Versions.length}`}
            btnText="Upload New Version"
            testid="Add-Key"
            onBtnClick={() => navigate(`/customers/${id}/documents/${fileId}/edit`)}
          />
          <LatestDocument data={data.Versions[0]} />
          <Heading as="h5">Version History</Heading>
          <DocumentVersionsTable data={data.Versions} organizationId={id as string} />
        </>
      )}
    </DashboardContainer>
  );
}

export default DocumentVersions;
