import * as React from "react";
import { color, fontSize, fontWeight, spacing } from "stablr";
import { ReactComponent as ArrowDownIcon } from "stablr/assets/icons/chevron-down.svg";
import { ReactComponent as ArrowUpIcon } from "stablr/assets/icons/chevron-up.svg";
import { Icon } from "stablr/components/atoms";
import styled from "styled-components";

interface DropDownListItemProps {
  title: string;
  children: React.ReactElement | React.ReactElement[];
  size: "large" | "medium" | "small";
}

const LARGE_LIST_ITEM_HEIGHT = "80px";
const MEDIUM_LIST_ITEM_HEIGHT = "60px";
const SMALL_LIST_ITEM_HEIGHT = "40px";

const DropDownListItemStyled = styled.div`
  width: 100%;
  position: relative;
  cursor: pointer;
  user-select: none;
`;

const LargeDropdownListItemStyled = styled.div`
  height: ${LARGE_LIST_ITEM_HEIGHT};
  line-height: ${LARGE_LIST_ITEM_HEIGHT};
  padding: 0 ${spacing.m};
  font-size: ${fontSize.data};

  & > *:first-child {
    width: ${LARGE_LIST_ITEM_HEIGHT};
    height: ${LARGE_LIST_ITEM_HEIGHT};
  }
`;

const MediumDropdownListItemStyled = styled.div`
  height: ${MEDIUM_LIST_ITEM_HEIGHT};
  line-height: ${MEDIUM_LIST_ITEM_HEIGHT};
  font-weight: ${fontWeight.medium};
  font-size: ${fontSize.data};

  & > *:first-child {
    width: ${SMALL_LIST_ITEM_HEIGHT};
    height: ${MEDIUM_LIST_ITEM_HEIGHT};
  }
`;

const SmallDropdownListItemStyled = styled.div`
  height: ${SMALL_LIST_ITEM_HEIGHT};
  line-height: ${SMALL_LIST_ITEM_HEIGHT};
  font-size: ${fontSize.p};
  color: ${color.themeNew.primary};
  font-weight: ${fontWeight.semiBold};

  & > *:first-child {
    width: ${SMALL_LIST_ITEM_HEIGHT};
    height: ${SMALL_LIST_ITEM_HEIGHT};
  }
`;

const DropDownListIconStyled = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DropDownContentStyled = styled.div``;

export function DropDownListItem({ title, children, size }: DropDownListItemProps) {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <>
      <DropDownListItemStyled
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        as={
          size === "large"
            ? LargeDropdownListItemStyled
            : size === "medium"
            ? MediumDropdownListItemStyled
            : SmallDropdownListItemStyled
        }
      >
        {title}
        <DropDownListIconStyled>
          <Icon
            color={
              size === "large"
                ? color.greyscale.grey5
                : size === "medium"
                ? color.greyscale.black
                : color.themeNew.primary
            }
            size={12}
          >
            {isOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
          </Icon>
        </DropDownListIconStyled>
      </DropDownListItemStyled>
      {isOpen && (
        <DropDownContentStyled style={{ padding: size === "large" ? spacing.m : ` ${spacing.xxs} 0` }}>
          {children}
        </DropDownContentStyled>
      )}
    </>
  );
}
