import { useResubmitBlockchainKYT } from "hooks/query/transaction/use-resubmit-blockchain-kyt";
import * as React from "react";
import { Button, color } from "stablr";

interface DownloadPaymentInformationProps {
  transactionId: string;
  blockchainTransactionId: string;
  type: "BUY" | "SELL";
}

export function ResubmitBlockchainKYTButtton({
  type,
  transactionId,
  blockchainTransactionId,
}: DownloadPaymentInformationProps) {
  const { mutate, isLoading } = useResubmitBlockchainKYT();

  return (
    <Button
      loading={isLoading}
      color={color.greyscale.white}
      borderColor={color.theme.primary}
      size="small"
      varient="primary"
      backgroundColor={color.theme.primary}
      onClick={() => mutate({ blockchainTransactionId, transactionId, type: type })}
    >
      Re-submit Chainalysis
    </Button>
  );
}
