import { useQuery } from "@tanstack/react-query";
import api from "api";
import { GetFinanceOverviewType } from "api/Finance";
import { APIFinanceOverview } from "types/API/Finance";

useFinanceOverview.queryKey = "finance-overview";

export function useFinanceOverview(type: GetFinanceOverviewType) {
  return useQuery(
    [useFinanceOverview.queryKey, type],
    async () => api.Finance.getFinanceOverview(type) as Promise<APIFinanceOverview[]>,
    {
      notifyOnChangeProps: "all",
    }
  );
}
