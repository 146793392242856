import { Hub } from "@aws-amplify/core";
import { fetchAuthSession } from "aws-amplify/auth";
import UserData, { EMPTY_USER_DATA } from "context/UserData";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { CognitoUserData, EmptyCognitoUserData } from "types/amplify/CognitoUserData";

interface CognitoUserSessionContextProps {
  children: React.ReactElement;
}

export default function UserDataContextProvider({ children }: CognitoUserSessionContextProps) {
  const [cognitoData, setCognitoData] = React.useState<CognitoUserData | EmptyCognitoUserData>(EMPTY_USER_DATA);

  const navigate = useNavigate();

  React.useEffect(() => {
    const initialiseData = async () => {
      try {
        setCognitoData({ ...(await fetchAuthSession()).tokens?.idToken?.payload } as any);
      } catch (err) {
        setCognitoData(EMPTY_USER_DATA as EmptyCognitoUserData);
      }
    };
    initialiseData();

    const cancelListener = Hub.listen("auth", async (data: any) => {
      switch (data.payload.event) {
        case "signIn":
        case "cognitoHostedUI":
          setCognitoData({ ...(await fetchAuthSession()).tokens?.idToken?.payload } as any);
          break;
        case "signOut":
          setCognitoData(EMPTY_USER_DATA);
          break;
        case "signIn_failure":
          setCognitoData(EMPTY_USER_DATA);
          break;
        case "tokenRefresh":
          setCognitoData({
            ...(await fetchAuthSession()).tokens?.idToken?.payload,
            data: data?.payload,
          } as CognitoUserData);
          break;
        case "tokenRefresh_failure":
          setCognitoData(EMPTY_USER_DATA);
          break;
        case "autoSignIn":
          setCognitoData({
            ...EMPTY_USER_DATA,
            data: data?.payload?.data,
          } as CognitoUserData | EmptyCognitoUserData);
          break;
        case "autoSignIn_failure":
          setCognitoData(EMPTY_USER_DATA);
          navigate("/sign-in");
          // TODO create error toast
          break;
        case "configured":
        // Do nothing
      }
      return () => {
        cancelListener();
      };
    });
  }, [navigate]);

  return <UserData.Provider value={cognitoData}>{children}</UserData.Provider>;
}
