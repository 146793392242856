import { useQuery } from "@tanstack/react-query";
import api from "api";

useBankAccount.queryKey = "bank-account";

export function useBankAccount(organizationId: string, bankaccountId: string) {
  return useQuery(
    [useBankAccount.queryKey, organizationId],
    async () => api.Customer.getCustomerBankAccount(organizationId, bankaccountId),
    {
      notifyOnChangeProps: "all",
    }
  );
}
