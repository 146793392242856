import { useQuery } from "@tanstack/react-query";
import api from "api";

useDocumentVersions.queryKey = "document-versions";

export function useDocumentVersions(organizationId: string, fileId: string) {
  return useQuery(
    [useDocumentVersions.queryKey, organizationId, fileId],
    async () => api.Document.getDocumentVersions(organizationId, fileId),
    {
      notifyOnChangeProps: "all",
    }
  );
}