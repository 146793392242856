const apiName = "stablr-api";

const Constants = {
  apiName,
  environment: process.env.REACT_APP_ENV as "test" | "development" | "acceptance" | "production",
  fallbackDenominator: "." as "," | ".",
  routes: {
    authenticated: [
      { path: "/dashboard/*" },
      { path: "/treasury/*" },
      { path: "/finance/*" },
      { path: "/transactions/*" },
      { path: "/customers/*" },
      { path: "/contracts/*" },
    ], // All routes that are within the app must be included here to prevent being redirected
  },
  googleClientId: process.env.REACT_APP_GOOGLE_CLIENT_ID || "",
  alchemy: {
    network: process.env.REACT_APP_ALCHEMY_NETWORK ?? "",
    apiKey: process.env.REACT_APP_ALCHEMY_API_KEY ?? "",
  },
  cache: {
    defaultSocketDelay: 5000, // 5 seconds delay before cache resets when socket is not available
  },
  web3: {
    contracts: {
      EURR: process.env.REACT_APP_EURR_CONTRACT,
      EURR_PROXY: process.env.REACT_APP_EURR_PROXY_CONTRACT,
    },
    chainId: process.env.REACT_APP_CHAIN_ID,
  },
  features: {
    disableCustomerDocuments: process.env.REACT_APP_DISABLE_CUSTOMER_DOCUMENTS === "1" ? true : false,
    disableCustomerCustodianBank: process.env.REACT_APP_DISABLE_CUSTOMER_CUSTODIAN_BANK === "1" ? true : false,
  },
  awsEnv: {
    aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
    aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
    aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
    aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
    aws_cognito_identity_pool_id: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
    API: {
      endpoints: [
        {
          name: apiName,
          endpoint: process.env.REACT_APP_BASE_URL,
          custom_headers: () => ({
            "Content-Type": "application/json",
          }),
        },
      ],
    },
    federationTarget: "COGNITO_USER_POOLS",
    aws_cognito_username_attributes: ["EMAIL"],
    aws_cognito_social_providers: ["GOOGLE"],
    aws_cognito_signup_attributes: ["EMAIL"],
    oauth: {
      domain: process.env.REACT_APP_OAUTH_DOMAIN,
      scope: ["profile", "email", "openid"],
      redirectSignIn: process.env.REACT_APP_OAUTH_REDIRECT_SIGNIN,
      redirectSignOut: process.env.REACT_APP_OAUTH_REDIRECT_SIGNOUT,
      responseType: "token",
    },
  },
};

export default Constants;
