import { APIBankAccount } from "types/API/BankAccount";
import { APICustomer, APITransactionProfile } from "types/API/Customer";
import { APIWallet } from "types/API/Wallet";

import Connection from "./Connection";
// import { MOCK_CUSTODIAN_BANK } from "./mocks/mock-customers";

// const getCustomerList = (): Promise<APICustomer[]> => api.Mock.mockRequest("ops/customers", MOCK_CUSTOMERS_DATA);

export const getCustomerList = (): Promise<APICustomer[]> => Connection.get("/ops/customers");

export const getCustomer = (organizationId: string): Promise<APICustomer> =>
  Connection.get(`/ops/customers/${organizationId}`);

type APIGetCustodianBank = {
  BankAccountId: string;
};

export const getCustomerCustodianBank = (organizationId: string): Promise<APIGetCustodianBank> =>
  Connection.get(`/ops/customers/${organizationId}/custodianaccount`);

export const updateCustomerCustodianBank = (organizationId: string, bankAccountId: string): Promise<unknown> =>
  Connection.put(`/ops/customers/${organizationId}/custodianaccount`, { BankAccountId: bankAccountId });

// export const getCustomerCustodianBank = (organizationId: string): Promise<APIGetCustodianBank> =>
//   api.Mock.mockRequest(`/ops/organizations/${organizationId}/custodian-bank`, MOCK_CUSTODIAN_BANK) as Promise<any>;

// export const setCustomerCustodianBank = (organizationId: string, _: string): Promise<APIGetCustodianBank> =>
//   api.Mock.mockRequest(`/ops/organizations/${organizationId}/custodian-bank`, true) as Promise<any>;

export const rejectCustomer = (organizationId: string) => Connection.put(`/ops/customers/${organizationId}/reject`);

// const rejectCustomer = (organizationId: string): Promise<boolean> =>
//   api.Mock.mockRequest(`/customers/reject/${organizationId}`, true);

export const confirmCustomer = (organizationId: string) => Connection.put(`/ops/customers/${organizationId}/approve`);

// const confirmCustomer = (organizationId: string): Promise<boolean> =>
// api.Mock.mockRequest(`/customers/confirm/${organizationId}`, true);

// const getCustomerBankList = (organizationId: string): Promise<APIBankAccount[]> =>
//   api.Mock.mockRequest(`/customers/banks/${organizationId}`, MOCK_BANK_ACCOUNT);

export const getCustomerBankList = (organizationId: string): Promise<APIBankAccount[]> =>
  Connection.get(`/ops/customers/${organizationId}/bankaccounts`);

// const getCustomerBankAccount = (organizationId: string): Promise<APIBankAccount> =>
//   api.Mock.mockRequest(`customer/bank-account/${organizationId}`, MOCK_BANK_ACCOUNT[0]);

export const getCustomerBankAccount = (organizationId: string, bankaccountId: string): Promise<APIBankAccount> =>
  Connection.get(`/ops/customers/${organizationId}/bankaccounts/${bankaccountId}`);

export const rejectCustomerBank = (bankId: string) => Connection.put(`/ops/customers/bankaccounts/${bankId}/reject`);

// const confirmCustomerBank = (organizationId: string): Promise<boolean> =>
//   api.Mock.mockRequest(`/bank/confirm/${organizationId}`, true);

export const confirmCustomerBank = (bankId: string) => Connection.put(`/ops/customers/bankaccounts/${bankId}/approve`);

// export const getCustomerWalletList = (organizationId: string): Promise<APIWallet[]> =>
//   mockRequest(`/customers/banks/${organizationId}`, MOCK_WALLET_DATA);

export const getCustomerWalletList = (organizationId: string): Promise<APIWallet[]> =>
  Connection.get(`/ops/organizations/${organizationId}/wallets`);

export const approveWallet = (WalletId: string) => Connection.post("/ops/confirmwallet", { entityId: WalletId });

export const rejectWallet = (WalletId: string) => Connection.post(`/ops/rejectwallet`, { entityId: WalletId });

export const getTransactionProfile = (organizationId: string) =>
  Connection.get(`/ops/customers/${organizationId}/transaction-profile`);

export const updateTransactionProfile = (organizationId: string, data: Partial<APITransactionProfile>) =>
  Connection.put(`/ops/customers/${organizationId}/transaction-profile`, data);
