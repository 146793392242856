import React, { useMemo } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useCurrentLocationFromPaths } from "refactor/use-current-location-from-paths";
import { DropDown } from "stablr/components/atoms";
import { DashboardContainer, Tabs } from "stablr/components/molecules";
import { useIsMobile } from "stablr/hooks";
import { media } from "stablr/styles";
import styled from "styled-components";

import { BuyOrderTab } from "./BuyOrderTab";
import { SellOrderTab } from "./SellOrderTab";

const TAB_LIST = [
  {
    label: "Buy Orders",
    value: "/transactions/buy",
    testid: "transactions-buy",
  },
  {
    label: "Sell Orders",
    value: "/transactions/sell",
    testid: "transactions-sell",
  },
];

const DashboardContainerStyled = styled(DashboardContainer)`
  display: flex;
  flex-direction: column;
  @media ${media.mobile} {
    & > div:nth-child(2) {
      /* Different child in the mobile view because of dropdown */
      flex: 1;
      display: flex;
      flex-direction: column;
      position: relative;
    }
  }
  @media ${media.desktop} {
    & > div {
      flex: 1;
      display: flex;
      flex-direction: column;
      position: relative;
    }
  }
`;

const TabPanelsStyled = styled.div`
  flex: 1;
  overflow-x: scroll;
`;

export default function TransactionOverview() {
  const navigation = useNavigate();
  const isMobile = useIsMobile();

  const handleOnTabChange = React.useCallback(
    (value: string) => {
      navigation(value);
    },
    [navigation]
  );

  const { permittedTabList, routes } = useMemo(() => {
    return {
      permittedTabList: TAB_LIST,
      routes: (
        <Routes>
          <Route path="*" element={<BuyOrderTab />} />
          <Route path="/buy" element={<BuyOrderTab />} />
          <Route path="/sell" element={<SellOrderTab />} />
        </Routes>
      ),
    };
  }, []);

  const currentLocation = useCurrentLocationFromPaths(permittedTabList.map(tabItem => tabItem.value));

  return (
    <DashboardContainerStyled>
      {isMobile ? (
        <>
          <DropDown
            defaultValue={permittedTabList[0].value}
            options={permittedTabList}
            value={permittedTabList[currentLocation]?.value as string}
            onChange={handleOnTabChange}
          />
          <TabPanelsStyled>{routes}</TabPanelsStyled>
        </>
      ) : (
        <Tabs
          value={permittedTabList[currentLocation]?.value as string}
          tabList={permittedTabList}
          onChange={handleOnTabChange}
        >
          <TabPanelsStyled>{routes}</TabPanelsStyled>
        </Tabs>
      )}
    </DashboardContainerStyled>
  );
}
