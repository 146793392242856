import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "api";
import Constants from "constants/index";
import { getErrorMessage, useToast } from "stablr";

import { useCustodianBank } from "./use-custodian-bank";

export function useUpdateCustodianBank() {
  const queryClient = useQueryClient();
  const { errorToast, successToast } = useToast();

  return useMutation({
    mutationFn: async ({ organizationId, bankAccountId }: { organizationId: string; bankAccountId: string }) =>
      api.Customer.updateCustomerCustodianBank(organizationId, bankAccountId),
    onSuccess: async (_: unknown, { organizationId }) => {
      // Added because backend is not completed by the time it returns 200 .... *sigh*
      setTimeout(async () => {
        await queryClient?.invalidateQueries({ queryKey: [useCustodianBank.queryKey, organizationId] });
      }, Constants.cache.defaultSocketDelay);

      successToast({ message: "Custodian Bank updated" });
    },
    onError: error => {
      errorToast({ message: getErrorMessage(error) });
    },
  });
}
