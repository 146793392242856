import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "api";
import Constants from "constants/index";
import { getErrorMessage, useToast } from "stablr";

import { useWallets } from "./use-organisation-wallets";

export function useRejectWallet() {
  const queryClient = useQueryClient();
  const { errorToast, successToast } = useToast();

  return useMutation({
    mutationFn: async ({ walletId }: { walletId: string; organizationId: string }) =>
      api.Customer.rejectWallet(walletId),
    onSuccess: async (_: unknown, { organizationId }) => {
      // Added because backend is not completed by the time it returns 200 .... *sigh*
      setTimeout(async () => {
        await queryClient?.invalidateQueries({ queryKey: [useWallets.queryKey, organizationId] });
      }, Constants.cache.defaultSocketDelay);

      successToast({ message: "Wallet has been rejected successfully" });
    },
    onError: error => {
      errorToast({ message: getErrorMessage(error) });
    },
  });
}
