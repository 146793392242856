// import api from "api";
import { APIDocument, APIUploadDocument } from "types/API/Document";
import { APIFileType } from "types/API/File";

import Connection from "./Connection";
// import { MOCK_DOCUMENT_DATA } from "./mocks/mock-document";

export const getDocumentVersions = (organizationId: string, fileId: string) =>
  Connection.get(`/ops/files/${organizationId}/internal/${fileId}/versions`);

export const uploadDocument = (organizationId: string, data: APIUploadDocument) =>
  Connection.postFile(`/ops/files/${organizationId}/internal`, data);

//   const document: APIApplication = {
//     ...data,
//     Id: `${MOCK_DOCUMENT_DATA.length + 1}`,
//   };
//   MOCK_API_DATA.push(document);
//   return api.Mock.mockRequest("/api/organizations/api-keys", document);
// };

// const getDocumentList = (Id: string): Promise<APIDocument[]> =>
//   api.Mock.mockRequest(`/ops/files/${Id}/internal`, MOCK_DOCUMENT_DATA);

export const downloadDocument = (organizationId: string, fileId: string, fileType: APIFileType, version?: number) => {
  return Connection.post(`/ops/files/${organizationId}/${fileType}/${fileId}/requests`, { Version: version });
};
export const updateDocument = (organizationId: string, fileId: string, updatedDocument: APIUploadDocument) => {
  Connection.putFile(`/ops/files/${organizationId}/internal/${fileId}`, updatedDocument);
};

export const getDocumentList = (id: string): Promise<APIDocument[]> => Connection.get(`/ops/files/${id}/internal`);

export const deleteDocument = (orgId: string, fileId: string) =>
  Connection.delete(`/ops/files/${orgId}/internal/${fileId}`);

export const getDocumentById = (organizationId: string, fileId: string) =>
  Connection.get(`/ops/files/${organizationId}/internal/${fileId}`);
