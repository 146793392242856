import { APITransaction, APITransactionListItem } from "types/API/Transaction";

import Connection from "./Connection";
// import { mockRequest } from "./Mock";
// import { MOCK_TRANSACTION, MOCK_TRANSACTIONS } from "./mocks/mock-transaction";

export interface APIPostConfirmPayment {
  PaymentInstructionId: string;
  TransferDate: string;
  BankAccount: string;
  Currency: string;
  GrossAmount: number;
  FeeAmount: number;
  Description: string;
}

export const getTransactions = (type: "BUY" | "SELL"): Promise<APITransactionListItem[]> =>
  Connection.get(`/ops/transactions?type=${type.toLowerCase()}`);

// export const getTransactions = (type: "BUY" | "SELL"): Promise<APITransactionListItem[]> =>
//   mockRequest(`/ops/blockchain/transactions?Type=${type}`, MOCK_TRANSACTIONS);

export const getTransaction = (transactionId: string): Promise<APITransaction> =>
  Connection.get(`/ops/transactions/${transactionId}`);

// const getTransactionDetail = (transactionId: string): Promise<APITransactionDetail> =>
//   Mock.mockRequest(`/transactions/${transactionId}`, MOCK_TRANSACTION);

// const getTransaction = (transactionId: string) =>
//   Mock.mockRequest(`/transaction/${transactionId}`, {
//     ...MOCK_TRANSACTION,
//     Status:
//       transactionId === "1"
//         ? "PENDING"
//         : transactionId === "2"
//         ? "CONFIRMED"
//         : transactionId === "3"
//         ? "AWAITING_CONFIRMATION"
//         : "CONFIRMED",
//     Id: transactionId,
//   }) as Promise<APITransaction>;

interface APIPostConfirmBlockchainTransactionResponse {
  success: true;
}

export const confirmBlockchainTransaction = (id: string, transactionHash: string, type: "MINT" | "BURN") =>
  (type === "BURN"
    ? Connection.post("/ops/confirmburnorder", {
        entityId: id,
        transactionHash,
      })
    : Connection.post("/ops/confirmmintorder", {
        entityId: id,
        transactionHash,
      })) as Promise<APIPostConfirmBlockchainTransactionResponse>;

interface APIPostConfirmPaymentResponse {
  success: true;
}

export const confirmPayment = (paymentPayload: APIPostConfirmPayment, type: "BUY" | "SELL") =>
  Connection.post(
    `/ops/${type === "BUY" ? "confirminboundpayment" : "confirmpayout"}`,
    paymentPayload
  ) as Promise<APIPostConfirmPaymentResponse>;

export const resubmitBlockchainKYT = (id: string, type: "BUY" | "SELL") =>
  type === "BUY"
    ? Connection.post("/ops/revalidatemintorder", {
        MintOrderId: id,
      })
    : Connection.post("/ops/revalidateburnorder", {
        BurnOrderId: id,
      });
