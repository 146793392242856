export enum BANK_ACCOUNT_SCHEMA {
  ACCOUNT_NAME = "Name",
  BANK_NAME = "BankName",
  IBAN = "Iban",
  BANK_POSTAL_CODE = "BankPostalCode",
  BLINC_ID = "BlincId",
  BIC = "BIC",
  COUNTRY = "Country",
  CITY = "City",
  BANK_ADDRESS = "BankAddress",
  TYPE = "Type",
  BANK_STATEMENT = "BankStatement",
  BANK_STATEMENT_FILE_NAME = "BankStatementFileName", // Field only visible from existing data, not create/update
}
