import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import UserDataContextProvider from "../context/UserDataProvider";
import AuthenticationWrapper from "./AuthenticationWrapper";
import Contracts from "./Contracts";
import { Customers } from "./Customers";
import Customer from "./Customers/Tabs";
import BankAccount from "./Customers/Tabs/BankAccount/BankAccountInfo";
import Dashboard from "./Dashboard";
import DashboardMenu from "./DashboardMenu";
import { Finance } from "./Finance";
import SignIn from "./SignIn";
import TransactionsDetails from "./Transactions/TransactionDetail";
import TransactionsOverview from "./Transactions/TransactionOverview";

export default function PageRoutes() {
  return (
    <BrowserRouter>
      <UserDataContextProvider>
        <AuthenticationWrapper>
          {isAuthenticatedPath =>
            isAuthenticatedPath ? (
              <DashboardMenu>
                <RoutesComponent />
              </DashboardMenu>
            ) : (
              <RoutesComponent />
            )
          }
        </AuthenticationWrapper>
      </UserDataContextProvider>
    </BrowserRouter>
  );
}

function RoutesComponent() {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/sign-in/*" element={<SignIn />} />
      <Route path="/dashboard/*" element={<Dashboard />} />
      <Route path="/finance/*" element={<Finance />} />
      <Route path="/contracts/*" element={<Contracts />} />
      <Route path="/transactions/*" element={<TransactionsOverview />} />
      <Route path="/customers/*" element={<Customers />} />
      <Route path="/customers/:id/*" element={<Customer />} />
      <Route path="/customers/:id/bank-accounts/:bankId" element={<BankAccount />} />
      <Route path="/transactions/buy/:id" element={<TransactionsDetails />} />
      <Route path="/transactions/sell/:id" element={<TransactionsDetails />} />
    </Routes>
  );
}
