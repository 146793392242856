import { useQuery } from "@tanstack/react-query";
import api from "api";
import Constants from "constants/index";

useContractTotalSupply.queryKey = "contract-total-supply";

export function useContractTotalSupply(token: keyof typeof Constants.web3.contracts) {
  return useQuery([useContractTotalSupply.queryKey, token], async () => api.Web3.Contract.getTotalSupply(token), {
    notifyOnChangeProps: "all",
  });
}
