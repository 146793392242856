import { getCustomerBadgeVarient } from "pages/Customers/functions/get-customer-badge-varient";
import { parseCustomerBadgetext } from "pages/Customers/functions/parse-customer-badge-text";
import React from "react";
import { Field, Form } from "react-final-form";
import { Badge, DashboardContainer, FormLayout, Heading, media, Spacer, spacing, useIsMobile } from "stablr";
import { ErrorMessage, TextInput } from "stablr/components/molecules";
import styled from "styled-components";
import { APICustomer } from "types/API/Customer";

import ApproveCustomer from "./common/ApproveCustomer";
import RejectCustomer from "./common/RejectCustomer";
import { CUSTOMER_SCHEMA } from "./schemaValidation";

type CustomerInfoFormProps = {
  data: APICustomer;
};

const ButtonContainer = styled.div`
  display: flex;

  > * {
    margin-top: ${spacing.m};
    margin-bottom: ${spacing.l};
  }

  @media (${media.mobile}) {
    display: block;

    > * {
      margin-bottom: ${spacing.l};
    }
  }
`;

export function CustomerInfoForm({ data }: CustomerInfoFormProps) {
  const isMobile = useIsMobile();

  const handleOnSubmit = () => {
    //do nothing
  };

  const organizationId = data.Id;

  return (
    <DashboardContainer>
      <Heading as="h3">
        {data?.Name} <Badge text={parseCustomerBadgetext(data.Status)} varient={getCustomerBadgeVarient(data.Status)} />
      </Heading>

      <Form onSubmit={handleOnSubmit} initialValues={data}>
        {({ submitError }) => {
          return (
            <>
              {data?.Status === "PENDING" && (
                <ButtonContainer>
                  {isMobile ? <Spacer height={spacing.m} /> : <></>}
                  <ApproveCustomer title={data.Name} organizationId={organizationId} />
                  <Spacer width={spacing.l} />
                  <RejectCustomer title={data.Name} organizationId={organizationId} />
                </ButtonContainer>
              )}
              <FormLayout columns={2}>
                <>
                  <Field
                    label="Name (Entity Name)"
                    name={CUSTOMER_SCHEMA.NAME}
                    component={TextInput}
                    disabled
                    compact
                  />
                  <Field
                    label="Registration Number"
                    name={CUSTOMER_SCHEMA.REGISTRATION_NUMBER}
                    component={TextInput}
                    disabled
                    compact
                  />
                  <Field label="Address" name={CUSTOMER_SCHEMA.ADDRESS} compact component={TextInput} disabled />
                  <Field
                    label="Postal Code"
                    name={CUSTOMER_SCHEMA.POSTAL_CODE}
                    compact
                    component={TextInput}
                    disabled
                  />
                  <Field
                    placeholder={data?.City}
                    label="City"
                    name={CUSTOMER_SCHEMA.CITY}
                    compact
                    component={TextInput}
                    disabled
                  />
                  <Field label="Country" name={CUSTOMER_SCHEMA.COUNTRY} compact component={TextInput} disabled />
                </>
              </FormLayout>

              {submitError && <ErrorMessage>{submitError}</ErrorMessage>}
            </>
          );
        }}
      </Form>
    </DashboardContainer>
  );
}
