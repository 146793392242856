import { useBankAccount } from "hooks/query/customers/bank/use-bank-account";
import React from "react";
import { useParams } from "react-router-dom";
import { ReactComponent as PieChartIconSVG } from "stablr/assets/icons/pie-chart.svg";
import { CardEmpty, CardError, CardLoader } from "stablr/components/molecules";
import { getErrorMessage } from "stablr/functions";

import { BankAccountInfo } from "./BankAccountInfo";

function BankAccount() {
  const { id, bankId } = useParams();

  const { data, isLoading, error, refetch } = useBankAccount(`${id}`, `${bankId}`);

  return (
    <>
      {error ? (
        <CardError body={getErrorMessage(error)} title="Error fetching bank account(s)" onRetry={refetch} />
      ) : isLoading ? (
        <CardLoader />
      ) : !data ? (
        <CardEmpty
          iconElement={<PieChartIconSVG />}
          title="No bank account found"
          body="There is no bank account at the moment"
        />
      ) : (
        <>
          <BankAccountInfo data={data} />
        </>
      )}
    </>
  );
}

export default BankAccount;
