import { useWallets } from "hooks/query/customers/wallet/use-organisation-wallets";
import React from "react";
import { useParams } from "react-router-dom";
import { Heading, spacing } from "stablr";
import { ReactComponent as PieChartIconSVG } from "stablr/assets/icons/pie-chart.svg";
import { CardEmpty, CardError, CardLoader, DashboardContainer } from "stablr/components/molecules";
import { getErrorMessage } from "stablr/functions";
import styled from "styled-components";

import { WalletTable } from "./WalletTable";

const HeadingStyled = styled(Heading)`
  padding-left: ${spacing.m};
  padding-bottom: ${spacing.s};
`;

function Wallets() {
  const { id } = useParams();
  const { data, isLoading, error, refetch } = useWallets(`${id}`);

  return (
    <DashboardContainer>
      <HeadingStyled as="h2">Wallets Overview</HeadingStyled>
      {error ? (
        <CardError body={getErrorMessage(error)} title="Error fetching wallet(s)" onRetry={refetch} />
      ) : isLoading ? (
        <CardLoader />
      ) : !data || data.length === 0 ? (
        <CardEmpty iconElement={<PieChartIconSVG />} title="No wallet found" body="There is no wallet at the moment" />
      ) : (
        <>
          <WalletTable data={data} organizationId={id as string} />
        </>
      )}
    </DashboardContainer>
  );
}

export default Wallets;
