import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "api";
import Constants from "constants/index";
import { getErrorMessage, useToast } from "stablr";

import { useBankAccount } from "./use-bank-account";

export function useRejectBank() {
  const queryClient = useQueryClient();
  const { errorToast, successToast } = useToast();

  return useMutation({
    mutationFn: async ({ bankId }: { organizationId: string; bankId: string }) =>
      api.Customer.rejectCustomerBank(bankId),
    onSuccess: async () => {
      setTimeout(async (_, { organizationId }: { organizationId: string; bankId: string }) => {
        await queryClient?.invalidateQueries({ queryKey: [useBankAccount.queryKey, organizationId] });
      }, Constants.cache.defaultSocketDelay);
      successToast({ message: "Bank has been rejected successfully" });
    },
    onError: error => {
      errorToast({ message: getErrorMessage(error) });
    },
  });
}
