import * as yup from "yup";

export enum TRANSACTION_PROFILE_SCHEMA {
  YEAR = "Year",
  MONTH = "Month",
  DAY = "Day",
}

export const TransactionProfileSchemaValidation = yup.object({
  [TRANSACTION_PROFILE_SCHEMA.YEAR]: yup
    .number()
    .required("Year is required")
    .integer("This number can not have decimals")
    .positive("Year must be a positive number")
    .max(1000000000000, "This number must be less than or equal to 1 trillion")
    .nullable()
    .transform((value, originalValue) => (originalValue === "" ? null : value)),
  [TRANSACTION_PROFILE_SCHEMA.MONTH]: yup
    .number()
    .integer("This number can not have decimals")
    .positive("Month must be a positive number")
    .max(1000000000000, "This number must be less than or equal to 1 trillion")
    .nullable()
    .transform((value, originalValue) => (originalValue === "" ? null : value)),
  [TRANSACTION_PROFILE_SCHEMA.DAY]: yup
    .number()
    .integer("This number can not have decimals")
    .positive("Day must be a positive number")
    .max(1000000000000, "This number must be less than or equal to 1 trillion")
    .nullable()
    .transform((value, originalValue) => (originalValue === "" ? null : value)),
});
