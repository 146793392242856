import { APIBankAccountType } from "types/API/BankAccount";

export function getBankAccountType(type: APIBankAccountType): string {
  switch (`${type}`) {
    case "0":
      return "SEPA";
    case "1":
      return "SEPAA";
    default:
      return type.toString();
  }
}
