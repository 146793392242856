import Constants from "constants/index";
import format from "date-fns/format";
import { useTransaction } from "hooks/query/transaction/use-transaction";
import { getBlockchainKYTStatusVarient } from "pages/Transactions/functions/get-blockchain-kyt-status-varient";
import { getFiatKYTStatusVarient } from "pages/Transactions/functions/get-fiat-kyt-status-varient";
import { getFiatStatusVarient } from "pages/Transactions/functions/get-fiat-status-varient";
import { getTransactionStatusVarient } from "pages/Transactions/functions/get-transaction-status-varient";
import { parseBlockchainKYTStatusText } from "pages/Transactions/functions/parse-blockchain-kyt-status-text";
import { parseFiatKYTStatusText } from "pages/Transactions/functions/parse-fiat-kyt-status-text";
import { parseFiatStatusText } from "pages/Transactions/functions/parse-fiat-status-text";
import { parseTransactionStatusText } from "pages/Transactions/functions/parse-transaction-status-text";
import { ConfirmPaymentModal } from "pages/Transactions/TransactionDetail/common/ConfirmPaymentModal";
import React from "react";
import { useParams } from "react-router-dom";
import {
  Badge,
  Button,
  capitalize,
  CardError,
  CardLoader,
  color,
  DashboardContainer,
  fontWeight,
  formatCurrency,
  getErrorMessage,
  Heading,
  Icon,
  InlineLink,
  Spacer,
  spacing,
  Table,
  TableGroup,
} from "stablr";
import { ReactComponent as CheckedCircleSVG } from "stablr/assets/icons/checked-full-circle.svg";
import { ReactComponent as EthrereumSVG } from "stablr/assets/icons/ethereum.svg";
import { ReactComponent as ExternalSVG } from "stablr/assets/icons/external.svg";
import styled from "styled-components";

import { getBlockchainStatusVarient } from "../functions/get-blockchain-status-varient";
import { parseBlockchainStatusText } from "../functions/parse-blockchain-status-text";
import { ConfirmBlockchainTransactionModal } from "./common/ConfirmBlockchainTransactionModal";
import { DownloadPaymentInstruction } from "./common/DownloadPaymentInstruction";
import { ResubmitBlockchainKYTButtton } from "./common/ResubmitBlockchainKYTButton";

const { Table: TableComponent, TableBody, TableHeader, TableRow, TableData, TableSpacer } = Table;

export const HRStyled = styled.hr`
  border-top: 1px solid ${color.greyscale.grey1};
`;

export const TransactionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TableDataStyled = styled(TableData)`
  color: ${color.palette.green};
`;

export const HeadingStyled = styled(Heading)`
  padding: ${spacing.s} ${spacing.l};
`;

export const SectionStyled = styled.div`
  & > div {
    min-width: 100%;
    max-width: 100%;
    padding: ${spacing.s} ${spacing.l};
  }

  & h2 {
    font-weight: ${fontWeight.semiBold};
  }

  & th {
    padding-bottom: 0;
    color: ${color.greyscale.grey5};
  }
`;

const TableGroupButtonContainerStyled = styled.div`
  width: 100%;
  display: flex;
  padding: 0 ${spacing.m} ${spacing.m};
`;

export default function TransactionDetail() {
  const { id } = useParams();
  const { data, isLoading, error, refetch } = useTransaction(`${id}`);

  // Modals
  const [openConfirmPayment, setOpenConfirmPayment] = React.useState(false);
  const [openConfirmTransaction, setOpenConfirmTransaction] = React.useState(false);

  const transactionType = window.location.pathname.includes("buy") ? "BUY" : "SELL";

  const mintOrBurnType = transactionType === "SELL" ? "Burn" : "Mint";

  const showConfirmPayment =
    (transactionType === "BUY" || (transactionType === "SELL" && data?.Blockchain.Status === "BURNED")) &&
    data?.Fiat.Status !== "CONFIRMED";
  const showPaymentInstruction =
    transactionType === "SELL" &&
    (data?.Status === "AWAITING_PAYOUT" || data?.Status === "CONFIRMED") &&
    data?.Blockchain.Status === "BURNED";

  const showConfirmBlockchainTransaction =
    (transactionType === "BUY" && data?.Fiat.Status === "CONFIRMED" && data?.Blockchain.Status !== "MINTED") ||
    (transactionType === "SELL" && data?.Blockchain.Status !== "BURNED");

  const hideReloadBlockchainKYT =
    data?.Blockchain.KYTStatus !== "COMPLETED" ||
    !["MINTED", "BURNED"].includes(data?.Blockchain.Status ?? "") ||
    data?.Blockchain.KYTStatus !== "COMPLETED";

  const showReloadBlockchainKYT =
    ((transactionType === "BUY" && data?.Fiat.Status === "CONFIRMED") || transactionType === "SELL") &&
    hideReloadBlockchainKYT;

  const isBlincType = data !== undefined && !isLoading && data.Customer.BankAccount.Types?.includes("BLINC");

  return (
    <>
      {error ? (
        <CardError body={getErrorMessage(error)} title="Error fetching transaction" onRetry={refetch} />
      ) : isLoading ? (
        <CardLoader />
      ) : (
        data && (
          <DashboardContainer>
            <TransactionsWrapper>
              <HeadingStyled as="h3">
                {transactionType === "BUY" ? "Buy Order" : "Sell Order"} - {id}
              </HeadingStyled>
              <SectionStyled>
                <HRStyled />
                <TableGroup label="General">
                  <TableBody>
                    <TableSpacer height={spacing.s} />
                    <TableRow noBorder>
                      <TableHeader paddingSize="large">Status</TableHeader>
                      <TableHeader paddingSize="large">Transaction ID</TableHeader>
                      <TableHeader paddingSize="large">Customer Name</TableHeader>
                      <TableHeader paddingSize="large">Customer ID</TableHeader>
                      <TableHeader paddingSize="large">Type</TableHeader>
                      <TableHeader paddingSize="large">Creation Date</TableHeader>
                    </TableRow>
                    <TableRow noBorder>
                      <TableData paddingSize="large">
                        <Badge
                          noMargin
                          text={parseTransactionStatusText(data.Status)}
                          varient={getTransactionStatusVarient(data.Status)}
                        />
                      </TableData>
                      <TableData paddingSize="large">{data.TransactionId ?? "-"}</TableData>
                      <TableData paddingSize="large">{data.Customer.Name ?? "-"}</TableData>
                      <TableData paddingSize="large">{data.Customer.OrganizationId ?? "-"}</TableData>
                      <TableData paddingSize="large">{data.Type ? capitalize(data.Type.toLowerCase()) : "-"}</TableData>
                      <TableData paddingSize="large">{format(new Date(data.CreatedDateTime), "dd/MM/yyy")}</TableData>
                    </TableRow>
                    <TableSpacer height={spacing.l} />
                  </TableBody>
                </TableGroup>
              </SectionStyled>
              <SectionStyled style={{ order: transactionType === "BUY" ? 1 : 2 }}>
                <TableGroup label="Fiat">
                  <>
                    <TableComponent>
                      <TableBody>
                        <TableSpacer height={spacing.s} />
                        <TableRow noBorder>
                          <TableHeader paddingSize="small">Payment Status</TableHeader>
                          <TableHeader paddingSize="small">Transfer Date</TableHeader>
                          <TableHeader paddingSize="small">Currency</TableHeader>
                          <TableHeader paddingSize="small">Expected Amount</TableHeader>
                          <TableHeader paddingSize="small">Gross Transaction Amount</TableHeader>
                          <TableHeader paddingSize="small">Banking Fee</TableHeader>
                        </TableRow>
                        <TableRow noBorder>
                          <TableData paddingSize="small">
                            <Badge
                              noMargin
                              text={parseFiatStatusText(data.Fiat.Status)}
                              varient={getFiatStatusVarient(data.Fiat.Status)}
                            />
                          </TableData>
                          <TableData paddingSize="small">
                            {data.Fiat.TransferredDateTime
                              ? format(new Date(data.Fiat.TransferredDateTime), "dd/MM/yyy")
                              : "-"}
                          </TableData>
                          <TableData paddingSize="small">{data.Fiat.FiatValue.Currency}</TableData>
                          <TableData paddingSize="small">
                            {data.Fiat.FiatValue
                              ? formatCurrency(
                                  {
                                    Amount: data.Fiat.FiatValue.Amount,
                                    Denominator: Constants.fallbackDenominator,
                                  },
                                  2
                                )
                              : "-"}
                          </TableData>
                          <TableData paddingSize="small">
                            {data.Fiat.GrossAmount
                              ? formatCurrency(
                                  {
                                    Amount: data.Fiat.GrossAmount.Amount,
                                    Denominator: Constants.fallbackDenominator,
                                  },
                                  2
                                )
                              : "-"}
                          </TableData>
                          <TableData paddingSize="small">
                            {data.Fiat.BankingFee
                              ? formatCurrency(
                                  {
                                    Amount: data.Fiat.BankingFee.Amount,
                                    Denominator: Constants.fallbackDenominator,
                                  },
                                  2
                                )
                              : "-"}
                          </TableData>
                        </TableRow>
                        <TableSpacer height={spacing.l} />
                        <TableRow noBorder>
                          <TableHeader paddingSize="small">Net Transaction Amount</TableHeader>
                          <TableHeader paddingSize="small">Payment Reference</TableHeader>
                          <TableHeader paddingSize="small">Transaction Description</TableHeader>
                          <TableHeader paddingSize="small">
                            {isBlincType ? "Customer Blinc ID" : "Customer Bank Account"}
                          </TableHeader>
                          {data.Type === "BUY" ? (
                            <TableHeader paddingSize="small">Bank Account Whitelisted</TableHeader>
                          ) : (
                            <></>
                          )}
                        </TableRow>
                        <TableRow noBorder>
                          <TableData paddingSize="small">
                            {data.Fiat.NetAmount
                              ? formatCurrency(
                                  {
                                    Amount: data.Fiat.NetAmount.Amount,
                                    Denominator: Constants.fallbackDenominator,
                                  },
                                  2
                                )
                              : "-"}
                          </TableData>
                          <TableData paddingSize="small">{data.PaymentInstructionId ?? "-"}</TableData>
                          <TableData paddingSize="small">{data.Fiat.Description ?? "-"}</TableData>
                          <TableData paddingSize="small">
                            {isBlincType
                              ? data.Customer.BankAccount.BlincId ?? "-"
                              : data.Customer.BankAccount.IBAN ?? "-"}
                          </TableData>
                          {data.Type === "BUY" ? (
                            <TableDataStyled paddingSize="small">
                              {data.Customer.BankAccount.Whitelisted === undefined
                                ? "-"
                                : data.Customer.BankAccount.Whitelisted === true
                                ? "Yes"
                                : "No"}
                            </TableDataStyled>
                          ) : (
                            <></>
                          )}
                        </TableRow>
                        <TableSpacer height={spacing.l} />
                        <TableRow noBorder>
                          <TableHeader paddingSize="small">Comply Advantage Case ID</TableHeader>
                          <TableHeader paddingSize="small">Direction</TableHeader>
                          <TableHeader paddingSize="small">Comply Advantage Status</TableHeader>
                        </TableRow>
                        <TableRow noBorder>
                          <TableData paddingSize="small">{data.Fiat.KYTId ?? "-"}</TableData>
                          <TableData paddingSize="small">
                            {data.Fiat.Direction ? capitalize(data.Fiat.Direction.toLowerCase()) : "-"}
                          </TableData>
                          <TableData paddingSize="small">
                            {data.Fiat.KYTStatus ? (
                              <Badge
                                noMargin
                                text={parseFiatKYTStatusText(data.Fiat.KYTStatus)}
                                varient={getFiatKYTStatusVarient(data.Fiat.KYTStatus)}
                              />
                            ) : (
                              "-"
                            )}
                          </TableData>
                          <TableData paddingSize="small">
                            {data.Fiat.KYTStatus && (
                              <InlineLink
                                target="_blank"
                                external
                                to={
                                  Constants.environment === "production"
                                    ? `https://stablr.tm.complyadvantage.com/transactions/${data.Fiat.KYTId}`
                                    : `https://stablr-qa.tm.complyadvantage.com/transactions/${data.Fiat.KYTId}`
                                }
                              >
                                <Button
                                  varient="line"
                                  size="small"
                                  backgroundColor={color.other.transparent}
                                  borderColor={color.palette.purple}
                                  color={color.palette.purple}
                                  icon={<ExternalSVG />}
                                >
                                  Open Comply Advantage
                                </Button>
                              </InlineLink>
                            )}
                          </TableData>
                        </TableRow>

                        <TableSpacer height={spacing.l} />
                      </TableBody>
                    </TableComponent>
                    {(showConfirmPayment || showPaymentInstruction) && (
                      <TableGroupButtonContainerStyled>
                        {showConfirmPayment && (
                          <Button
                            size="small"
                            icon={<CheckedCircleSVG />}
                            varient="line"
                            iconColor={color.palette.purple}
                            onClick={() => setOpenConfirmPayment(true)}
                          >
                            {transactionType === "BUY" ? "Confirm Pay-in" : "Confirm Pay-out"}
                          </Button>
                        )}
                        {showConfirmPayment && showPaymentInstruction && <Spacer width={spacing.m} />}
                        {showPaymentInstruction && (
                          <DownloadPaymentInstruction
                            organizationId={data.Customer.OrganizationId}
                            id={data.PaymentInstructionId}
                          />
                        )}
                      </TableGroupButtonContainerStyled>
                    )}
                  </>
                </TableGroup>
              </SectionStyled>
              <SectionStyled style={{ order: transactionType === "BUY" ? 2 : 1 }}>
                <TableGroup label="Blockchain">
                  <>
                    <TableComponent>
                      <TableBody>
                        <TableSpacer height={spacing.s} />
                        <TableRow noBorder>
                          <TableHeader paddingSize="small">Status</TableHeader>
                          <TableHeader paddingSize="small">{mintOrBurnType} Order ID</TableHeader>
                          <TableHeader paddingSize="small">Network</TableHeader>
                          <TableHeader paddingSize="small">{mintOrBurnType} Transaction Hash</TableHeader>
                          <TableHeader paddingSize="small">{mintOrBurnType} Date</TableHeader>
                        </TableRow>
                        <TableRow noBorder>
                          <TableData paddingSize="small">
                            {data.Status ? (
                              <Badge
                                noMargin
                                text={parseBlockchainStatusText(data.Blockchain.Status)}
                                varient={getBlockchainStatusVarient(data.Blockchain.Status)}
                              />
                            ) : (
                              "-"
                            )}
                          </TableData>
                          {/** No Idea what this (Mint Order ID) is? */}
                          <TableData paddingSize="small">{data.Blockchain.Id}</TableData>
                          <TableData paddingSize="small">
                            <Icon>
                              <EthrereumSVG />
                            </Icon>
                            {data.Blockchain.Network ?? "Ethereum"}
                          </TableData>
                          <TableData paddingSize="small">
                            {data.Blockchain.TransactionHash ? (
                              <InlineLink
                                target="_blank"
                                external
                                to={`https://etherscan.io/tx/${data.Blockchain.TransactionHash}`}
                              >
                                Etherscan
                              </InlineLink>
                            ) : (
                              "-"
                            )}
                          </TableData>
                          <TableData paddingSize="small">
                            {/* {data.Blockchain.CreatedDateTime
                              ? format(new Date(data.Blockchain.CreatedDateTime), "dd/MM/yyy HH:mm")
                              : "-"} */}
                            -
                          </TableData>
                        </TableRow>
                        <TableSpacer height={spacing.l} />
                        <TableRow noBorder>
                          <TableHeader paddingSize="small">Currency</TableHeader>
                          <TableHeader paddingSize="small">Amount</TableHeader>
                          <TableHeader paddingSize="small">Gas Fee</TableHeader>
                          <TableHeader paddingSize="small">Gas Fee (In EUR)</TableHeader>
                          <TableHeader paddingSize="small">
                            {data.Type === "SELL" ? "Customer Origination Address" : "Customer Destination Address"}
                          </TableHeader>
                        </TableRow>
                        <TableRow noBorder>
                          <TableData paddingSize="small">
                            {" "}
                            {data.Blockchain.BlockchainValue ? data.Blockchain.BlockchainValue.Currency : "-"}
                          </TableData>
                          <TableData paddingSize="small">
                            {data.Blockchain.BlockchainValue?.Amount
                              ? formatCurrency(
                                  {
                                    Amount: data.Blockchain.BlockchainValue.Amount,
                                    Denominator: Constants.fallbackDenominator,
                                  },
                                  2
                                )
                              : "-"}
                          </TableData>
                          <TableData paddingSize="small" align="center">
                            {data.Blockchain.GasFeeETH?.Amount
                              ? formatCurrency(
                                  {
                                    Amount: data.Blockchain.GasFeeETH.Amount,
                                    Denominator: Constants.fallbackDenominator,
                                  },
                                  2
                                )
                              : "-"}
                          </TableData>
                          <TableData paddingSize="small">
                            {data.Blockchain.GasFeeEUR?.Amount
                              ? formatCurrency(
                                  {
                                    Amount: data.Blockchain.GasFeeEUR.Amount,
                                    Denominator: Constants.fallbackDenominator,
                                  },
                                  2
                                )
                              : "-"}
                          </TableData>
                          <TableData paddingSize="small">{data.Customer.Wallet.WalletAddress ?? "-"}</TableData>
                        </TableRow>
                        <TableSpacer height={spacing.l} />
                        <TableRow noBorder>
                          {data.Type === "SELL" ? (
                            <TableHeader paddingSize="small">Wallet Address Whitelisted</TableHeader>
                          ) : (
                            <></>
                          )}
                          <TableHeader paddingSize="small">Issuing Platform</TableHeader>
                          <TableHeader paddingSize="small">Chainalysis External ID</TableHeader>
                          <TableHeader paddingSize="small">Exposure Description</TableHeader>
                        </TableRow>
                        <TableRow noBorder>
                          {data.Type === "SELL" ? (
                            <TableDataStyled paddingSize="small" color={color.palette.green}>
                              {data.Customer.Wallet.Whitelisted !== undefined
                                ? data.Customer.Wallet.Whitelisted === true
                                  ? "Yes"
                                  : "No"
                                : "-"}
                            </TableDataStyled>
                          ) : (
                            <></>
                          )}
                          <TableData paddingSize="small">
                            {data.Blockchain.IssuingPlatform
                              ? capitalize(data.Blockchain.IssuingPlatform.toLowerCase())
                              : "-"}
                          </TableData>
                          <TableData paddingSize="small">{data.Blockchain.KYTId ?? "-"}</TableData>
                          <TableData paddingSize="small">{data.Blockchain.KYTDescription ?? "-"}</TableData>
                        </TableRow>
                        <TableSpacer height={spacing.l} />
                        <TableRow noBorder>
                          <TableHeader paddingSize="small">Alert Level</TableHeader>
                          <TableHeader paddingSize="small">Chainalysis Status</TableHeader>
                        </TableRow>
                        <TableRow noBorder>
                          <TableData paddingSize="small">
                            {data.Blockchain.KYTAlertLevel
                              ? capitalize(data.Blockchain.KYTAlertLevel.toLowerCase())
                              : "-"}
                          </TableData>
                          <TableData paddingSize="large">
                            {data.Blockchain.KYTStatus ? (
                              <Badge
                                noMargin
                                text={parseBlockchainKYTStatusText(data.Blockchain.KYTStatus)}
                                varient={getBlockchainKYTStatusVarient(data.Blockchain.KYTStatus)}
                              />
                            ) : (
                              "-"
                            )}
                          </TableData>
                        </TableRow>
                        <TableSpacer height={spacing.l} />
                      </TableBody>
                    </TableComponent>

                    <TableGroupButtonContainerStyled>
                      {showConfirmBlockchainTransaction && (
                        <>
                          <Button
                            size="small"
                            icon={<CheckedCircleSVG />}
                            varient="line"
                            iconColor={color.palette.purple}
                            onClick={() => setOpenConfirmTransaction(true)}
                          >
                            {`Confirm ${data.Type === "BUY" ? "Mint" : "Burn"} Transaction`}
                          </Button>
                          <Spacer width={spacing.m} />
                        </>
                      )}
                      <InlineLink
                        target="_blank"
                        external
                        to={`https://kyt.chainalysis.com/users/${data.Customer.OrganizationId}`}
                      >
                        <Button
                          varient="line"
                          size="small"
                          backgroundColor={color.other.transparent}
                          borderColor={color.palette.purple}
                          color={color.palette.purple}
                          icon={<ExternalSVG />}
                        >
                          Open Chainalysis
                        </Button>
                      </InlineLink>
                      <Spacer width={spacing.m} />
                      {showReloadBlockchainKYT && (
                        <ResubmitBlockchainKYTButtton
                          blockchainTransactionId={data.Blockchain.Id}
                          transactionId={data.TransactionId}
                          type={data.Type}
                        />
                      )}
                    </TableGroupButtonContainerStyled>
                  </>
                </TableGroup>
                <ConfirmPaymentModal
                  data={data}
                  id={data.TransactionId}
                  isOpen={openConfirmPayment}
                  onClose={() => setOpenConfirmPayment(false)}
                />
                <ConfirmBlockchainTransactionModal
                  data={data}
                  isOpen={openConfirmTransaction}
                  onClose={() => setOpenConfirmTransaction(false)}
                />
              </SectionStyled>
            </TransactionsWrapper>
          </DashboardContainer>
        )
      )}
    </>
  );
}
