import { useQuery } from "@tanstack/react-query";
import api from "api";

useCustodianBank.queryKey = "custodianBank";

export function useCustodianBank(organizationId: string) {
  return useQuery(
    [useCustodianBank.queryKey, organizationId],
    async () => api.Customer.getCustomerCustodianBank(organizationId),
    {
      notifyOnChangeProps: "all",
    }
  );
}
