import { FORM_ERROR } from "final-form";
import { useUpdateTransactionProfile } from "hooks/query/customers/customer/use-update-profile";
import React from "react";
import { Field, Form } from "react-final-form";
import { useParams } from "react-router-dom";
import { Button, Heading } from "stablr/components/atoms";
import { DashboardContainer, ErrorMessage, TextInput } from "stablr/components/molecules";
import { FormLayout } from "stablr/components/organisms/";
import { getErrorMessage, validateFormValues } from "stablr/functions";
import { color, fontSize, fontWeight, spacing } from "stablr/styles";
import styled from "styled-components";
import { APITransactionProfile } from "types/API/Customer";

import {
  TRANSACTION_PROFILE_SCHEMA,
  TransactionProfileSchemaValidation,
} from "./schema/transactionProfileSchemaValidation";

const FormNoteStyled = styled.div`
  color: ${color.greyscale.greyDark1};
  font-size: ${fontSize.small};
  font-weight: ${fontWeight.bold};
  margin: -${spacing.m} 0 ${spacing.xl} 0;
`;

const FormStyled = styled.form`
  width: 100%;
`;

type TransactionProfileFormProps = {
  profile?: APITransactionProfile;
};

type TransactionProfileFormValues = {
  Year: string;
  Month: string;
  Day: string;
};

export function TransactionProfileForm({ profile }: TransactionProfileFormProps) {
  const { id } = useParams();

  const { mutateAsync: mutateAsyncPutProfile, isLoading } = useUpdateTransactionProfile();

  const handleOnSubmit = async (data: TransactionProfileFormValues) => {
    const organizationId = id ?? "";
    const dataProfile = {
      Year: parseInt(data.Year, 10),
      Month: parseInt(data.Month, 10),
      Day: parseInt(data.Day, 10),
    };
    try {
      await mutateAsyncPutProfile({ organizationId, data: dataProfile });
    } catch (error) {
      return { [FORM_ERROR]: getErrorMessage(error) };
    }
  };

  return (
    <DashboardContainer>
      <Heading as="h5">Expected Transaction Profile</Heading>
      <Form
        onSubmit={handleOnSubmit}
        initialValues={profile}
        validate={validateFormValues(TransactionProfileSchemaValidation)}
      >
        {({ submitError, handleSubmit }) => {
          return (
            <FormStyled onSubmit={handleSubmit}>
              <FormLayout columns={3} mobileColumns={1}>
                <Field
                  placeholder="Year"
                  label="Yearly Limit*"
                  name={TRANSACTION_PROFILE_SCHEMA.YEAR}
                  type="number"
                  compact
                  component={TextInput}
                />
                <Field
                  placeholder="Month"
                  label="Monthly Limit"
                  name={TRANSACTION_PROFILE_SCHEMA.MONTH}
                  type="number"
                  compact
                  component={TextInput}
                />
                <Field
                  placeholder="Day"
                  label="Daily Limit"
                  name={TRANSACTION_PROFILE_SCHEMA.DAY}
                  type="number"
                  compact
                  component={TextInput}
                />
              </FormLayout>
              <FormNoteStyled>*Mandatory</FormNoteStyled>
              <Button
                type="submit"
                onClick={() => handleSubmit}
                backgroundColor={color.greyscale.black}
                loading={isLoading}
              >
                Save
              </Button>
              {submitError && <ErrorMessage>{submitError}</ErrorMessage>}
            </FormStyled>
          );
        }}
      </Form>
    </DashboardContainer>
  );
}
