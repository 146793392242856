import { useWeb3React } from "@web3-react/core";
import Constants from "constants/index";
import { FORM_ERROR } from "final-form";
import * as React from "react";
import { Field, Form } from "react-final-form";
import { Web3Service } from "services/web3";
import {
  Button,
  color,
  ErrorMessage,
  fontSize,
  fontWeight,
  Spacer,
  spacing,
  TextInput,
  transformWalletAddress,
  useToast,
} from "stablr";
import styled from "styled-components";

interface ABIFunctionFormProps {
  abiFunction: {
    name: string;
    type: "function";
    inputs: { internalType: "address" | string; name: string; type: "address" | "uint254" }[];
  };
  onSubmit?: (values: any) => void;
  contractAddress: string;
  contractAbi: any;
}

const ABIFunctionFormStyled = styled.div``;

const SuccessLinkStyled = styled.a`
  color: ${color.themeNew.primary};
  font-size: ${fontSize.p};
  text-decoration: none;
  font-weight: ${fontWeight.medium};
`;

export function ABIFunctionForm({ abiFunction, onSubmit, contractAddress, contractAbi }: ABIFunctionFormProps) {
  const { account } = useWeb3React();
  const { errorToast, successToast } = useToast();
  const [successfulTransaction, setSuccessfulTransaction] = React.useState<undefined | string>(undefined);

  const handleOnSubmit = async (values: any) => {
    if (window.ethereum && account) {
      try {
        const contract = new Web3Service.eth.Contract(contractAbi, contractAddress);
        const inputValues = abiFunction.inputs.map(inputData => values[inputData.name]);

        const data = contract.methods[abiFunction.name](...inputValues).encodeABI();
        const tx = {
          from: account,
          to: contractAddress,
          value: 0,
          data,
        };

        const txHash = await window.ethereum.request({
          method: "eth_sendTransaction",
          params: [tx],
        });

        successToast({ title: `${abiFunction.name} Successful`, message: `Transaction: ${txHash}` });

        setSuccessfulTransaction(txHash);
        onSubmit && onSubmit(values);
      } catch (error: any) {
        if (error.code === "INVALID_ARGUMENT") {
          const fieldType = abiFunction.inputs.find(input => input.name === error.argument)?.type;
          return { [FORM_ERROR]: `${error.argument} field is not a valid '${fieldType}' type` };
        } else {
          errorToast({ title: "Error", message: error.message });
          return { [FORM_ERROR]: error.message };
        }
      }
    } else {
      return { [FORM_ERROR]: "Ethereum wallet not connected or not active" };
    }
  };

  return (
    <ABIFunctionFormStyled>
      <Form onSubmit={handleOnSubmit}>
        {({ handleSubmit, submitError, submitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              {abiFunction.inputs.map(input => (
                <Field
                  key={input.name}
                  name={input.name}
                  label={`${input.name} (${input.type})`}
                  placeholder={input.type === "address" ? "0x..." : input.type.startsWith("uint") ? "0" : input.name}
                  component={TextInput}
                  compact
                ></Field>
              ))}
              {successfulTransaction && (
                <>
                  <Spacer height={spacing.m} />
                  Transaction {`"${transformWalletAddress(successfulTransaction)}"`} created{" "}
                  <SuccessLinkStyled
                    target="__blank"
                    href={`https://${
                      Constants.web3.chainId === "1" ? "" : "sepolia."
                    }etherscan.io/tx/${successfulTransaction}`}
                  >
                    - View on Etherscan
                  </SuccessLinkStyled>
                </>
              )}
              {submitError && (
                <>
                  <Spacer height={spacing.m} />
                  <ErrorMessage>{submitError}</ErrorMessage>
                </>
              )}
              <Spacer height={spacing.m} />
              <Button loading={submitting} type="submit">
                Execute
              </Button>
              <Spacer height={spacing.m} />
            </form>
          );
        }}
      </Form>
    </ABIFunctionFormStyled>
  );
}
