import { BadgeVarients } from "stablr/types/badge-variant";
import { APIBankAccountStatusType } from "types/API/BankAccount";

export function getBankAccountBadgeVariant(status: APIBankAccountStatusType): BadgeVarients {
  switch (`${status}`) {
    case "PENDING":
      return "info";
    case "APPROVED":
      return "success";
    case "REJECTED":
      return "error";
    default:
      return "default";
  }
}
