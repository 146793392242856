import * as React from "react";
import { DashboardContainer } from "stablr/components/molecules";

import RecentActivityCard from "./components/RecentActivityCard";
import BalanceCard from "./components/TotalSupplyCard";

export default function DashboardComponent() {
  return (
    <DashboardContainer>
      <>
        <BalanceCard />
        <RecentActivityCard />
      </>
    </DashboardContainer>
  );
}
