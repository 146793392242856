import * as React from "react";

import { ContractAddressCategory } from "../../components/ContractAddressCategory";
import { DropDownListItem } from "../../components/DropDownListItem";
import { ABIFunctionForm } from "./components/ABIFunctionForm";

type FunctionOptionType = {
  label: string;
  items: string[];
};

interface EthereumContractListItemProps {
  proxyAddress: string;
  implementationAddress: string;
  symbol: string;
  proxyFunctionOptions: FunctionOptionType[];
  proxyAbi: any;
  implementationFunctionOptions: FunctionOptionType[];
  implementationAbi: any;
}

function getABIFunctionMap(ABI: any, arrayOfFunctionNames: string[]) {
  const filteredABI = ABI.filter(
    (item: { name: string; type: string }) => item.type === "function" && arrayOfFunctionNames.includes(item.name)
  );

  return filteredABI;
}

function formatFunctionName(functionName: string) {
  // Add space before each uppercase letter
  let formattedStr = functionName.replace(/([A-Z])/g, " $1");

  // Capitalize the first letter
  formattedStr = formattedStr.charAt(0).toUpperCase() + formattedStr.slice(1).toLowerCase();

  // Return the formatted string
  return formattedStr;
}

export function EthereumContractListItem({
  proxyAddress,
  implementationAddress,
  symbol,
  proxyFunctionOptions,
  proxyAbi,
  implementationFunctionOptions,
  implementationAbi,
}: EthereumContractListItemProps) {
  const { proxyFunctionABIOptions, implementationFunctionABIOptions } = React.useMemo(() => {
    return {
      proxyFunctionABIOptions: proxyFunctionOptions.map(functionList => ({
        ...functionList,
        items: getABIFunctionMap(proxyAbi, functionList.items),
      })),
      implementationFunctionABIOptions: implementationFunctionOptions.map(functionList => ({
        ...functionList,
        items: getABIFunctionMap(implementationAbi, functionList.items),
      })),
    };
  }, []);

  return (
    <DropDownListItem size="large" title={symbol}>
      <ContractAddressCategory title={"Proxy contract address"} address={proxyAddress ?? "-"}>
        {proxyFunctionABIOptions.map(proxyFunctionABIItem => (
          <DropDownListItem key={proxyFunctionABIItem.label} size="medium" title={proxyFunctionABIItem.label}>
            {proxyFunctionABIItem.items.map((abiFunction: any) => (
              <DropDownListItem key={abiFunction.name} size="small" title={formatFunctionName(abiFunction.name)}>
                <ABIFunctionForm
                  abiFunction={abiFunction as any}
                  contractAddress={proxyAddress}
                  contractAbi={proxyAbi}
                />
              </DropDownListItem>
            ))}
          </DropDownListItem>
        ))}
      </ContractAddressCategory>

      <ContractAddressCategory title={"Implimentation contract address"} address={implementationAddress ?? "-"}>
        {implementationFunctionABIOptions.map(implementationFunctionABIItem => (
          <DropDownListItem
            key={implementationFunctionABIItem.label}
            size="medium"
            title={implementationFunctionABIItem.label}
          >
            {implementationFunctionABIItem.items.map((abiFunction: any) => (
              <DropDownListItem key={abiFunction.name} size="small" title={formatFunctionName(abiFunction.name)}>
                <ABIFunctionForm
                  abiFunction={abiFunction as any}
                  contractAddress={implementationAddress}
                  contractAbi={implementationAbi}
                />
              </DropDownListItem>
            ))}
          </DropDownListItem>
        ))}
      </ContractAddressCategory>
    </DropDownListItem>
  );
}
