import { BadgeVarients } from "stablr";
import { APIBlockchainTransactionStatus } from "types/API/Transaction";

export function getBlockchainStatusVarient(status: APIBlockchainTransactionStatus): BadgeVarients {
  switch (`${status}`) {
    case "PENDING":
      return "info";
    case "FAILED":
      return "error";
    case "CONFIRMED":
      return "success";
    case "BURNED":
      return "success";
    case "MINTED":
      return "success";
    case "REJECTED":
      return "error";
    case "TRANSFERRED":
      return "action";
    default:
      return "default"; // Use Default Varient
  }
}
