import Constants from "constants/index";
import * as React from "react";
import { formatCurrency, spacing, Table, TableInlineAccordionGroup } from "stablr";
import styled from "styled-components";
import { APIFinanceOverview } from "types/API/Finance";

interface FinanceTableProps {
  data: APIFinanceOverview;
}

const { TableBody, TableData, TableHead, TableHeader, Table: TableComponent, TableRow, TableSpacer } = Table;

const LEFT_COLUMN_WIDTH = "250px";

const TableWrapperStyled = styled.div`
  width: 100%;
  overflow-x: scroll;
`;

function formatFinanceTableCurrency(value?: number, decimals?: number) {
  return value === 0 || value === undefined
    ? "-"
    : formatCurrency({ Amount: value, Denominator: Constants.fallbackDenominator }, decimals);
}

export default function FinanceTableComponent({ data }: FinanceTableProps) {
  return (
    <TableWrapperStyled>
      <TableComponent>
        <TableHead>
          <TableRow>
            <TableHeader paddingSize="xsmall" width={LEFT_COLUMN_WIDTH}></TableHeader>
            <TableHeader paddingSize="xsmall">Opening Balance</TableHeader>
            <TableHeader paddingSize="xsmall">Deposit</TableHeader>
            <TableHeader paddingSize="xsmall">Withdrawal</TableHeader>
            <TableHeader paddingSize="xsmall">Cash In Transfer</TableHeader>
            <TableHeader paddingSize="xsmall">Fees</TableHeader>
            <TableHeader paddingSize="xsmall">Closing Balance</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableSpacer height={spacing.s} />
          <TableInlineAccordionGroup colSpan={7} title="Stable Coins" isDefaultOpen>
            {data.StableCoins?.map((item, index) => (
              <TableRow key={`${item.Asset}-${index}`} noBorder={data.StableCoins?.length === index + 1}>
                <TableHeader paddingSize="xsmall" width={LEFT_COLUMN_WIDTH}>
                  {item.Asset}
                </TableHeader>
                <TableData paddingSize="xsmall" varient={item.OpeningBalance < 0 ? "red" : undefined}>
                  {formatFinanceTableCurrency(item.OpeningBalance, 2)}
                </TableData>
                <TableData paddingSize="xsmall" varient={item.DepositBalance < 0 ? "red" : undefined}>
                  {formatFinanceTableCurrency(item.DepositBalance, 2)}
                </TableData>
                <TableData paddingSize="xsmall" varient={item.WithdrawalBalance < 0 ? "red" : undefined}>
                  {formatFinanceTableCurrency(item.WithdrawalBalance, 2)}
                </TableData>
                <TableData paddingSize="xsmall" varient={item.CashInTransferBalance < 0 ? "red" : undefined}>
                  {formatFinanceTableCurrency(item.CashInTransferBalance, 2)}
                </TableData>
                <TableData paddingSize="xsmall" varient={item.FeeBalance < 0 ? "red" : undefined}>
                  {formatFinanceTableCurrency(item.FeeBalance, 2)}
                </TableData>
                <TableData
                  paddingSize="xsmall"
                  bold
                  varient={item?.ClosingBalance && item.ClosingBalance < 0 ? "red" : undefined}
                >
                  {formatFinanceTableCurrency(item.ClosingBalance, 2)}
                </TableData>
                <TableData paddingSize="xsmall"></TableData>
              </TableRow>
            ))}
          </TableInlineAccordionGroup>
          <TableInlineAccordionGroup colSpan={7} title="Crypto Currency" isDefaultOpen>
            {data.CryptoCurrency?.map((item, index) => (
              <TableRow key={`cryptocurrency-${index}`} noBorder={data.CryptoCurrency?.length === index + 1}>
                <TableHeader paddingSize="xsmall" width={LEFT_COLUMN_WIDTH}>
                  {item.Asset}
                </TableHeader>
                <TableData paddingSize="xsmall" varient={item.OpeningBalance < 0 ? "red" : undefined}>
                  {formatFinanceTableCurrency(item.OpeningBalance, 2)}
                </TableData>
                <TableData paddingSize="xsmall" varient={item.DepositBalance < 0 ? "red" : undefined}>
                  {formatFinanceTableCurrency(item.DepositBalance, 2)}
                </TableData>
                <TableData paddingSize="xsmall" varient={item.WithdrawalBalance < 0 ? "red" : undefined}>
                  {formatFinanceTableCurrency(item.WithdrawalBalance, 2)}
                </TableData>
                <TableData paddingSize="xsmall" varient={item.CashInTransferBalance < 0 ? "red" : undefined}>
                  {formatFinanceTableCurrency(item.CashInTransferBalance, 2)}
                </TableData>
                <TableData paddingSize="xsmall" varient={item.FeeBalance < 0 ? "red" : undefined}>
                  {formatFinanceTableCurrency(item.FeeBalance, 2)}
                </TableData>
                <TableData
                  paddingSize="xsmall"
                  bold
                  varient={item.ClosingBalance !== undefined && item.ClosingBalance < 0 ? "red" : undefined}
                >
                  {formatFinanceTableCurrency(item.ClosingBalance, 2)}
                </TableData>
                <TableData paddingSize="xsmall"></TableData>
              </TableRow>
            ))}
          </TableInlineAccordionGroup>
          <TableInlineAccordionGroup colSpan={7} title="Fiat Banks EUR" isDefaultOpen>
            {data.FiatBanks?.map((item, index) => (
              <TableRow key={`fiat - ${index}`} noBorder={data.FiatBanks?.length === index + 1}>
                <TableHeader paddingSize="xsmall" width={LEFT_COLUMN_WIDTH}>
                  {item.Bank}
                </TableHeader>
                <TableData paddingSize="xsmall" varient={item.OpeningBalance < 0 ? "red" : undefined}>
                  {formatFinanceTableCurrency(item.OpeningBalance)}
                </TableData>
                <TableData paddingSize="xsmall" varient={item.DepositBalance < 0 ? "red" : undefined}>
                  {formatFinanceTableCurrency(item.DepositBalance, 2)}
                </TableData>
                <TableData paddingSize="xsmall" varient={item.WithdrawalBalance < 0 ? "red" : undefined}>
                  {formatFinanceTableCurrency(item.WithdrawalBalance, 2)}
                </TableData>
                <TableData paddingSize="xsmall" varient={item.CashInTransferBalance < 0 ? "red" : undefined}>
                  {formatFinanceTableCurrency(item.CashInTransferBalance, 2)}
                </TableData>
                <TableData paddingSize="xsmall" varient={item.FeeBalance < 0 ? "red" : undefined}>
                  {formatFinanceTableCurrency(item.FeeBalance, 2)}
                </TableData>
                <TableData
                  paddingSize="xsmall"
                  bold
                  varient={item.ClosingBalance && item.ClosingBalance < 0 ? "red" : undefined}
                >
                  {formatFinanceTableCurrency(item.ClosingBalance, 2)}
                </TableData>
                <TableData paddingSize="xsmall"></TableData>
              </TableRow>
            ))}
          </TableInlineAccordionGroup>
          <TableInlineAccordionGroup colSpan={7} title="Asset Managers EUR" isDefaultOpen>
            {data.AssetManagers?.map((item, index) => (
              <TableRow key={`asset-manager-${index}`} noBorder={data.AssetManagers?.length === index + 1}>
                <TableHeader paddingSize="xsmall" width={LEFT_COLUMN_WIDTH}></TableHeader>
                <TableData paddingSize="xsmall" varient={item.OpeningBalance < 0 ? "red" : undefined}>
                  {formatFinanceTableCurrency(item.OpeningBalance,2)}
                </TableData>
                <TableData paddingSize="xsmall" varient={item.DepositBalance < 0 ? "red" : undefined}>
                  {formatFinanceTableCurrency(item.DepositBalance,2)}
                </TableData>
                <TableData paddingSize="xsmall" varient={item.WithdrawalBalance < 0 ? "red" : undefined}>
                  {formatFinanceTableCurrency(item.WithdrawalBalance,2)}
                </TableData>
                <TableData paddingSize="xsmall" varient={item.CashInTransferBalance < 0 ? "red" : undefined}>
                  {formatFinanceTableCurrency(item.CashInTransferBalance,2)}
                </TableData>
                <TableData paddingSize="xsmall" varient={item.FeeBalance < 0 ? "red" : undefined}>
                  {formatFinanceTableCurrency(item.FeeBalance,2)}
                </TableData>
                <TableData
                  paddingSize="xsmall"
                  bold
                  varient={item.ClosingBalance && item.ClosingBalance < 0 ? "red" : undefined}
                >
                  {formatFinanceTableCurrency(item.ClosingBalance,2)}
                </TableData>
                <TableData paddingSize="xsmall"></TableData>
              </TableRow>
            ))}
          </TableInlineAccordionGroup>
        </TableBody>
        <TableSpacer height={spacing.m} />
        <TableBody varient="shaded">
          <TableRow>
            <TableHeader paddingSize="xsmall" varient="bold">
              Total Fiat EUR
            </TableHeader>
            <TableData
              paddingSize="xsmall"
              varient={data.TotalFiatEUR?.OpeningBalance && data.TotalFiatEUR?.OpeningBalance < 0 ? "red" : undefined}
            >
              {formatFinanceTableCurrency(data.TotalFiatEUR?.OpeningBalance,2)}
            </TableData>
            <TableData
              paddingSize="xsmall"
              varient={data.TotalFiatEUR?.DepositBalance && data.TotalFiatEUR?.DepositBalance < 0 ? "red" : undefined}
            >
              {formatFinanceTableCurrency(data.TotalFiatEUR?.DepositBalance,2)}
            </TableData>
            <TableData
              paddingSize="xsmall"
              varient={
                data.TotalFiatEUR?.WithdrawalBalance && data.TotalFiatEUR?.WithdrawalBalance < 0 ? "red" : undefined
              }
            >
              {formatFinanceTableCurrency(data.TotalFiatEUR?.WithdrawalBalance,2)}
            </TableData>
            <TableData
              paddingSize="xsmall"
              varient={
                data.TotalFiatEUR?.CashInTransferBalance && data.TotalFiatEUR?.CashInTransferBalance < 0
                  ? "red"
                  : undefined
              }
            >
              {formatFinanceTableCurrency(data.TotalFiatEUR?.CashInTransferBalance,2)}
            </TableData>
            <TableData
              paddingSize="xsmall"
              varient={data.TotalFiatEUR?.FeeBalance && data.TotalFiatEUR?.FeeBalance < 0 ? "red" : undefined}
            >
              {formatFinanceTableCurrency(data.TotalFiatEUR?.FeeBalance,2)}
            </TableData>
            <TableData
              paddingSize="xsmall"
              bold
              varient={data.TotalFiatEUR?.ClosingBalance && data.TotalFiatEUR?.ClosingBalance < 0 ? "red" : undefined}
            >
              {formatFinanceTableCurrency(data.TotalFiatEUR?.ClosingBalance,2)}
            </TableData>
            <TableData paddingSize="xsmall"></TableData>
          </TableRow>
          <TableRow>
            <TableHeader paddingSize="xsmall" varient="bold">
              Diff. Fiat EUR/EURR
            </TableHeader>
            <TableData
              paddingSize="xsmall"
              varient={
                data.DiffFiatEURToEURR?.OpeningBalance && data.DiffFiatEURToEURR?.OpeningBalance < 0 ? "red" : undefined
              }
            >
              {formatFinanceTableCurrency(data.DiffFiatEURToEURR?.OpeningBalance,2)}
            </TableData>
            <TableData
              paddingSize="xsmall"
              varient={
                data.DiffFiatEURToEURR?.DepositBalance && data.DiffFiatEURToEURR?.DepositBalance < 0 ? "red" : undefined
              }
            >
              {formatFinanceTableCurrency(data.DiffFiatEURToEURR?.DepositBalance,2)}
            </TableData>
            <TableData
              paddingSize="xsmall"
              varient={
                data.DiffFiatEURToEURR?.WithdrawalBalance && data.DiffFiatEURToEURR?.WithdrawalBalance < 0
                  ? "red"
                  : undefined
              }
            >
              {formatFinanceTableCurrency(data.DiffFiatEURToEURR?.WithdrawalBalance,2)}
            </TableData>
            <TableData
              paddingSize="xsmall"
              varient={
                data.DiffFiatEURToEURR?.CashInTransferBalance && data.DiffFiatEURToEURR?.CashInTransferBalance < 0
                  ? "red"
                  : undefined
              }
            >
              {formatFinanceTableCurrency(data.DiffFiatEURToEURR?.CashInTransferBalance)}
            </TableData>
            <TableData
              paddingSize="xsmall"
              varient={
                data.DiffFiatEURToEURR?.FeeBalance && data.DiffFiatEURToEURR?.FeeBalance < 0 ? "red" : undefined
              }
            >
              {formatFinanceTableCurrency(data.DiffFiatEURToEURR?.FeeBalance,2)}
            </TableData>
            <TableData
              paddingSize="xsmall"
              bold
              varient={
                data.DiffFiatEURToEURR?.ClosingBalance && data.DiffFiatEURToEURR?.ClosingBalance < 0 ? "red" : undefined
              }
            >
              {formatFinanceTableCurrency(data.DiffFiatEURToEURR?.ClosingBalance,2)}
            </TableData>
            <TableData paddingSize="xsmall"></TableData>
          </TableRow>
        </TableBody>
        <TableSpacer height={spacing.m} />
        <TableBody varient="shaded">
          <>
            {" "}
            <TableRow>
              <TableHeader paddingSize="xsmall" varient="bold" colSpan={8}>
                Fiat Banks EUR - Operational
              </TableHeader>
            </TableRow>
            {data.BanksEUROperational?.map(item => (
              <TableRow key={item.Name}>
                <TableHeader paddingSize="xsmall" width={LEFT_COLUMN_WIDTH}>
                  {item.Name}
                </TableHeader>
                <TableData paddingSize="xsmall" varient={item.OpeningBalance < 0 ? "red" : undefined}>
                  {formatFinanceTableCurrency(item.OpeningBalance, 2)}
                </TableData>
                <TableData paddingSize="xsmall" varient={item.DepositBalance < 0 ? "red" : undefined}>
                  {formatFinanceTableCurrency(item.DepositBalance, 2)}
                </TableData>
                <TableData paddingSize="xsmall" varient={item.WithdrawalBalance < 0 ? "red" : undefined}>
                  {formatFinanceTableCurrency(item.WithdrawalBalance, 2)}
                </TableData>
                <TableData paddingSize="xsmall" varient={item.CashInTransferBalance < 0 ? "red" : undefined}>
                  {formatFinanceTableCurrency(item.CashInTransferBalance, 2)}
                </TableData>
                <TableData paddingSize="xsmall" varient={item.FeeBalance < 0 ? "red" : undefined}>
                  {formatFinanceTableCurrency(item.FeeBalance, 2)}
                </TableData>
                <TableData
                  paddingSize="xsmall"
                  bold
                  varient={item.ClosingBalance && item.ClosingBalance < 0 ? "red" : undefined}
                >
                  {formatFinanceTableCurrency(item.ClosingBalance, 2)}
                </TableData>
                <TableData paddingSize="xsmall"></TableData>
              </TableRow>
            ))}
            <TableRow>
              <TableHeader paddingSize="xsmall" varient="bold">
                Total Fiat EUR - Operational
              </TableHeader>
              <TableData
                paddingSize="xsmall"
                varient={
                  data.DiffFiatEURToEURR?.OpeningBalance && data.DiffFiatEURToEURR?.OpeningBalance < 0
                    ? "red"
                    : undefined
                }
              >
                {formatFinanceTableCurrency(data.DiffFiatEURToEURR?.OpeningBalance, 2)}
              </TableData>
              <TableData
                paddingSize="xsmall"
                varient={
                  data.DiffFiatEURToEURR?.DepositBalance && data.DiffFiatEURToEURR?.DepositBalance < 0
                    ? "red"
                    : undefined
                }
              >
                {formatFinanceTableCurrency(data.DiffFiatEURToEURR?.DepositBalance, 2)}
              </TableData>
              <TableData
                paddingSize="xsmall"
                varient={
                  data.DiffFiatEURToEURR?.WithdrawalBalance && data.DiffFiatEURToEURR?.WithdrawalBalance < 0
                    ? "red"
                    : undefined
                }
              >
                {formatFinanceTableCurrency(data.DiffFiatEURToEURR?.WithdrawalBalance, 2)}
              </TableData>
              <TableData
                paddingSize="xsmall"
                varient={
                  data.DiffFiatEURToEURR?.CashInTransferBalance && data.DiffFiatEURToEURR?.CashInTransferBalance < 0
                    ? "red"
                    : undefined
                }
              >
                {formatFinanceTableCurrency(data.DiffFiatEURToEURR?.CashInTransferBalance)}
              </TableData>
              <TableData
                paddingSize="xsmall"
                varient={
                  data.DiffFiatEURToEURR?.FeeBalance && data.DiffFiatEURToEURR?.FeeBalance < 0 ? "red" : undefined
                }
              >
                {formatFinanceTableCurrency(data.DiffFiatEURToEURR?.FeeBalance, 2)}
              </TableData>
              <TableData
                paddingSize="xsmall"
                bold
                varient={
                  data.DiffFiatEURToEURR?.ClosingBalance && data.DiffFiatEURToEURR?.ClosingBalance < 0
                    ? "red"
                    : undefined
                }
              >
                {formatFinanceTableCurrency(data.DiffFiatEURToEURR?.ClosingBalance, 2)}
              </TableData>
              <TableData paddingSize="xsmall"></TableData>
            </TableRow>
          </>
        </TableBody>
      </TableComponent>
    </TableWrapperStyled>
  );
}
