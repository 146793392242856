import * as CustodianBanks from "./CustodianBanks";
import * as Customer from "./Customer";
import * as Dashboard from "./Dashboard";
import * as Document from "./Document";
import * as Files from "./Files";
import * as Finance from "./Finance";
import * as Governance from "./Governance";
import * as Mock from "./Mock";
import * as Payment from "./Payment";
import * as Transaction from "./Transaction";
import * as Web3 from "./web3";

type APIInterface = {
  Mock: typeof Mock;
  Dashboard: typeof Dashboard;
  Transaction: typeof Transaction;
  Customer: typeof Customer;
  Files: typeof Files;
  Finance: typeof Finance;
  Document: typeof Document;
  Payment: typeof Payment;
  Web3: typeof Web3;
  CustodianBanks: typeof CustodianBanks;
  Governance: typeof Governance;
};

const API: APIInterface = {
  Mock,
  Dashboard,
  Transaction,
  Customer,
  Files,
  Finance,
  Document,
  Web3,
  Payment,
  CustodianBanks,
  Governance,
};

export default API;
