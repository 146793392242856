import { useDocuments } from "hooks/query/customers/document/use-documents";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as PieChartIconSVG } from "stablr/assets/icons/pie-chart.svg";
import { ActionHeader, CardEmpty, CardError, CardLoader, DashboardContainer } from "stablr/components/molecules";
import { getErrorMessage } from "stablr/functions";

import { DocumentTable } from "./DocumentTable";

function Documents() {
  const { id } = useParams();
  const { data, isLoading, error, refetch } = useDocuments(`${id}`);

  const navigate = useNavigate();
  return (
    <DashboardContainer>
      <ActionHeader
        title="Documents Overview"
        btnText="Upload Document"
        testid="Add-Key"
        onBtnClick={() => navigate(`/customers/${id}/documents/add`)}
      />
      {error ? (
        <CardError body={getErrorMessage(error)} title="Error fetching document(s)" onRetry={refetch} />
      ) : isLoading ? (
        <CardLoader />
      ) : !data || data.length === 0 ? (
        <CardEmpty
          iconElement={<PieChartIconSVG />}
          title="No document found"
          body="There is no document at the moment"
        />
      ) : (
        <>
          <DocumentTable data={data} organizationId={id as string} />
        </>
      )}
    </DashboardContainer>
  );
}

export default Documents;
