import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "api";
import { useToast } from "stablr/hooks";
import { APIUploadDocument } from "types/API/Document";

import { useDocuments } from "./use-documents";

export function useUploadDocument() {
  const queryClient = useQueryClient();
  const { successToast } = useToast();

  return useMutation({
    mutationFn: async ({ organizationId, document }: { organizationId: string; document: APIUploadDocument }) => {
      return api.Document.uploadDocument(organizationId, document);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [useDocuments.queryKey] });
      successToast({ message: "Document has been uploaded successfully" });
    },
  });
}
