import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "api";
import Constants from "constants/index";
import { asyncSleep } from "refactor/functions/async-sleep";
import { getErrorMessage } from "stablr/functions";
import { useToast } from "stablr/hooks";

import { useTransaction } from "./use-transaction";
import { useTransactions } from "./use-transactions";

export function useResubmitBlockchainKYT() {
  const queryClient = useQueryClient();
  const { errorToast, successToast } = useToast();

  return useMutation({
    mutationFn: async ({
      type,
      blockchainTransactionId,
    }: {
      transactionId: string;
      blockchainTransactionId: string;
      type: "BUY" | "SELL";
    }) => api.Transaction.resubmitBlockchainKYT(blockchainTransactionId, type),
    onSuccess: async (_, { type, transactionId }) => {
      await asyncSleep(Constants.cache.defaultSocketDelay);

      await queryClient?.invalidateQueries({ queryKey: [useTransactions.queryKey, type] });
      await queryClient?.invalidateQueries({ queryKey: [useTransaction.queryKey, transactionId] });

      successToast({ message: "Chainalysis KYT Updated" });
    },
    onError: error => {
      errorToast({ message: getErrorMessage(error) });
    },
  });
}
