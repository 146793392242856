import { FORM_ERROR } from "final-form";
import { useUploadDocument } from "hooks/query/customers/document/use-upload-document";
import React from "react";
import { Form } from "react-final-form";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Heading, Spacer } from "stablr/components/atoms";
import { ButtonContainer, ErrorMessage } from "stablr/components/molecules";
import { getErrorMessage, validateFormValues } from "stablr/functions";
import { getFileBase64 } from "stablr/functions/get-file-base-64";
import { color, spacing } from "stablr/styles";
import { APIDocument } from "types/API/Document";

import DocumentForm from "../common/DocumentForm";
import { DocumenSchemaValidation } from "../common/schemaValidation";

export default function AddDocument() {
  const navigation = useNavigate();
  const { mutateAsync: mutateUploadDocument, isLoading } = useUploadDocument();

  const { id } = useParams();

  const handleOnSubmit = async (data: APIDocument) => {
    try {
      const file = data.File as File;
      const base64 = `${await getFileBase64(file)}`;
      const fileName = file.name;
      const document = {
        Title: data.Title,
        FileName: fileName,
        Data: base64,
      };
      const organizationId = id ?? "";
      await mutateUploadDocument({ organizationId, document });
      navigation(-1);
    } catch (error) {
      return { [FORM_ERROR]: getErrorMessage(error) };
    }
  };

  return (
    <>
      <Heading as="h3">Upload Document</Heading>
      <Form onSubmit={handleOnSubmit} validate={validateFormValues(DocumenSchemaValidation)} keepDirtyOnReinitialize>
        {({ handleSubmit, submitError, submitting }) => {
          const isDisabled = submitting || isLoading;
          return (
            <form onSubmit={handleSubmit}>
              <DocumentForm disabled={isDisabled} />
              {submitError && <ErrorMessage>{submitError}</ErrorMessage>}

              <ButtonContainer justifyContent="flex-start">
                <Button type="submit" varient="primary" color={color.greyscale.white} loading={isDisabled}>
                  Upload
                </Button>
                <Spacer width={spacing.m} />
              </ButtonContainer>
            </form>
          );
        }}
      </Form>
    </>
  );
}
