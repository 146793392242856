import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "api";
import { getErrorMessage } from "stablr/functions";
import { useToast } from "stablr/hooks";

import { useDocuments } from "./use-documents";

export function useDeleteDocument() {
  const queryClient = useQueryClient();
  const { successToast, errorToast } = useToast();

  return useMutation(
    ({ fileId, organizationId }: { fileId: string; organizationId: string }) => {
      return api.Document.deleteDocument(organizationId, fileId);
    },
    {
      onSuccess: async _ => {
        await queryClient.invalidateQueries({ queryKey: [useDocuments.queryKey] });
        successToast({ message: "Document has been deleted successfully" });
      },
      onError: error => {
        errorToast({ message: getErrorMessage(error) });
      },
    }
  );
}
