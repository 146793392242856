import { format, parse } from "date-fns";
import React, { useState } from "react";
import { FieldRenderProps } from "react-final-form";
import { Input, input, InputNote, Label } from "stablr";
import { ReactComponent as DatePickerSVG } from "stablr/assets/icons/date-picker.svg";
import color from "stablr/styles/color";
import styled from "styled-components";

import { Calendar } from "./Calendar/Calendar";

const DatePickerIcon = styled(DatePickerSVG)`
  cursor: pointer;
`;

const DateInputStyled = styled.div`
  width: 100%;
  max-width: ${input.maxWidth};
  position: relative;

  & input {
    cursor: pointer;
  }
`;

export function DateInput({
  input: { ...inputProps },
  note,
  compact = false,
  label,
  meta: { error, touched },
  ...props
}: FieldRenderProps<string, HTMLInputElement>) {
  const [isCalendarOpen, setCalendarOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const isError = error && touched;

  const handleDateSelect = (date: Date) => {
    setSelectedDate(date);
    inputProps.onChange({ target: { value: format(date, "dd/MM/yyyy") } });
    closeCalendar();
  };

  const openCalendar = () => {
    setCalendarOpen(!isCalendarOpen);
  };

  const closeCalendar = () => {
    setCalendarOpen(false);
  };

  React.useEffect(() => {
    if (inputProps.value !== undefined && inputProps.value !== "") {
      const initialDate = parse(inputProps.value.toString(), "dd/MM/yyyy", new Date());
      inputProps.onChange({ target: { value: format(initialDate, "dd/MM/yyyy") } });
    }
  }, [inputProps]);

  React.useEffect(() => {
    if (selectedDate) {
      inputProps.onChange({ target: { value: format(selectedDate, "dd/MM/yyyy") } });
    }
  }, [inputProps, selectedDate]);

  return (
    <DateInputStyled>
      <Label htmlFor={inputProps.name}>{label}</Label>
      <Input
        readOnly
        {...inputProps}
        type="text"
        icon={<DatePickerIcon />}
        onIconClick={openCalendar}
        onClick={openCalendar}
        {...props}
      />
      {isCalendarOpen && <Calendar onSelect={handleDateSelect} selectedDate={selectedDate} onClose={closeCalendar} />}
      {(!compact || note?.trim() || isError) && (
        <InputNote htmlFor={inputProps.name} color={isError ? color.palette.red : undefined}>
          {isError ? error : note}
        </InputNote>
      )}
    </DateInputStyled>
  );
}
