import Constants from "constants/index";
import { FORM_ERROR } from "final-form";
import { useConfirmBlockchainTransaction } from "hooks/query/transaction/use-confirm-blockchain-transaction";
import React from "react";
import { Field, Form } from "react-final-form";
import {
  ActionModal,
  ErrorMessage,
  formatCurrency,
  getErrorMessage,
  Spacer,
  Table,
  TextInput,
  validateFormValues,
} from "stablr";
import spacing from "stablr/styles/spacing";
import styled from "styled-components";
import { APITransaction } from "types/API/Transaction";

import { ConfirmOrderValidation } from "./validateTransactionHash";

interface ConfirmPaymentModalProps {
  isOpen: boolean;
  onClose: () => void;
  data: APITransaction;
}

export interface TransactionHashForm {
  transactionHash: string;
}

const { TableBody, TableData, TableRow, TableHeader, Table: TableComponent } = Table;

const TableStyled = styled(TableComponent)`
  th,
  td {
    padding-top: ${spacing.xs};
    padding-bottom: ${spacing.xs};
  }
`;

export function ConfirmBlockchainTransactionModal({ isOpen, onClose, data }: ConfirmPaymentModalProps) {
  const { mutateAsync: mutateAsyncConfirmBlockchainTransaction } = useConfirmBlockchainTransaction();

  const BlockchainTransactionType = data.Type === "BUY" ? "MINT" : "BURN";

  const onConfirm = async ({ transactionHash }: TransactionHashForm) => {
    try {
      await mutateAsyncConfirmBlockchainTransaction({
        transactionId: data.TransactionId, // Needed for cache
        blockchainTransactionId: data.Blockchain.Id,
        transactionHash,
        type: BlockchainTransactionType,
      });
      onClose();
    } catch (er) {
      return { [FORM_ERROR]: getErrorMessage(er) };
    }
  };

  return (
    <Form onSubmit={onConfirm} validate={validateFormValues(ConfirmOrderValidation)}>
      {({ handleSubmit, submitting, submitError }) => (
        <ActionModal
          marginHorizontalSize="small"
          open={isOpen}
          onClose={() => onClose()}
          title={`Confirm ${data.Type === "BUY" ? "Mint" : "Burn"} Transaction`}
          btnText="Confirm"
          description={
            <>
              <TableStyled>
                <TableBody varient="shaded">
                  <>
                    {data.Type === "BUY" && (
                      <>
                        <TableRow>
                          <TableHeader>Wallet Name:</TableHeader>
                          <TableData>{data.Customer.Wallet.WalletName}</TableData>
                        </TableRow>
                        <TableRow>
                          <TableHeader>Wallet Address:</TableHeader>
                          <TableData>{data.Customer.Wallet.WalletAddress}</TableData>
                        </TableRow>
                      </>
                    )}
                    <TableRow>
                      <TableHeader>Amount:</TableHeader>
                      <TableData>
                        {formatCurrency(
                          {
                            Amount: data.Blockchain.BlockchainValue.Amount,
                            Denominator: Constants.fallbackDenominator,
                          },
                          2
                        )}{" "}
                        EURR
                      </TableData>
                    </TableRow>
                  </>
                </TableBody>
              </TableStyled>
              <Spacer height={spacing.m} />
              <Field
                compact
                label={`Transaction Hash of ${data.Type === "BUY" ? "Mint" : "Burn"} Transaction`}
                component={TextInput}
                name="transactionHash"
                placeholder="0xb5c8bd94..."
              />
              {submitError && <ErrorMessage>{submitError}</ErrorMessage>}
            </>
          }
          onConfirm={handleSubmit}
          loading={submitting}
        />
      )}
    </Form>
  );
}
