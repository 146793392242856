import * as yup from "yup";

const MAXIMUM_FILE_SIZE_MB = 10;

export enum DOCUMENT_SCHEMA {
  TITLE = "Title",
  FILE_NAME = "FileName",
  FILE = "File",
}

export const DocumenSchemaValidation = yup.object({
  [DOCUMENT_SCHEMA.TITLE]: yup.string().max(100, "Cannot be longer than 100 characters").required("Name is required"),
  [DOCUMENT_SCHEMA.FILE]: yup
    .mixed()
    .test("fileSize", `Maximum file size ${MAXIMUM_FILE_SIZE_MB}MB`, value => {
      if (value === undefined) {
        return false;
      }
      const oneMegaByte = 1024 * 1024;
      return value.size <= oneMegaByte * MAXIMUM_FILE_SIZE_MB;
    })
    .test("Is .PDF", value => {
      // Types from https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
      if (value === undefined) {
        return false;
      }
      return ["application/pdf"].includes(value?.type);
    })
    .required("Document is required"),
});
