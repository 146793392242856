import { APITransactionStatus } from "types/API/Transaction";

export function parseTransactionStatusText(status: APITransactionStatus): string {
  switch (`${status}`) {
    case "PENDING":
      return "Pending";
    case "CONFIRMED":
      return "Confirmed";
    case "FAILED":
      return "Failed";
    case "Rejected":
      return "Rejected";
    case "AWAITING_PAYOUT":
      return "Awaiting Payout";
    default:
      return status ?? "undefined";
  }
}
