import copy from "copy-to-clipboard";
import * as React from "react";
import { color, fontSize, fontWeight, spacing, transformWalletAddress, useToast } from "stablr";
import { ReactComponent as CopyIconSVG } from "stablr/assets/icons/content-copy.svg";
import { ReactComponent as ContractIcon } from "stablr/assets/icons/contract.svg";
import { Icon } from "stablr/components/atoms";
import styled from "styled-components";

interface ContractAddressTitleProps {
  title: string;
  address: string;
  children: React.ReactElement | React.ReactElement[];
}

const ICON_SIZE = "30px";

const LIST_ITEM_HEIGHT = "50px";

const COPY_BUTTON_HEIGHT = "40px";

const ContractAddressTitleStyled = styled.div`
  position: relative;
  display: flex;
  padding-left: ${ICON_SIZE};
  height: ${LIST_ITEM_HEIGHT};
  line-height: ${LIST_ITEM_HEIGHT};
  align-items: center;

  & > *:first-child {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${LIST_ITEM_HEIGHT};
  }
`;

const CopyableDataStyled = styled.button`
  color: ${color.themeNew.primary};
  font-size: ${fontSize.p};
  height: ${COPY_BUTTON_HEIGHT};
  line-height: ${COPY_BUTTON_HEIGHT};
  border: none;
  min-width: 120px;
  border-radius: 6px;
  background: ${color.greyscale.white};
  position: relative;

  & > div:last-child {
    padding-left: ${spacing.s};
    display: inline-block;
  }

  cursor: pointer;

  &:hover {
    background-color: ${color.greyscale.grey1};
    font-weight: ${fontWeight.semiBold};
  }
`;

const ContractAddressChildrenStyled = styled.div`
  padding-left: ${spacing.m};
`;

const TitleStyled = styled.div`
  height: ${LIST_ITEM_HEIGHT};
  line-height: ${LIST_ITEM_HEIGHT};
  flex: 1;
`;

export function ContractAddressCategory({ title, address, children }: ContractAddressTitleProps) {
  const { infoToast } = useToast();

  const handleOnCopyAddress = () => {
    copy(address);
    infoToast({ title: "Copied", message: `Address ${transformWalletAddress(address)} Copied to Clipboard` });
  };

  return (
    <>
      <ContractAddressTitleStyled>
        <Icon color={color.themeNew.primary} size={22}>
          <ContractIcon />
        </Icon>
        <TitleStyled>{title}</TitleStyled>

        <CopyableDataStyled onClick={handleOnCopyAddress}>
          {transformWalletAddress(address)}
          <Icon color={color.themeNew.primary}>
            <CopyIconSVG />
          </Icon>
        </CopyableDataStyled>
      </ContractAddressTitleStyled>
      <ContractAddressChildrenStyled>{children}</ContractAddressChildrenStyled>
    </>
  );
}
