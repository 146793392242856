import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import * as React from "react";
import { ReactComponent as EthereumSVG } from "stablr/assets/icons/ethereum.svg";
import { ReactComponent as SolanaSVG } from "stablr/assets/icons/solana.svg";
import { Badge, Table } from "stablr/components/atoms";
import { TableHeaderIcon } from "stablr/components/atoms/Table";
import { useIsMobile } from "stablr/hooks";
import { fontWeight } from "stablr/styles";
import styled from "styled-components";
import { APIWalletListItem, APIWalletStatusType } from "types/API/Wallet";

import WalletActions from "./common/WalletActions";
import { getWalletBadgeVarient } from "./functions/get-wallet-badge-varient";
import { parseWalletBadgeText } from "./functions/parse-wallet-badge-text";

const { TableBody, TableData, TableHead, TableHeader, TableRow, TableRowBadge } = Table;

interface WalletTableProps {
  data: APIWalletListItem[];
  organizationId: string;
}

interface NetworkIconsType {
  [key: string]: JSX.Element;
}

const WalletTableStyled = styled.table`
  width: 100%;

  & th {
    font-weight: ${fontWeight.bold};
  }
`;

const WalletTableWrapperStyled = styled.div`
  & tr {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    & span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 200px;
    }

    & span[data-testid="Badge"] {
      max-width: unset;
      margin: 0;
    }

    & b {
      font-weight: ${fontWeight.semiBold};
    }
  }
`;

const columnHelper = createColumnHelper<APIWalletListItem>();

const networkIcons: NetworkIconsType = {
  Ethereum: <EthereumSVG />,
  Solana: <SolanaSVG />,
};

const getNetworkIcon = (network: string): JSX.Element | null => {
  return networkIcons[network] || null;
};

export function WalletTable({ data, organizationId }: WalletTableProps) {
  const isMobile = useIsMobile();

  const columns = React.useMemo(() => {
    const tableColumns = [
      columnHelper.accessor("Network", {
        header: "",
        cell: info => getNetworkIcon(info.getValue()),
        footer: info => info.column.id,
        enableSorting: false,
      }),
      columnHelper.accessor("Name", {
        header: "Name",
        cell: info => info.getValue(),
        footer: info => info.column.id,
      }),
      columnHelper.accessor("Address", {
        header: "Address",
        cell: info => info.getValue(),
        footer: info => info.column.id,
      }),
      columnHelper.accessor("Type", {
        header: "Type",
        cell: info => info.getValue(),
        footer: info => info.column.id,
      }),
      columnHelper.accessor("Status", {
        header: "Status",
        cell: info => displayStatus(info.getValue()),
        footer: info => info.column.id,
      }),
      columnHelper.accessor("Id", {
        header: "",
        cell: info => (
          <WalletActions id={info.getValue()} status={info.row.original.Status} organizationId={organizationId} />
        ),
        footer: info => info.column.id,
        enableSorting: false,
      }),
    ];

    const mobileColumns = [tableColumns[0], tableColumns[1], tableColumns[3]];
    const col = [...(isMobile ? mobileColumns : tableColumns)];
    return col;
  }, [isMobile, organizationId]);

  const [sorting, setSorting] = React.useState<SortingState>([]);

  const displayStatus = (status: APIWalletStatusType) => {
    if (status === "APPROVED") {
      return "Approved";
    } else if (status === "DELETED") {
      return "Deleted";
    } else {
      return <Badge text={parseWalletBadgeText(status)} varient={getWalletBadgeVarient(status)} />;
    }
  };

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <WalletTableWrapperStyled>
      <WalletTableStyled>
        <TableHead>
          <>
            {table.getHeaderGroups().map(headerGroup => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <TableHeader
                    key={header.id}
                    icon={<TableHeaderIcon header={header} />}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHeader>
                ))}
              </TableRow>
            ))}
          </>
        </TableHead>
        <TableBody>
          <>
            {table.getRowModel().rows.map(row => (
              <React.Fragment key={row.getValue("Id")}>
                {isMobile && (
                  <TableRowBadge
                    colSpan={3}
                    badgeElement={
                      <Badge
                        varient={getWalletBadgeVarient(row.original?.Status)}
                        text={parseWalletBadgeText(row.original?.Status)}
                      />
                    }
                  ></TableRowBadge>
                )}
                <TableRow key={row.id}>
                  {row.getVisibleCells().map(cell => {
                    return (
                      <TableData key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableData>
                    );
                  })}
                </TableRow>
              </React.Fragment>
            ))}
          </>
        </TableBody>
      </WalletTableStyled>
    </WalletTableWrapperStyled>
  );
}
