import { useQuery } from "@tanstack/react-query";
import api from "api";

useTransactionProfile.queryKey = "transaction-profile";

export function useTransactionProfile(organizationId: string) {
  return useQuery(
    [useTransactionProfile.queryKey, organizationId],
    async () => api.Customer.getTransactionProfile(organizationId),
    {
      notifyOnChangeProps: "all",
    }
  );
}
