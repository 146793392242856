import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "api";
import Constants from "constants/index";
import { getErrorMessage } from "stablr/functions";
import { useToast } from "stablr/hooks";

import { useBankAccount } from "./use-bank-account";

export function useConfirmBank() {
  const queryClient = useQueryClient();
  const { errorToast, successToast } = useToast();

  return useMutation({
    mutationFn: async ({ bankId }: { organizationId: string; bankId: string }) =>
      api.Customer.confirmCustomerBank(bankId),
    onSuccess: async (_, { organizationId }: { organizationId: string; bankId: string }) => {
      setTimeout(async () => {
        await queryClient?.invalidateQueries({ queryKey: [useBankAccount.queryKey, organizationId] });
      }, Constants.cache.defaultSocketDelay);

      successToast({ message: "Bank has been confirmed successfully" });
    },
    onError: error => {
      errorToast({ message: getErrorMessage(error) });
    },
  });
}
