import { BadgeVarients } from "stablr";
import { APIFiatKYTStatus } from "types/API/Transaction";

export function getFiatKYTStatusVarient(status: APIFiatKYTStatus): BadgeVarients {
  switch (`${status}`) {
    case "FAILED":
      return "default";
    case "COMPLETED":
      return "success";
    case "UNDER_INVESTIGATION":
      return "action";
    case "BLOCKED":
      return "error";
    case "NONE":
      return "default";
    default:
      return "default";
  }
}
