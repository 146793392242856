import Constants from "constants/index";
import { formatISO, parse } from "date-fns";
import { FORM_ERROR } from "final-form";
import { useConfirmPayment } from "hooks/query/transaction/use-confirm-payment";
import React from "react";
import { Field, Form } from "react-final-form";
import { DateInput } from "refactor/DateInput";
import { ValidationAlert } from "stablr";
import { ErrorMessage, ListBoxInput, TextInput } from "stablr/components/molecules";
import { ActionModal, FormLayout } from "stablr/components/organisms";
import { getErrorMessage, validateFormValues } from "stablr/functions";
import spacing from "stablr/styles/spacing";
import styled from "styled-components";
import { APITransaction } from "types/API/Transaction";

import { formatPaymentAmount } from "../../../../refactor/functions/format-payment-amount";
import { parsePaymentAmount } from "../../../../refactor/functions/parse-payment-amount";
import { PAYMENT_CONFIRMATION_SCHEMA, PaymentConfirmationValidation } from "./paymentConfirmationSchemaValidation";

interface ConfirmPaymentModalProps {
  isOpen: boolean;
  onClose: () => void;
  id: string;
  data: APITransaction;
}

interface ConfirmPayloadProps {
  PaymentInstructionId: string;
  BankingFee: number;
  Currency: string;
  Description: string;
  GrossAmount: number;
  IBAN: string;
  TransferredDateTime: string;
}

const FormLayoutWrapper = styled.div`
  padding-right: ${spacing.m};
  max-height: 380px;
  overflow-y: auto;
  overflow-x: hidden;
`;

const TextStyled = styled.p`
  margin-top: -${spacing.m};
`;

const currencies = [
  {
    value: "EUR",
    label: "EUR",
  },
];

export function ConfirmPaymentModal({ isOpen, id, onClose, data }: ConfirmPaymentModalProps) {
  const { mutateAsync: mutateAsyncConfirmPayment, isLoading } = useConfirmPayment();

  const handleClose = () => {
    if (isLoading) return;
    onClose();
  };

  const onConfirm = async (values: ConfirmPayloadProps) => {
    try {
      const parsedDate = parse(values.TransferredDateTime, "dd/MM/yyyy", new Date());
      const formattedDate = formatISO(parsedDate, { representation: "date" });

      const parsedGrossAmount = parsePaymentAmount(values.GrossAmount.toString());
      const parsedBankingFee = parsePaymentAmount(values.BankingFee.toString());

      await mutateAsyncConfirmPayment({
        TransactionId: data.TransactionId, // Needed for Cache Reloading
        Type: data.Type, // Needed for Cache Reloading
        PaymentInstructionId: data.PaymentInstructionId,
        TransferDate: formattedDate,
        BankAccount: values.IBAN,
        Currency: values.Currency,
        GrossAmount: parsedGrossAmount,
        FeeAmount: parsedBankingFee,
        Description: values.Description,
      });

      onClose();
    } catch (er) {
      return { [FORM_ERROR]: getErrorMessage(er) };
    }
  };

  const isBlincType = data !== undefined && data.Customer.BankAccount.Types?.includes("BLINC");

  return (
    <Form
      initialValues={{
        [PAYMENT_CONFIRMATION_SCHEMA.PAYMENT_INSTRUCTION_ID]: data.PaymentInstructionId,
        [PAYMENT_CONFIRMATION_SCHEMA.TRANSFER_DATE]: data.Fiat.TransferredDateTime,
        [PAYMENT_CONFIRMATION_SCHEMA.BANK_ACCOUNT]: "", // Needs to be empty, validated against available data at submit
        [PAYMENT_CONFIRMATION_SCHEMA.CURRENCY]: data.Fiat.FiatValue.Currency,
        [PAYMENT_CONFIRMATION_SCHEMA.GROSS_AMOUNT]: data.Fiat.GrossAmount,
        [PAYMENT_CONFIRMATION_SCHEMA.FEE]: data.Fiat.BankingFee,
        [PAYMENT_CONFIRMATION_SCHEMA.DESCRIPTION]: data.Description,
      }}
      keepDirtyOnReinitialize
      onSubmit={onConfirm}
      validate={validateFormValues(PaymentConfirmationValidation)}
    >
      {({ handleSubmit, submitting, submitError }) => {
        return (
          <form onSubmit={handleSubmit}>
            <ActionModal
              marginHorizontalSize="small"
              open={isOpen}
              onClose={handleClose}
              title={`Confirm Payment - ${id}`}
              btnText="Confirm"
              description={
                <>
                  <TextStyled>Please enter the data in the fields</TextStyled>
                  <FormLayoutWrapper>
                    <FormLayout>
                      <Field
                        label="Transfer Date"
                        component={DateInput}
                        name={PAYMENT_CONFIRMATION_SCHEMA.TRANSFER_DATE}
                        placeholder={"Select date"}
                        compact
                      />
                      <Field
                        label={`Bank account (${isBlincType ? "Blinc ID" : "IBAN/Swift"})`}
                        component={TextInput}
                        name={PAYMENT_CONFIRMATION_SCHEMA.BANK_ACCOUNT}
                        placeholder={`Bank account (${isBlincType ? "Blinc ID" : "IBAN/Swift"})`}
                        compact
                        validate={value => {
                          if (
                            isBlincType
                              ? value !== data.Customer.BankAccount.BlincId
                              : value !== data.Customer.BankAccount.IBAN
                          ) {
                            return (
                              <ValidationAlert message="Bank Account does not match with whitelisted customer bank accounts" />
                            );
                          }
                        }}
                      />
                      <Field
                        label="Currency"
                        options={currencies}
                        component={ListBoxInput}
                        name={PAYMENT_CONFIRMATION_SCHEMA.CURRENCY}
                        placeholder={"Currency"}
                        compact
                      />
                      <Field
                        label="Gross Amount"
                        component={TextInput}
                        name={PAYMENT_CONFIRMATION_SCHEMA.GROSS_AMOUNT}
                        placeholder="Gross amount"
                        format={value => formatPaymentAmount(value, Constants.fallbackDenominator)}
                        formatOnBlur
                        compact
                      />
                      <Field
                        label="Fee"
                        component={TextInput}
                        name={PAYMENT_CONFIRMATION_SCHEMA.FEE}
                        placeholder="Fee"
                        format={value => formatPaymentAmount(value, Constants.fallbackDenominator)}
                        formatOnBlur
                        compact
                      />
                      <Field
                        label="Description"
                        component={TextInput}
                        name={PAYMENT_CONFIRMATION_SCHEMA.DESCRIPTION}
                        placeholder={"Description"}
                        compact
                      />
                    </FormLayout>
                    {submitError && <ErrorMessage>{submitError}</ErrorMessage>}
                  </FormLayoutWrapper>
                </>
              }
              onConfirm={handleSubmit}
              loading={submitting}
            />
          </form>
        );
      }}
    </Form>
  );
}
