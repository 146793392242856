import { useTransactionProfile } from "hooks/query/customers/customer/use-transaction-profile";
import React from "react";
import { useParams } from "react-router-dom";
import { CardError, CardLoader, DashboardContainer, getErrorMessage, Heading } from "stablr";

import { TransactionProfileForm } from "./TransactionProfileForm";

export function TransactionProfilePage() {
  const { id } = useParams();

  const { data, error, isLoading, refetch } = useTransactionProfile(`${id}`);

  return (
    <DashboardContainer>
      <Heading as="h3">Expected Transaction Profile</Heading>
      {error ? (
        <CardError body={getErrorMessage(error)} title="Error fetching transactionProfile" onRetry={refetch} />
      ) : isLoading ? (
        <CardLoader />
      ) : (
        <TransactionProfileForm profile={data} />
      )}
    </DashboardContainer>
  );
}
