import { useDeleteDocument } from "hooks/query/customers/document/use-delete-document";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ActionModal } from "stablr/components/organisms";
import { color } from "stablr/styles";

export default function DeleteDocumentModal({
  isOpen,
  onClose,
  fileId,
  organizationId,
  fileName,
}: {
  isOpen: boolean;
  onClose: () => void;
  fileId: string;
  fileName: string;
  organizationId: string;
}) {
  const navigate = useNavigate();

  const { mutateAsync: mutateAsyncDeleteDocument, isLoading } = useDeleteDocument();

  const onDelete = async () =>
    mutateAsyncDeleteDocument(
      { organizationId, fileId },
      {
        onSuccess: () => {
          onClose();
          navigate("./documents");
        },
      }
    );

  const handleClose = () => {
    if (isLoading) return;
    onClose();
  };

  return (
    <ActionModal
      open={isOpen}
      onClose={handleClose}
      title={`Delete: ${fileName}`}
      btnText="Delete"
      description="Are you sure you want to delete this file including all the version history?"
      onConfirm={onDelete}
      loading={isLoading}
      btnColor={color.greyscale.white}
      btnBackgroundColor={color.palette.red}
    />
  );
}
