import Constants from "constants/index";
import { parse } from "date-fns";
import isPast from "date-fns/isPast";
import * as yup from "yup";

import { formatPaymentAmount } from "../../../../refactor/functions/format-payment-amount";

export enum PAYMENT_CONFIRMATION_SCHEMA {
  PAYMENT_INSTRUCTION_ID = "PaymmentInstructionId",
  TRANSFER_DATE = "TransferredDateTime",
  BANK_ACCOUNT = "IBAN",
  CURRENCY = "Currency",
  GROSS_AMOUNT = "GrossAmount",
  FEE = "BankingFee",
  DESCRIPTION = "Description",
}

export const PaymentConfirmationValidation = yup.object({
  IBAN: yup
    .string()
    .required("Bank Account is required"),
  TransferredDateTime: yup
    .string()
    .nullable()
    .required("Date is required")
    .matches(/^(\d{2}\/\d{2}\/\d{4})$/, 'Date should be in the format "DD/MM/YYYY"')
    .test("Is Past", "Transfer date can not be later than today", value => {
      if (!value) return true;
      const parsedDate = parse(value, "dd/MM/yyyy", new Date());
      return isPast(parsedDate);
    }),

  [PAYMENT_CONFIRMATION_SCHEMA.GROSS_AMOUNT]: yup
    .mixed()
    .required("Gross Amount is required")
    .test("format", "Invalid format (e.g., 1,000.00)", value => {
      if (!value) return true;
      const formattedValue = formatPaymentAmount(value, Constants.fallbackDenominator);
      return value === formattedValue;
    }),
  [PAYMENT_CONFIRMATION_SCHEMA.FEE]: yup
    .mixed()
    .required("Banking fee is required")
    .test("format", "Invalid format (e.g., 1,000.00)", value => {
      if (!value) return true;
      const formattedValue = formatPaymentAmount(value, Constants.fallbackDenominator);
      return value === formattedValue;
    }),
  [PAYMENT_CONFIRMATION_SCHEMA.DESCRIPTION]: yup
    .string()
    .max(22, "Description must be at most 22 characters")
    .matches(/^[a-zA-Z0-9.,'_\-?+*/\s]+$/, "Invalid characters in Description")
    .required("Description is required"),
});
