import { useQuery } from "@tanstack/react-query";
import api from "api";

useCustomer.queryKey = "customer";

export function useCustomer(organizationId: string) {
  return useQuery([useCustomer.queryKey, organizationId], async () => api.Customer.getCustomer(organizationId), {
    notifyOnChangeProps: "all",
  });
}
