import { Web3ReactProvider } from "@web3-react/core";
import { connectors } from "connectors";
import * as React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useCurrentLocationFromPaths } from "refactor/use-current-location-from-paths";
import { DashboardContainer, DropDown, Tabs, useIsMobile } from "stablr";
import { composeTestID } from "stablr/functions/compose-test-id";

import { EthereumNetwork } from "./Networks/Ethereum/EthereumNetwork";

interface indexProps {
  testid?: string;
}

ContractsPage.testid = "index";

const TAB_LIST = [
  {
    label: "Ethereum",
    value: "/contracts/ethereum",
    testid: "contracts-ethereum",
  },
  // {
  //   label: "Solana",
  //   value: "/contracts/solana",
  //   testid: "contracts-solana",
  // },
];

export default function ContractsPage({ ...props }: indexProps) {
  const navigation = useNavigate();
  const isMobile = useIsMobile();

  const handleOnTabChange = React.useCallback(
    (value: string) => {
      navigation(value);
    },
    [navigation]
  );

  const { permittedTabList, routes } = React.useMemo(() => {
    return {
      permittedTabList: TAB_LIST,
      routes: (
        <Routes>
          <Route path="*" element={<EthereumNetwork />} />
          <Route path="/ethereum" element={<EthereumNetwork />} />
          {/* <Route path="/solana" element={<>solana</>} /> */}
        </Routes>
      ),
    };
  }, []);

  const currentLocation = useCurrentLocationFromPaths(permittedTabList.map(tabItem => tabItem.value));

  return (
    <Web3ReactProvider connectors={connectors}>
      <DashboardContainer data-testid={composeTestID(ContractsPage.testid, props.testid)}>
        {isMobile ? (
          <>
            <DropDown
              defaultValue={permittedTabList[0].value}
              options={permittedTabList}
              value={permittedTabList[currentLocation]?.value as string}
              onChange={handleOnTabChange}
            />
            <>{routes}</>
          </>
        ) : (
          <Tabs
            value={permittedTabList[currentLocation]?.value as string}
            tabList={permittedTabList}
            onChange={handleOnTabChange}
          >
            <>{routes}</>
          </Tabs>
        )}
      </DashboardContainer>
    </Web3ReactProvider>
  );
}
