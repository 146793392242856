// import api from "api";
import { CustodianBank } from "types/API/CustodianBank";

import Connection from "./Connection";
// import { MOCK_CUSTODIAN_BANKS } from "./mocks/mock-custodian-banks";

type APIGetCustodianBanks = CustodianBank[];

export const getCustodianBanks = (): Promise<APIGetCustodianBanks> => Connection.get(`/ops/governance/bankaccounts`);

// export const getCustodianBanks = (): Promise<APIGetCustodianBanks> =>
//   api.Mock.mockRequest(`/ops/custodian-banks`, MOCK_CUSTODIAN_BANKS) as Promise<any>;
