import Constants from "constants/index";
import { useContractTotalSupply } from "hooks/query/web3/contract/use-contract-total-supply";
import * as React from "react";
import { formatCurrency } from "stablr";
import { CardData, CardWrapper } from "stablr/components/molecules";
import { color, spacing } from "stablr/styles";
import styled from "styled-components";

const BalanceCardStyled = styled(CardWrapper)`
  background: ${color.gradient};
  padding: ${spacing.m} ${spacing.l};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default function BalanceCardComponent() {
  const { data: totalSupply, isLoading: isTotalSupplyLoading } = useContractTotalSupply("EURR");

  return (
    <BalanceCardStyled>
      <CardData
        color={color.greyscale.white}
        label="EURR in circulation"
        data={
          isTotalSupplyLoading
            ? "-"
            : `${formatCurrency(
                {
                  Amount: totalSupply,
                  Denominator: Constants.fallbackDenominator,
                },
                2
              )} EURR`
        }
      />
    </BalanceCardStyled>
  );
}
