export function formatPaymentAmount(value: string | null, denominator = "," as "." | ","): string {
  if (value === null) {
    return "";
  }

  // Strip any characters (using type="number" on input causes formating clashes (with , and .))
  const strippedValue = value.replace(/`/g, "").replace(/[^0-9.]/g, "");
  if (strippedValue === "") {
    return "";
  }

  const parsedAmount = parseFloat(strippedValue);
  return parsedAmount.toLocaleString(denominator === "." ? "en-US" : "de-DE", {
    minimumFractionDigits: 2,
  });
}
