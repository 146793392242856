import { fetchAuthSession } from "aws-amplify/auth";
import Constants from "constants/index";
import React from "react";
import { matchRoutes, useLocation, useNavigate } from "react-router-dom";
import { FullScreenLoader } from "stablr/components/molecules";

export default function AuthenticationWrapper({
  children,
}: {
  children: (isAuthenticatedPath: boolean) => React.ReactElement;
}) {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const isAuthenticatedPath = React.useMemo(
    () => matchRoutes(Constants.routes.authenticated, pathname) !== null,
    [pathname]
  );

  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    const getCurrentSession = async () => {
      try {
        /* eslint-disable @typescript-eslint/no-unused-vars */

        const payload = (await fetchAuthSession()).tokens?.idToken?.payload;
        if (!isAuthenticatedPath) {
          navigate("../dashboard");
        }
        setIsLoading(false);
      } catch (error: unknown) {
        if (!isAuthenticatedPath) {
          navigate("../sign-in");
          return;
        }
        // If there is no active user session
        if (error === "No current user") {
          navigate("../sign-in");
        } // If there is a different error with the session (handle the same way)
        else {
          navigate("../sign-in");
        }
      }
    };
    getCurrentSession();
  }, [isAuthenticatedPath, navigate]);

  return isAuthenticatedPath && isLoading && !Constants.routes?.authenticated?.length ? (
    <FullScreenLoader />
  ) : (
    children(isAuthenticatedPath)
  );
}
