import { useCustomer } from "hooks/query/customers/customer/use-customer";
import React from "react";
import { useParams } from "react-router-dom";
import { ReactComponent as PieChartIconSVG } from "stablr/assets/icons/pie-chart.svg";
import { CardEmpty, CardError, CardLoader } from "stablr/components/molecules";
import { getErrorMessage } from "stablr/functions";

import { CustomerInfoForm } from "./CustomerInfoForm";

function CustomerInfoPage() {
  const { id } = useParams();
  const { data, isLoading, error: errorCustomer, refetch } = useCustomer(`${id}`);

  return (
    <>
      {errorCustomer ? (
        <CardError body={getErrorMessage(errorCustomer)} title="Error fetching customer" onRetry={refetch} />
      ) : isLoading ? (
        <CardLoader />
      ) : !data ? (
        <CardEmpty
          iconElement={<PieChartIconSVG />}
          title="No customer found"
          body="There is no customer at the moment"
        />
      ) : (
        <CustomerInfoForm data={data} />
      )}
    </>
  );
}

export default CustomerInfoPage;
