import { useConfirmCustomer } from "hooks/query/customers/customer/use-confirm-customer";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as CheckedCirleIcon } from "stablr/assets/icons/checked-circle.svg";
import { Button } from "stablr/components/atoms";
import { ActionModal } from "stablr/components/organisms";
import color from "stablr/styles/color";

export default function ApproveCustomer({ title, organizationId }: { organizationId: string; title: string }) {
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);

  const { mutateAsync: mutateAsyncConfirmCustomer, isLoading } = useConfirmCustomer();

  const openModal = (event: React.MouseEvent<HTMLElement>) => {
    event?.preventDefault();
    setOpen(true);
  };

  const onCloseModal = () => !isLoading && setOpen(false);

  const onApprove = async () => {
    await mutateAsyncConfirmCustomer({ organizationId }, { onSuccess: () => navigate(-1) });
    onCloseModal();
  };

  return (
    <>
      <Button
        onClick={openModal}
        icon={<CheckedCirleIcon />}
        backgroundColor={color.palette.green}
        width="160px"
        loading={isLoading}
      >
        Approve
      </Button>
      <ActionModal
        open={open}
        onClose={onCloseModal}
        title={`Confirm Customer ${title}`}
        btnText="Confirm"
        description={`Are you sure you want to confirm ${title}?`}
        onConfirm={onApprove}
        btnColor={color.greyscale.white}
        loading={isLoading}
      />
    </>
  );
}
