import { useQuery } from "@tanstack/react-query";
import api from "api";

useTransaction.queryKey = "transaction";

export function useTransaction(transactionId: string) {
  return useQuery([useTransaction.queryKey, transactionId], async () => api.Transaction.getTransaction(transactionId), {
    notifyOnChangeProps: "all",
  });
}
