import { useQuery } from "@tanstack/react-query";
import api from "api";

useDocument.queryKey = "documemnt";

export function useDocument(organizationId: string, fileId: string) {
  return useQuery(
    [useDocument.queryKey, fileId],
    async () => api.Document.getDocumentById(organizationId, fileId),
    {
      notifyOnChangeProps: "all",
    }
  );
}