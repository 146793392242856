import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  Row,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { format } from "date-fns";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Table } from "stablr/components/atoms";
import { TableHeaderIcon } from "stablr/components/atoms/Table";
import { useIsMobile } from "stablr/hooks";
import styled from "styled-components";
import { APIDocument } from "types/API/Document";

import DocumentActions from "./common/DocumentActions";

const { TableBody, Table: TableStyled, TableData, TableHead, TableHeader, TableRow } = Table;

interface DocumentTableProps {
  data: APIDocument[];
  organizationId?: string;
}

const DocumentTableStyled = styled(TableStyled)`
  & tr > td:nth-child(1) {
    /* Stop date from wrapping */
    white-space: nowrap;
  }

  & tr > td:nth-last-child(2) {
    /* Styles for the actions column */
    width: 20px;
  }
`;

const columnHelper = createColumnHelper<APIDocument>();

export function DocumentTable({ data, organizationId }: DocumentTableProps) {
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const [sorting, setSorting] = React.useState<SortingState>([]);

  const handleOnActionsClick = (row: Row<APIDocument>) => {
    navigate(`/customers/${organizationId}/documents/${row.original.FileId}/versions`);
  };

  const columns = React.useMemo(() => {
    const tableColumns = [
      columnHelper.accessor("UploadedAt", {
        header: "Timestamp",
        cell: info => <TableData>{format(new Date(info.getValue()), "dd/MM/y HH:mm") ?? "-"}</TableData>,
        footer: info => info.column.id,
      }),
      columnHelper.accessor("Title", {
        header: "Name",
        cell: info => <TableData>{info.getValue() ?? "-"}</TableData>,
        footer: info => info.column.id,
      }),

      columnHelper.accessor("FileName", {
        header: "Document",
        cell: info => <TableData>{info.getValue() ?? "-"}</TableData>,
        footer: info => info.column.id,
      }),
      columnHelper.accessor("FileId", {
        header: "",
        cell: info => <DocumentActions title={info.row.original.Title} fileId={info.getValue()} />,
        footer: info => info.column.id,
        enableSorting: false,
      }),
    ];

    const mobileColumns = [tableColumns[0], tableColumns[1], tableColumns[3]];
    const col = [...(isMobile ? mobileColumns : tableColumns)];
    return col;
  }, [isMobile]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <DocumentTableStyled>
      <TableHead>
        {table.getHeaderGroups().map(headerGroup => (
          <TableRow key={headerGroup.id}>
            {headerGroup.headers.map(header => (
              <TableHeader
                icon={<TableHeaderIcon header={header} />}
                key={header.id}
                onClick={header.column.getToggleSortingHandler()}
              >
                {flexRender(header.column.columnDef.header, header.getContext())}
              </TableHeader>
            ))}
          </TableRow>
        ))}
      </TableHead>
      <TableBody>
        {table.getRowModel().rows.map(row => (
          <React.Fragment key={`ROW_${row.id}`}>
            <TableRow key={row.id} onRowClick={() => handleOnActionsClick(row)}>
              {row.getVisibleCells().map(cell => {
                return (
                  <React.Fragment key={`Row_${row.id}_Column_${cell.id}`}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </React.Fragment>
                );
              })}
            </TableRow>
          </React.Fragment>
        ))}
      </TableBody>
    </DocumentTableStyled>
  );
}
