import { flexRender, getCoreRowModel, getSortedRowModel, SortingState, useReactTable } from "@tanstack/react-table";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Table } from "stablr/components/atoms";
import { TableHeaderIcon } from "stablr/components/atoms/Table";
import { useIsMobile } from "stablr/hooks";
import styled from "styled-components";
import { APITransactionListItem } from "types/API/Transaction";

import { BUY_ORDER_TABLE_COLUMNS } from "./BuyOrderTableColumns";
import { SELL_ORDER_TABLE_COLUMNS } from "./SellOrderTableColumns";

const { TableBody, TableHead, TableHeader, TableRow, Table: TableComponent } = Table;

interface TransactionsTableProps {
  data: APITransactionListItem[];
  type: "BUY" | "SELL";
}

const TableStyled = styled(TableComponent)`
  & tr > td:nth-child(1) {
    /* Stop date from wrapping */
    white-space: nowrap;
  }
`;

export function TransactionsTable({ data }: TransactionsTableProps) {
  const navigate = useNavigate();

  const transactionType = window.location.pathname.includes("sell") ? "SELL" : "BUY";

  const tableColumns = transactionType === "SELL" ? SELL_ORDER_TABLE_COLUMNS : BUY_ORDER_TABLE_COLUMNS;

  const isMobile = useIsMobile();

  const handleRowClick = (id: string) => {
    const rowData = data.find(item => item.TransactionId === id);
    if (rowData) {
      navigate(`/transactions/${transactionType === "SELL" ? "sell" : "buy"}/${id}`, { state: { rowData } });
    }
  };

  const [sorting, setSorting] = React.useState<SortingState>([
    {
      id: "CreatedAt",
      desc: true,
    },
  ]);

  const columns = React.useMemo(() => {
    const mobileColumns = [tableColumns[0], tableColumns[1], tableColumns[2], tableColumns[3]];
    const col = [...(isMobile ? mobileColumns : tableColumns)];
    return col;
  }, [isMobile, tableColumns]);

  const table = useReactTable<APITransactionListItem>({
    data,
    columns,
    state: {
      sorting,
    },
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <TableStyled>
      <TableHead>
        <>
          {table.getHeaderGroups().map(headerGroup => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <TableHeader
                  key={header.id}
                  icon={<TableHeaderIcon header={header} />}
                  onClick={header.column.getToggleSortingHandler()}
                >
                  {flexRender(header.column.columnDef.header, header.getContext())}
                </TableHeader>
              ))}
            </TableRow>
          ))}
        </>
      </TableHead>
      <TableBody>
        {table.getRowModel().rows.map(row => (
          <React.Fragment key={`TRANSACTION_Row_${row.id}`}>
            <TableRow onRowClick={() => handleRowClick(row.original?.TransactionId)}>
              {row.getVisibleCells().map(cell => {
                return (
                  <React.Fragment key={`TRANSACTION_Expandable_${cell.id}_${row.id}`}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </React.Fragment>
                );
              })}
            </TableRow>
          </React.Fragment>
        ))}
      </TableBody>
    </TableStyled>
  );
}
