import { useQuery } from "@tanstack/react-query";
import api from "api";
import { getErrorMessage } from "stablr/functions";
import { useToast } from "stablr/hooks";
import { APIFileResponse } from "types/API/File";

useDownloadPayment.queryKey = "download-payment";

export default function useDownloadPayment(organizationId: string, paymentInstructionId: string) {
  const { errorToast, successToast } = useToast();
  return useQuery(
    [useDownloadPayment.queryKey, organizationId, paymentInstructionId],
    async () => api.Payment.downloadPaymentFile(organizationId, paymentInstructionId),
    {
      enabled: false,
      retry: false,
      onSuccess: (response: APIFileResponse) => {
        window.open(response.SignedUrl);
        successToast({ message: "Successfully downloaded the file", title: "File Download" });
      },
      onError: error => {
        errorToast({ message: getErrorMessage(error), title: "Payment Download Failed" });
      },
    }
  );
}
