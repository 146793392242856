import * as yup from "yup";

function validateTxhash(TxHash: string) {
  return /^0x([A-Fa-f0-9]{64})$/.test(TxHash);
}
export const ConfirmOrderValidation = yup.object({
  transactionHash: yup
    .string()
    .required("Transaction hash is required")
    .test("validateTransaction", "Invalid transaction hash", (value?: string) =>
      value ? validateTxhash(value) : true
    ),
});
