import api from "api";
import { APIFileResponse } from "types/API/File";
import { APIPaymentFile } from "types/API/Payment";

import Connection from "./Connection";

export const getPaymentFileId = (organizationId: string, paymentInstructionId: string): Promise<APIPaymentFile> =>
  Connection.get(`/ops/files/${organizationId}/payment-instructions/${paymentInstructionId}`);

export const downloadPaymentFile = (organizationId: string, paymentInstructionId: string): Promise<APIFileResponse> =>
  getPaymentFileId(organizationId, paymentInstructionId).then(data =>
    api.Files.downloadFile(organizationId, data.FileId, "payment-instructions")
  );
