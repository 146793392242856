import { useQuery } from "@tanstack/react-query";
import api from "api";

useCustomers.queryKey = "customers";

export function useCustomers() {
  return useQuery([useCustomers.queryKey], async () => api.Customer.getCustomerList(), {
    notifyOnChangeProps: "all",
  });
}
