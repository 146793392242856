import { useQuery } from "@tanstack/react-query";
import api from "api";

useDocuments.queryKey = "documents";

export function useDocuments(id: string) {
  return useQuery([useDocuments.queryKey,id], async () => api.Document.getDocumentList(id), {
    notifyOnChangeProps: "all",
  });
}
