import { APIWalletStatusType } from "types/API/Wallet";

export function getWalletBadgeVarient(status: APIWalletStatusType) {
  switch (`${status}`) {
    case "PENDING":
      return "info";
    case "APPROVED":
      return "success";
    case "REJECTED":
      return "error";
    default:
      return "default";
  }
}
