import { useQuery } from "@tanstack/react-query";
import api from "api";

useBankAccounts.queryKey = "bank-accounts";

export function useBankAccounts(organizationId: string) {
  return useQuery([useBankAccounts.queryKey], async () => api.Customer.getCustomerBankList(organizationId), {
    notifyOnChangeProps: "all",
  });
}
