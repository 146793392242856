import { useTransactions } from "hooks/query/transaction/use-transactions";
import * as React from "react";
import { CardEmpty, CardError, CardLoader, getErrorMessage } from "stablr";
import { ReactComponent as TransactionsIcon } from "stablr/assets/icons/transactions.svg";

import { TransactionsTable } from "./common/TransactionsTable";

export function BuyOrderTab() {
  const { data, isLoading, error, refetch } = useTransactions("BUY");

  return (
    <>
      {error ? (
        <CardError body={getErrorMessage(error)} title="Error fetching Buy Order(s)" onRetry={refetch} />
      ) : isLoading ? (
        <CardLoader />
      ) : !data?.length ? (
        <CardEmpty
          iconElement={<TransactionsIcon />}
          title="No Buy Order(s) found"
          body="There currently no Buy Orders"
        />
      ) : (
        <TransactionsTable data={data} type="BUY" />
      )}
    </>
  );
}
