import color from "stablr/styles/color";
import { createGlobalStyle } from "styled-components";
import { normalize } from "styled-normalize";

const GlobalStyle = createGlobalStyle`
${normalize}

/** Defaults **/

html,body, #root {
  font-family: 'Montserrat';
  font-weight: 400;
  // transition: all .1s ease-out; // Option for faded intro?
  height:100%;
  /*
  }
  next can be declared only for body but won't hurt if both html/body
  body {
  */
  display:flex;
  flex-flow:column;
}
html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

/** Input Fields **/
input:focus, textarea:focus, select:focus{
        outline: none;
    }

// Set default disabled background color for input
input:disabled{ 
  background-color: ${color.greyscale.grey1};
}

// Remove number input arrows
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* Chrome autofill override */
input:-webkit-autofill{
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}


`;

export default GlobalStyle;
