import { APITransaction, APITransactionListItem } from "types/API/Transaction";

export const MOCK_TRANSACTIONS: APITransactionListItem[] = [
  {
    TransactionId: "2i37gu3fdsd",
    CreatedDateTime: new Date().toISOString(),
    Type: "BUY",
    Fiat: {
      Status: "PENDING",
      KYTStatus: "UNDER_INVESTIGATION",
      FiatValue: {
        Amount: 10.0,
        Currency: "EUR",
      },
    },
    Blockchain: {
      Status: "TRANSFERRED",
      KYTStatus: "UNDER_INVESTIGATION",
      BlockchainValue: {
        Amount: 10.0,
        Currency: "EURR",
      },
    },
  },
  {
    TransactionId: "2ier3wfdsd",
    CreatedDateTime: new Date().toISOString(),
    Type: "BUY",
    Fiat: {
      Status: "PENDING",
      KYTStatus: "UNDER_INVESTIGATION",
      FiatValue: {
        Amount: 10.0,
        Currency: "EUR",
      },
    },
    Blockchain: {
      Status: "REJECTED",
      KYTStatus: "UNDER_INVESTIGATION",
      BlockchainValue: {
        Amount: 10.0,
        Currency: "EURR",
      },
    },
  },
  {
    TransactionId: "144u3fdsd",
    CreatedDateTime: new Date().toISOString(),
    Type: "SELL",
    Fiat: {
      Status: "REJECTED",
      KYTStatus: "UNDER_INVESTIGATION",
      FiatValue: {
        Amount: 10.0,
        Currency: "EUR",
      },
    },
    Blockchain: {
      Status: "BURNED",
      KYTStatus: "BLOCKED",
      BlockchainValue: {
        Amount: 10.0,
        Currency: "EURR",
      },
    },
  },
  {
    TransactionId: "1123fdsd",
    CreatedDateTime: new Date().toISOString(),
    Type: "SELL",
    Fiat: {
      Status: "CONFIRMED",
      KYTStatus: "UNDER_INVESTIGATION",
      FiatValue: {
        Amount: 40.0,
        Currency: "EUR",
      },
    },
    Blockchain: {
      Status: "BURNED",
      KYTStatus: "FAILED",
      BlockchainValue: {
        Amount: 30.0,
        Currency: "EURR",
      },
    },
  },
];

export const MOCK_TRANSACTION: APITransaction = {
  TransactionId: "2i37gu3fdsd",
  CreatedDateTime: new Date().toISOString(),
  Type: "BUY",
  Status: "PENDING",
  PaymentInstructionId: "83h983h23hh",
  Description: "Description",
  Customer: {
    Name: "Name",
    OrganizationId: "12432314",
    UserId: "OrgID",
    BankAccount: {
      AccountName: "Example Bank",
      IBAN: "ergsdfvdf",
      Types: ["SWIFT"],
      Whitelisted: true,
    },
    Wallet: {
      WalletAddress: "0x165C23r5345t455666567676768768",
      WalletName: "Name of wallet",
      Whitelisted: true,
    },
  },
  Fiat: {
    Status: "PENDING",
    CreatedDateTime: new Date().toISOString(),
    TransferredDateTime: new Date().toISOString(),
    GrossAmount: {
      Amount: 10.0,
      Currency: "EUR",
    },
    NetAmount: {
      Amount: 10.0,
      Currency: "EUR",
    },
    BankingFee: {
      Amount: 10.0,
      Currency: "EUR",
    },
    KYTId: "COMPAD_12345",
    KYTStatus: "UNDER_INVESTIGATION",
    Description: "hi",
    FiatValue: {
      Amount: 10.0,
      Currency: "EUR",
    },
    Direction: "INBOUND",
  },
  Blockchain: {
    Id: "234234w534523",
    Network: "ETHEREUM",
    Status: "MINTED",
    TransactionHash: "0x97d9930949439343fde",
    CreatedDateTime: new Date().toISOString(),
    KYTId: "CHAINALY_123",
    KYTStatus: "BLOCKED",
    KYTAlertLevel: "HIGH",
    KYTDescription: "Scam 2021.",
    BlockchainValue: {
      Amount: 10.0,
      Currency: "EURR",
    },
    GasFeeETH: {
      Amount: 4.0,
      Currency: "EUR",
    },
    GasFeeEUR: {
      Amount: 4.0,
      Currency: "EUR",
    },
    IssuingPlatform: "FIREBLOCKS",
  },
};
