import { useQuery } from "@tanstack/react-query";
import api from "api";

useAccountOperators.queryKey = "accountOperators";

export function useAccountOperators() {
  return useQuery([useAccountOperators.queryKey], async () => api.Governance.getAccountOperators(), {
    notifyOnChangeProps: "all",
  });
}
