import { APICustomerStatusType } from "types/API/Customer";

export function parseCustomerBadgetext(status: APICustomerStatusType) {
  if (status === undefined) return "";
  switch (status) {
    case "APPROVED": {
      return "Approved";
    }
    case "REJECTED": {
      return "Rejected";
    }
    case "PENDING": {
      return "Pending";
    }
    default: {
      return status;
    }
  }
}
