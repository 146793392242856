import Constants from "constants/index";
import { EURR_ABI } from "contract/EURR-ABI";
import { Web3Service } from "services/web3";

const assumedDecimals = 6;

export async function getTotalSupply(token: keyof typeof Constants.web3.contracts) {
  const contract = new Web3Service.eth.Contract(EURR_ABI, Constants.web3.contracts[token]);

  /**
   * This saves us an additional call for each request.
   * We can optionally request the decimalsin the future,
   * however, for the moment this is fixed on our only token
   */
  // const decimals = await contract.methods.decimals().call();

  const result = await contract.methods.totalSupply().call();

  return result / 10 ** assumedDecimals; // Calculate based on decimals
}
