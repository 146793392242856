import { BadgeVarients } from "stablr";
import { APIFiatTransactionStatus } from "types/API/Transaction";

export function getFiatStatusVarient(status: APIFiatTransactionStatus): BadgeVarients {
  switch (`${status}`) {
    case "PENDING":
      return "info";
    case "FAILED":
      return "error";
    case "CONFIRMED":
      return "success";
    case "REJECTED":
      return "error";
    case "AWAITING_PAYOUT":
      return "action";
    default:
      return "default"; // Use Default Varient
  }
}
