import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "api";
import { APIPostConfirmPayment } from "api/Transaction";
import Constants from "constants/index";
import { asyncSleep } from "refactor/functions/async-sleep";
import { getErrorMessage } from "stablr/functions";
import { useToast } from "stablr/hooks";

import { useTransaction } from "./use-transaction";
import { useTransactions } from "./use-transactions";

interface UseConfirmPayment extends APIPostConfirmPayment {
  TransactionId: string;
  Type: "BUY" | "SELL";
}

export function useConfirmPayment() {
  const { errorToast, successToast } = useToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (payload: UseConfirmPayment) =>
      api.Transaction.confirmPayment(
        {
          PaymentInstructionId: payload.PaymentInstructionId,
          TransferDate: payload.TransferDate,
          BankAccount: payload.BankAccount,
          Currency: payload.Currency,
          GrossAmount: payload.GrossAmount,
          FeeAmount: payload.FeeAmount,
          Description: payload.Description,
        },
        payload.Type
      ),
    onSuccess: async (_: unknown, { TransactionId, Type }) => {
      await asyncSleep(Constants.cache.defaultSocketDelay);

      await queryClient?.invalidateQueries({
        queryKey: [useTransactions.queryKey, Type],
      });
      await queryClient?.invalidateQueries({ queryKey: [useTransaction.queryKey, TransactionId] });

      successToast({ message: "Order has been confirmed successfully" });
    },
    onError: error => {
      errorToast({ message: getErrorMessage(error) });
    },
  });
}
