import { useBankAccounts } from "hooks/query/customers/bank/use-bank-accounts";
import React from "react";
import { useParams } from "react-router-dom";
import { Heading, spacing } from "stablr";
import { ReactComponent as PieChartIconSVG } from "stablr/assets/icons/pie-chart.svg";
import { CardEmpty, CardError, CardLoader, DashboardContainer } from "stablr/components/molecules";
import { getErrorMessage } from "stablr/functions";
import styled from "styled-components";

import { BankAccountTable } from "./BankAccountTable";

const HeadingStyled = styled(Heading)`
  padding-left: ${spacing.m};
  padding-bottom: ${spacing.s};
`;

function BankAccounts() {
  const { id } = useParams();
  const { data, isLoading, error, refetch } = useBankAccounts(`${id}`);

  return (
    <DashboardContainer>
      <HeadingStyled as="h2">Banks Overview</HeadingStyled>
      {error ? (
        <CardError body={getErrorMessage(error)} title="Error fetching bank account(s)" onRetry={refetch} />
      ) : isLoading ? (
        <CardLoader />
      ) : !data || data.length === 0 ? (
        <CardEmpty
          iconElement={<PieChartIconSVG />}
          title="No bank account found"
          body="There is no bank account at the moment"
        />
      ) : (
        <>
          <BankAccountTable data={data} />
        </>
      )}
    </DashboardContainer>
  );
}

export default BankAccounts;
