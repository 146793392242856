import React from "react";
import { useLocation } from "react-router-dom";

export function useCurrentLocationFromPaths(slugsOrPaths: Array<string>) {
  const { pathname } = useLocation();

  return React.useMemo(() => {
    let slugLocation = -1;
    slugsOrPaths.forEach((pathOrPaths, index) => {
      if (pathname.startsWith(pathOrPaths)) slugLocation = index;
    });
    return slugLocation;
  }, [pathname, slugsOrPaths]);
}
