import { useWeb3React } from "@web3-react/core";
import * as React from "react";
import { color, getErrorMessage, transformWalletAddress, useToast } from "stablr";
import { ReactComponent as RadioButtonCircleSVG } from "stablr/assets/icons/radio_button_circle.svg";
import { Button } from "stablr/components/atoms";
import Web3 from "web3";

export function MetaMaskButton() {
  const [loading, setLoading] = React.useState(false);
  const { connector, hooks } = useWeb3React();
  const { errorToast } = useToast();
  const { useSelectedAccount } = hooks;
  const account = useSelectedAccount(connector);

  const onConnectMetaMask = async () => {
    const chainId = process.env.SUPPORT_CHAIN_ID || "11155111";
    try {
      if (chainId && window.ethereum && window.ethereum.networkVersion !== chainId) {
        try {
          await window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: Web3.utils.toHex(parseInt(chainId)) }],
          });
        } catch (err: any) {
          errorToast({ title: "Error", message: "Network change rejected" });
        }
      } else {
        setLoading(true);
        try {
          await connector.activate(chainId);
        } catch (err) {
          errorToast({ title: "Error", message: "User rejected the request" });
          setLoading(false);
        }
      }
    } catch (error) {
      errorToast({ title: "Error", message: getErrorMessage(error) });
    }
  };

  const onDisconnectMetaMask = () => {
    if (connector?.deactivate) {
      connector.deactivate();
    } else {
      connector.resetState();
    }
  };

  React.useEffect(() => {
    if (account) setLoading(false);
  }, [account]);

  return (
    <Button
      loading={loading}
      icon={<RadioButtonCircleSVG />}
      iconColor={account ? color.palette.green : color.palette.red}
      iconSize={22}
      varient="line"
      onClick={account ? onDisconnectMetaMask : onConnectMetaMask}
    >
      {account ? transformWalletAddress(account) : "Connect to Wallet"}
    </Button>
  );
}
