import React, { useMemo } from "react";
import { ReactComponent as RejectSVG } from "stablr/assets/icons/cancel.svg";
import { ReactComponent as CheckSVG } from "stablr/assets/icons/check-circle.svg";
import { HeadlessMenuList } from "stablr/components/molecules";
import { color } from "stablr/styles";
import styled from "styled-components";

import { ConfirmWalletModal } from "./ConfirmWalletModal";
import { RejectWalletModal } from "./RejectWalletModal";

const HeadlessMenuListStyled = styled.div`
  & > div {
    & > button {
      max-height: 20px;
    }
  }
`;

interface WalletActionsProps {
  id: string;
  status: string;
  organizationId: string;
}

const WalletActions = ({ id, status, organizationId }: WalletActionsProps) => {
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const [openReject, setOpenReject] = React.useState(false);

  const options = useMemo(() => {
    const options = [];
    if (status === "PENDING") {
      options.push({
        label: "Approve",
        icon: <CheckSVG />,
        iconColor: color.palette.green,
        iconBgColor: color.greyscale.white,
        onClick: () => setOpenConfirm(true),
      });
    }
    if (status === "PENDING") {
      options.push({
        label: "Reject",
        icon: <RejectSVG />,
        iconBgColor: color.greyscale.white,

        onClick: () => setOpenReject(true),
      });
    }
    return options;
  }, [status]);

  return (
    <>
      {options?.length ? (
        <HeadlessMenuListStyled>
          <HeadlessMenuList options={options} />
        </HeadlessMenuListStyled>
      ) : (
        <></>
      )}
      <ConfirmWalletModal
        walletId={id}
        organizationId={organizationId}
        isOpen={openConfirm}
        onClose={() => setOpenConfirm(false)}
      />
      <RejectWalletModal
        walletId={id}
        isOpen={openReject}
        onClose={() => setOpenReject(false)}
        organizationId={organizationId}
      />
    </>
  );
};

export default WalletActions;
