import { useConfirmWallet } from "hooks/query/customers/wallet/use-confirm-wallet";
import React from "react";
import { ActionModal } from "stablr";

export function ConfirmWalletModal({
  isOpen,
  onClose,
  walletId,
  organizationId,
}: {
  isOpen: boolean;
  onClose: () => void;
  walletId: string;
  organizationId: string;
}) {
  const { mutateAsync: mutateAsyncConfirmWallet, isLoading } = useConfirmWallet();

  const onConfirm = async () =>
    mutateAsyncConfirmWallet(
      { walletId, organizationId },
      {
        onSuccess: () => {
          onClose();
        },
      }
    );

  const handleClose = () => {
    if (isLoading) return;
    onClose();
  };

  return (
    <ActionModal
      open={isOpen}
      onClose={handleClose}
      title={"Confirm Wallet"}
      btnText="Confirm"
      description="Are you sure you want to confirm?"
      onConfirm={onConfirm}
      loading={isLoading}
    />
  );
}
