import { useMutation } from "@tanstack/react-query";
import api from "api";
import { getErrorMessage } from "stablr/functions";
import { useToast } from "stablr/hooks";
import { APIFileResponse, APIFileType } from "types/API/File";

useDownloadFileVersion.queryKey = "download-file";

export default function useDownloadFileVersion() {
  const { errorToast, successToast } = useToast();

  return useMutation({
    mutationFn: async ({
      organizationId,
      fileId,
      fileType,
      version,
    }: {
      organizationId: string;
      fileId: string;
      fileType: APIFileType;
      version: number;
    }) => {
      return api.Document.downloadDocument(organizationId, fileId, fileType, version);
    },
    onSuccess: (response: APIFileResponse) => {
      window.open(response.SignedUrl);
      successToast({ message: "Successfully downloaded the file", title: "File Download" });
    },
    onError: error => {
      errorToast({ message: getErrorMessage(error), title: "File Download Failed" });
    },
  });
}
