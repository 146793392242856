import { useCustomer } from "hooks/query/customers/customer/use-customer";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as ArrowBackSVG } from "stablr/assets/icons/arrow-back.svg";
import { Icon } from "stablr/components/atoms";
import { color, spacing } from "stablr/styles";
import styled from "styled-components";

const SpanButtonStyled = styled.span`
  display: inline-block;
  cursor: pointer;

  & div {
    margin-right: ${spacing.m};
  }
`;

export function HeaderNavigationCustomer() {
  const navigate = useNavigate();
  const location = useLocation();
  const currentUrl = location.pathname;
  const id = currentUrl.split("/")[2];

  const { data } = useCustomer(id);

  const handleGoBack = () => {
    if (currentUrl.startsWith("/customers/") && currentUrl.includes("/bank-accounts/")) {
      if (currentUrl === `/customers/${id}/bank-accounts/`) {
        navigate("/customers");
      } else {
        navigate(`/customers/${id}/bank-accounts`);
      }
    } else if (currentUrl.startsWith("/customers/") && currentUrl.includes("/documents/")) {
      if (currentUrl === `/customers/${id}/documents/`) {
        navigate("/customers");
      } else if (currentUrl.includes("/edit")) {
        navigate(-1);
      } else {
        navigate(`/customers/${id}/documents`);
      }
    } else {
      navigate("/customers");
    }
  };

  return (
    <SpanButtonStyled onClick={handleGoBack}>
      <Icon size={25} color={color.palette.purple}>
        <ArrowBackSVG />
      </Icon>
      {data?.Name}
    </SpanButtonStyled>
  );
}
