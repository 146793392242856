import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "api";
import { useToast } from "stablr/hooks";
import { APITransactionProfile } from "types/API/Customer";

import { useTransactionProfile } from "./use-transaction-profile";

export function useUpdateTransactionProfile() {
  const queryClient = useQueryClient();
  const { successToast } = useToast();

  return useMutation({
    mutationFn: async ({ organizationId, data }: { organizationId: string; data: APITransactionProfile }) => {
      return api.Customer.updateTransactionProfile(organizationId, data);
    },
    onSuccess: async () => {
      await queryClient?.invalidateQueries({ queryKey: [useTransactionProfile.queryKey] });
      successToast({ message: "Transaction Profile has been updated successfully" });
    },
  });
}
