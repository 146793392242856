import { useRejectWallet } from "hooks/query/customers/wallet/use-reject-wallet";
import React from "react";
import { ActionModal } from "stablr";
import { color } from "stablr/styles";

export function RejectWalletModal({
  isOpen,
  onClose,
  walletId,
  organizationId,
}: {
  isOpen: boolean;
  onClose: () => void;
  walletId: string;
  organizationId: string;
}) {
  const { mutateAsync: mutateAsyncRejectWallet, isLoading } = useRejectWallet();

  const handleSubmit = async () =>
    mutateAsyncRejectWallet(
      { walletId, organizationId },
      {
        onSuccess: () => {
          onClose();
        },
      }
    );

  const handleClose = () => {
    if (isLoading) return;
    onClose();
  };

  return (
    <ActionModal
      open={isOpen}
      onClose={handleClose}
      title={"Reject wallet"}
      btnText="Confirm"
      btnBackgroundColor={color.palette.red}
      description={"Are you sure you want to delete?"}
      onConfirm={handleSubmit}
      loading={isLoading}
    />
  );
}
