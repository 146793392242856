import * as yup from "yup";

export enum PAYMENT_CONFIRMATION_SCHEMA {
  BANKING_PARTNER_OPERATOR = "BankingPartnerOperator",
  ID = "Id",
}

export const CustodianBankValidation = yup.object({
  Name: yup.string().required("Bank Account is required"),
  Id: yup.string().required("Bank Account is required"),
});
