import { FORM_ERROR } from "final-form";
import { useDocument } from "hooks/query/customers/document/use-document";
import { useUpdateDocument } from "hooks/query/customers/document/use-update-document";
import React from "react";
import { Form } from "react-final-form";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Heading, Spacer } from "stablr/components/atoms";
import { ButtonContainer, CardError, CardLoader, ErrorMessage } from "stablr/components/molecules";
import { getErrorMessage, validateFormValues } from "stablr/functions";
import { getFileBase64 } from "stablr/functions/get-file-base-64";
import { color, spacing } from "stablr/styles";
import { APIDocument } from "types/API/Document";

import DocumentForm from "../common/DocumentForm";
import { DocumenSchemaValidation } from "../common/schemaValidation";

export default function EditDocument() {
  const { fileId, id } = useParams();

  const { mutateAsync: mutateUpdateDocument } = useUpdateDocument();
  const { data: document, isLoading, refetch, error } = useDocument(id ?? "", fileId ?? "");

  const navigate = useNavigate();

  const handleOnSubmit = async (data: APIDocument) => {
    try {
      const file = data.File as File;
      const base64 = `${await getFileBase64(file)}`;
      const updatedData = {
        Title: data.Title,
        FileName: file.name,
        Data: base64,
      };
      await mutateUpdateDocument({
        organizationId: id ?? "",
        fileId: fileId ?? "",
        document: updatedData,
      });
      navigate(-1);
    } catch (error) {
      return { [FORM_ERROR]: getErrorMessage(error) };
    }
  };

  return (
    <>
      <Heading as="h3">Upload New Version</Heading>
      <Form
        onSubmit={handleOnSubmit}
        initialValues={document}
        validate={validateFormValues(DocumenSchemaValidation)}
        keepDirtyOnReinitialize
      >
        {({ handleSubmit, submitError, submitting }) => {
          const isDisabled = submitting || isLoading;
          return (
            <form onSubmit={handleSubmit}>
              <>
                {error ? (
                  <CardError body={getErrorMessage(error)} title="Error fetching customer" onRetry={refetch} />
                ) : isLoading ? (
                  <CardLoader />
                ) : document ? (
                  <>
                    <DocumentForm documentTitle={document.Title} isEditForm disabled={isDisabled} />
                    <ButtonContainer justifyContent="flex-start">
                      <Button type="submit" varient="primary" color={color.greyscale.white} loading={isDisabled}>
                        Upload
                      </Button>
                      <Spacer width={spacing.m} />
                    </ButtonContainer>
                  </>
                ) : (
                  <CardLoader />
                )}
                {submitError && <ErrorMessage>{submitError}</ErrorMessage>}
              </>
            </form>
          );
        }}
      </Form>
    </>
  );
}
