import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Icon, spacing } from "stablr";
import { ReactComponent as ArrowBackSVG } from "stablr/assets/icons/arrow-back.svg";
import styled from "styled-components";

const SpanButtonStyled = styled.span`
  display: inline-block;
  cursor: pointer;

  & div {
    margin-right: ${spacing.m};
  }
`;

export function HeaderNavigationTransactions() {
  const navigate = useNavigate();
  const location = useLocation();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <SpanButtonStyled onClick={handleGoBack}>
      <Icon size={25}>
        <ArrowBackSVG />
      </Icon>
      {location.pathname.includes("/buy/") ? "Back to Buy Orders" : "Back to Sell Orders"}
    </SpanButtonStyled>
  );
}
