import { ReactComponent as LogoMarkSVG } from "assets/svg/logo-ops-white.svg";
import { ReactComponent as LogoStablrSVG } from "assets/svg/logo-stablr-ops.svg";
import { signInWithRedirect } from "aws-amplify/auth";
import * as React from "react";
import { Button, Heading, Paragraph } from "stablr/components/atoms";
import { HalfHalfResponsive } from "stablr/components/templates";
import { composeTestID } from "stablr/functions";
import styled from "styled-components";

interface SignInProps {
  testid?: string;
}

const SignInStyled = styled.main`
  width: 100%;
  min-height: 100%;
`;

SignInComponent.testid = "signIn";

export default function SignInComponent({ testid }: SignInProps) {
  const signIn = async () => {
    await signInWithRedirect({ provider: "Google" } as any);
  };

  return (
    <SignInStyled data-testid={composeTestID(SignInComponent.testid, testid)}>
      <HalfHalfResponsive logo={LogoStablrSVG} logoMark={<LogoMarkSVG />}>
        <>
          <Heading as="h1" varient="h2">
            Hi, Welcome Back!
          </Heading>
          <Paragraph>Easy access to fully backed digital assets</Paragraph>
          <Button width="100%" onClick={() => signIn()}>
            Login
          </Button>
        </>
      </HalfHalfResponsive>
    </SignInStyled>
  );
}
