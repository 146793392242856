import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Badge, Table } from "stablr/components/atoms";
import { TableHeaderIcon } from "stablr/components/atoms/Table";
import { useIsMobile } from "stablr/hooks";
import { fontWeight } from "stablr/styles";
import color from "stablr/styles/color";
import styled from "styled-components";
import { APIBankAccount, APIBankAccountStatusType } from "types/API/BankAccount";

import { getBankAccountBadgeVariant } from "./functions/get-bank-account-badge-varient";
import { getBankAccountType } from "./functions/get-bank-account-type";
import { parseBankAccountBadgeText } from "./functions/parse-bank-account-badge-text";

const { TableBody, TableData, TableHead, TableHeader, TableRow, TableRowBadge } = Table;

interface BankAccountTableProps {
  data: APIBankAccount[];
}

const BankAccountTableStyled = styled.table`
  width: 100%;

  & th {
    font-weight: ${fontWeight.bold};
  }
`;

const BankAccountTableWrapperStyled = styled.div`
  overflow-x: auto;

  & tr {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    & span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 200px;
    }

    & span[data-testid="Badge"] {
      max-width: unset;
      margin: 0;

      &.success {
        background-color: transparent;
        color: ${color.greyscale.black};
        font-weight: ${fontWeight.default};
      }
    }

    & b {
      font-weight: ${fontWeight.semiBold};
    }
  }
`;

const columnHelper = createColumnHelper<APIBankAccount>();

export function BankAccountTable({ data }: BankAccountTableProps) {
  const { id } = useParams();

  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const handleOnActionsClick = (bankId: string) => {
    navigate(`/customers/${id}/bank-accounts/${bankId}`);
  };

  const [sorting, setSorting] = React.useState<SortingState>([]);

  const columns = React.useMemo(() => {
    const tableColumns = [
      columnHelper.accessor("BankName", {
        header: "Bank Name",
        cell: info => info.getValue(),
        footer: info => info.column.id,
      }),
      columnHelper.accessor("Name", {
        header: "Bank Account Holder",
        cell: info => info.getValue(),
        footer: info => info.column.id,
      }),

      columnHelper.accessor(row => (row.Types.includes("BLINC") ? row.BlincId : row.Iban), {
        header: "Account Id",
        cell: info => info.getValue(),
        footer: info => info.column.id,
      }),
      columnHelper.accessor("Types", {
        header: "Type",
        cell: info => getBankAccountType(info?.getValue()?.[0]),
        footer: info => info.column.id,
      }),
      columnHelper.accessor("Status", {
        header: "Status",
        cell: info => displayStatus(info.getValue()),
        footer: info => info.column.id,
      }),
    ];

    const displayStatus = (status: APIBankAccountStatusType) => {
      if (status === "APPROVED") {
        return "Approved";
      } else {
        return <Badge text={parseBankAccountBadgeText(status)} varient={getBankAccountBadgeVariant(status)} />;
      }
    };

    const mobileColumns = [tableColumns[0], tableColumns[1], tableColumns[3]];
    const col = [...(isMobile ? mobileColumns : tableColumns)];
    return col;
  }, [isMobile]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <BankAccountTableWrapperStyled>
      <BankAccountTableStyled>
        <TableHead>
          <>
            {table.getHeaderGroups().map(headerGroup => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <TableHeader
                    icon={<TableHeaderIcon header={header} />}
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHeader>
                ))}
              </TableRow>
            ))}
          </>
        </TableHead>
        <TableBody>
          <>
            {table.getRowModel().rows.map(row => (
              <React.Fragment key={row.original.Id}>
                {isMobile && (
                  <TableRowBadge
                    colSpan={3}
                    badgeElement={
                      <Badge
                        varient={getBankAccountBadgeVariant(row.original?.Status)}
                        text={parseBankAccountBadgeText(row.original?.Status)}
                      />
                    }
                  ></TableRowBadge>
                )}
                <TableRow key={row.id} onRowClick={() => handleOnActionsClick(row.original.Id)}>
                  {row.getVisibleCells().map(cell => {
                    return (
                      <TableData key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableData>
                    );
                  })}
                </TableRow>
              </React.Fragment>
            ))}
          </>
        </TableBody>
      </BankAccountTableStyled>
    </BankAccountTableWrapperStyled>
  );
}
