import { APIBlockchainKYTStatus } from "types/API/Transaction";

export function parseBlockchainKYTStatusText(status: APIBlockchainKYTStatus): string {
  switch (`${status}`) {
    case "FAILED":
      return "Failed";
    case "COMPLETED":
      return "Completed";
    case "UNDER_INVESTIGATION":
      return "Under Investigation";
    case "BLOCKED":
      return "Blocked";
    case "NONE":
      return "None";
    default:
      return status ?? "undefined";
  }
}
