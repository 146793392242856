import * as React from 'react'
import { ReactComponent as ArrowDownSVG } from 'stablr/assets/icons/arrow-down.svg'
import { ReactComponent as ArrowUpSVG } from 'stablr/assets/icons/arrow-up.svg'
import { ReactComponent as SortSVG } from 'stablr/assets/icons/sort.svg'
import { Icon } from 'stablr/components/atoms/Icon'

export interface TableHeaderIconProps {
  header: any
}

export function TableHeaderIcon({ header }: TableHeaderIconProps) {
  if (header.column.getCanSort() && header.column.getIsSorted()) {
    if (header.column.getIsSorted() === 'asc') {
      return (
        <Icon size={12}>
          <ArrowUpSVG />
        </Icon>
      )
    } else if (header.column.getIsSorted() === 'desc') {
      return (
        <Icon size={12}>
          <ArrowDownSVG />
        </Icon>
      )
    } else {
      // Unknown sort
      return (
        <Icon size={12}>
          <SortSVG />
        </Icon>
      )
    }
  } else if (header.column.getCanSort() && !header.column.getIsSorted()) {
    return (
      <Icon size={12}>
        <SortSVG />
      </Icon>
    )
  } else {
    return null
  }
}
