import * as React from "react";
import { color, spacing } from "stablr/styles";
import styled from "styled-components";

interface ListContainerProps {
  children: React.ReactElement | React.ReactElement[];
}

const ListContainerStyled = styled.div`
  margin: ${spacing.m} ${spacing.m};
  box-shadow: 0 0 4px 0 ${color.greyscale.grey3};
`;

export function ListContainer({ children }: ListContainerProps) {
  return <ListContainerStyled>{children}</ListContainerStyled>;
}
